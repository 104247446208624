import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import {
  Alert,
  API,
  AuthContext,
  Button,
  exceptionHandler,
  Loader
} from "shared-components";

import { requestActions, statusValue } from "./actionValueMap";

const useStyles = createUseStyles((theme: any) => {
  return {
    buttonMargin: {
      marginRight: 50,
      marginLeft: 50,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    casesTable: {
      marginTop: 15
    },
    remove: {
      width: 75,
      textAlign: "center",
      cursor: "pointer"
    },
    logContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: 50
    },
    activityLog: {
      display: "flex",
      flexDirection: "row",
      marginTop: "15px !important"
    },
    actionButtonContainer: {
      marginTop: 20,
      display: "flex",
      width: "100%",
      justifyContent: "center"
    },
    estimatingButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "5%"
    }
  };
});

interface Props {
  sender: boolean;
  form: RequestFormData;
  errors: any;
  setErrors: any;
  cases: CasesData[];
  setForm: React.Dispatch<React.SetStateAction<RequestFormData>>;
  setRequestAction: React.Dispatch<React.SetStateAction<RequestAction>>;
  setActivities?: any;
  requestAction: RequestAction;
  activities?: any[];
  add?: boolean;
}

export const RequestActions = ({
  sender,
  form,
  setErrors,
  cases,
  requestAction,
  setRequestAction,
  setForm,
  setActivities,
  activities,
  add
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { defaultTeam } = useContext(AuthContext);

  const { status } = form;

  const [loading, setLoading] = useState(false as boolean);

  const canQuote =
    form.clientTeamId === defaultTeam &&
    [statusValue.Estimating, statusValue.Accepting].includes(status);

  const receiver = form.clientTeamId === defaultTeam;

  const handleConfirm = async (params: {
    action: string;
    comment?: string;
  }) => {
    setRequestAction({ ...requestAction, open: false });
    setLoading(true);

    let data;

    if (
      params.action === requestActions.REQUEST_QUOTE ||
      params.action === requestActions.SEND_REQUEST
    ) {
      const valid = validateSender();
      if (!valid) {
        setLoading(false);
        return;
      }
      const caseIds = cases.map((casedata: CasesData) => {
        return casedata.id;
      });
      data = {
        action: params.action,
        comment: params.comment,
        ...form,
        caseIds
      };
    } else if (params.action === requestActions.SEND_QUOTE) {
      const valid = validateReceiver();
      if (!valid) {
        setLoading(false);
        return;
      }
      data = {
        action: params.action,
        comment: params.comment,
        ...form
      };
    } else {
      data = {
        action: params.action,
        comment: params.comment
      };
    }

    try {
      const response = await API.post(
        `requests/${add ? "add" : form.id}/actions`,
        data
      );
      if (response && response.data) {
        const { activities, ...form } = response.data;
        setForm({ ...form });
        setActivities(activities);
        if (params.action === requestActions.SEND_REQUEST) {
          Alert("success", "success", t("Your request has been sent."), t);
          history.push(`/requests/${form.id}`);
        } else if (params.action === requestActions.REQUEST_QUOTE) {
          Alert(
            "success",
            "success",
            t("Your quote request has been sent."),
            t
          );
          history.push(`/requests/${form.id}`);
        } else if (params.action === requestActions.EDIT_REQUEST) {
          Alert("success", "success", t("You can now edit the request."), t);
        } else if (params.action === requestActions.ACCEPT_REQUEST) {
          Alert("success", "success", t("Your request is accepted."), t);
        } else if (params.action === requestActions.SEND_BACK_REQUEST) {
          Alert("success", "success", t("Your request was sent back."), t);
        } else if (params.action === requestActions.SEND_QUOTE) {
          Alert("success", "success", t("Your quote has been sent."), t);
        } else if (params.action === requestActions.ORDER) {
          Alert("success", "success", t("Your order has been made."), t);
        } else if (params.action === requestActions.REQUEST_CHECK) {
          Alert("success", "success", t("Check has been requested."), t);
        } else if (params.action === requestActions.SEND_BACK_CHECK) {
          Alert("success", "success", t("Check has been sent back."), t);
        } else if (params.action === requestActions.COMPLETE_CHECK) {
          Alert("success", "success", t("Check has been completed."), t);
        } else if (params.action === requestActions.REQUEST_CANCEL) {
          Alert("success", "success", t("Your request has been cancelled"), t);
        } else if (params.action === requestActions.ACCEPT_CANCEL) {
          Alert(
            "success",
            "success",
            t("You have accepted the cancellation."),
            t
          );
        }
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLoading(false);
  };

  const validateSender = () => {
    let newErrors = {};

    if (!form.requestType) {
      newErrors = { ...newErrors, requestType: "Required" };
    }
    if (!form.teamClientId) {
      newErrors = { ...newErrors, teamClientId: "Required" };
    }
    if (!form.requestBody) {
      newErrors = { ...newErrors, requestBody: "Required" };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const validateReceiver = () => {
    let newErrors = {};

    if (!form.price) {
      newErrors = { ...newErrors, price: "Required" };
    }
    if (form.price && !(form.price > 0)) {
      newErrors = {
        ...newErrors,
        price: "Please enter value that is not negative and zero"
      };
    }
    if (!form.dueDate) {
      newErrors = { ...newErrors, dueDate: "Required" };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleAction = (action: string) => {
    setRequestAction({ open: true, action, handleConfirm });
  };

  if (loading)
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );

  const tracingReport = form.requestType === "tracing";

  return (
    <div className={classes.actionButtonContainer}>
      {sender && (status === statusValue.Editing || add) && (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            return handleConfirm({
              action: tracingReport
                ? requestActions.SEND_REQUEST
                : requestActions.REQUEST_QUOTE
            });
          }}
          className={classes.buttonMargin}
        >
          {tracingReport ? t("Send Request") : t("Request Quote")}{" "}
        </Button>
      )}
      {[
        statusValue.Estimating,
        statusValue.Ordering,
        statusValue.Accepting,
        statusValue.Declined
      ].includes(status) &&
        (sender ? (
          <div className={classes.estimatingButtons}>
            {status === statusValue.Ordering && !tracingReport && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  return handleAction(requestActions.ORDER);
                }}
                className={classes.buttonMargin}
              >
                {t("Order")}{" "}
              </Button>
            )}
            <Button
              type="primary"
              size="small"
              onClick={() => {
                return handleAction(requestActions.EDIT_REQUEST);
              }}
              className={classes.buttonMargin}
            >
              {t("Edit")}{" "}
            </Button>
          </div>
        ) : (
          canQuote && (
            <div className={classes.estimatingButtons}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  return handleAction(requestActions.SEND_BACK_REQUEST);
                }}
                className={classes.buttonMargin}
              >
                {t("Return request")}{" "}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  const valid = tracingReport ? true : validateReceiver();
                  if (!valid) {
                    return;
                  }
                  if (tracingReport)
                    return handleAction(requestActions.ACCEPT_REQUEST);
                  return handleAction(requestActions.SEND_QUOTE);
                }}
                className={classes.buttonMargin}
              >
                {tracingReport ? t("Accept request") : t("Send quote")}{" "}
              </Button>
            </div>
          )
        ))}

      {[statusValue.Working, statusValue.Rejected].includes(status) &&
        !sender &&
        receiver && (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              return handleAction(requestActions.REQUEST_CHECK);
            }}
            className={classes.buttonMargin}
          >
            {t("Request check")}{" "}
          </Button>
        )}

      {status === statusValue.Checking && sender && (
        <div className={classes.estimatingButtons}>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              return handleAction(requestActions.COMPLETE_CHECK);
            }}
            className={classes.buttonMargin}
          >
            {t("Complete check")}{" "}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              return handleAction(requestActions.SEND_BACK_CHECK);
            }}
            className={classes.buttonMargin}
          >
            {t("Return check")}{" "}
          </Button>
        </div>
      )}

      {[statusValue.Canceling].includes(status) && !sender && receiver && (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            return handleAction(requestActions.ACCEPT_CANCEL);
          }}
          className={classes.buttonMargin}
        >
          {t("Accept cancellation")}{" "}
        </Button>
      )}
    </div>
  );
};
