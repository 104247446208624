import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { API, Loader } from "shared-components";
import { AuthContext, exceptionHandler } from "shared-components/src";

import { ComponentWrapper, SwitchTeamModal } from "../../components";
import { CaseDoubleCheck } from "../../components/organisms/DoubleCheck";
import { ReportWrapper } from "./report/ReportWrapper";
import {
  CaseData,
  Member,
  PrescriptionType,
  ReportListObject,
  SwitchTeamValue
} from "./types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      background: theme.background,
      height: "80vh",
      overflowY: "auto"
    },
    containWrapper: {
      width: "100%",
      height: "calc(100vh - 40px)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1c1",
        borderRadius: "10px"
      }
    },
    loader: {
      display: "flex",
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center"
    }
  };
});

export const Case = ({
  publicReport,
  errorBoundary
}: {
  publicReport: boolean;
  errorBoundary: any;
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [caseState, setCaseState] = useState({
    answer: "agree",
    agreement_send: 0
  } as CaseData);
  const [lastCaseState, setLastCaseState] = useState({} as any);
  const [caseStatus, setCaseStatus] = useState("" as string);
  const [reportList, setReportList] = useState([{}] as ReportListObject[]);
  const [clients, setClients] = useState([] as TeamClientData[]);
  const {
    defaultTeam,
    profile: { teams: allTeams }
  } = useContext(AuthContext);

  const { id, reportCode }: any = useParams();

  const [medicines, setMedicines] = useState([] as any);
  const [allMembers, setAllMembers] = useState([] as Member[]);
  const [caseDoubleCheck, setCaseDoubleCheck] = useState(
    null as CaseDoubleCheck | null
  );
  const [prescriptionTypes, setPrescriptionTypes] = useState<
    PrescriptionType[]
  >([]);

  const [loading, setLoading] = useState(false as boolean);
  const [viewAsTeamOwner, setViewAsTeamOwner] = useState(false as boolean);
  const [switchTeam, setSwitchTeam] = useState({
    open: false,
    teams: []
  } as SwitchTeamValue);

  let reportId: any;
  let commentId: any;

  if (location && location.search && location.search.includes("commentId=")) {
    const urlParams = new URLSearchParams(window.location.search);
    reportId = urlParams.get("reportId");
    commentId = urlParams.get("commentId");
  }

  const fetchCase = async () => {
    setLoading(true);
    try {
      const clientTeamId =
        location &&
        location.search &&
        location.search.includes("?clientTeamId=") &&
        location.search.split("=")[1];

      const res = await API.get(`cases/${id || reportCode}`, {
        params: {
          clientTeamId: clientTeamId || undefined,
          public: publicReport || undefined
        }
      });
      if (
        res.data.team.ID !== defaultTeam &&
        (res.data.report.clientTeamId !== defaultTeam ||
          res.data.report.diagnosisRequestId === null)
      ) {
        setViewAsTeamOwner(true);
        getTeamClients(res.data.team.ID);
        getMembers(res.data.team.ID);
      }
      setCaseState({
        patient_id: res.data.case.patientId,
        patient_comment: res.data.case.patientComment,
        patient_email: res.data.case.patientEmail,
        patient_name: res.data.case.patientName,
        id: res.data.case.id,
        team_name: res.data.team.name,
        created_at: res.data.case.createdAt,
        agreement_answered_at: res.data.case.agreementAnsweredAt,
        agreement_status: res.data.case.agreementStatus,
        case_number: res.data.case.caseNumber,
        myBookmark: res.data.case.user_mark,
        marked: res.data.case.marked,
        pharmacist: res.data.case.agreementPharmacistUserId,
        supervisory_pharmacist: res.data.case.agreementSupervisoryUserId,
        sex: res.data.case.sex,
        birthday: res.data.case.birthday,
        age: res.data.case.age,
        occupation: res.data.case.occupation,
        answer: res.data.case.answer === "" ? "agree" : res.data.case.answer,
        agreement_description: res.data.case.agreementDescription,
        agreement_send: res.data.case.agreementSend,
        case_status: res.data.case.caseStatus,
        agreementPharmacistUserName: res.data.case.agreementPharmacistUserName,
        diagnosisRequestId: res.data.report.diagnosisRequestId,
        reportId: res.data.report.id
      });
      setLastCaseState({
        pharmacist: res.data.case.agreementPharmacistUserId,
        patient_id: res.data.case.patientId,
        patient_name: res.data.case.patientName,
        sex: res.data.case.sex,
        birthday: res.data.case.birthday,
        age: res.data.case.age,
        occupation: res.data.case.occupation,
        answer: res.data.case.answer === "" ? "agree" : res.data.case.answer,
        patient_comment: res.data.case.patientComment,
        agreement_description: res.data.case.agreementDescription,
        agreement_send: res.data.case.agreementSend,
        patient_email: res.data.case.patientEmail
      });

      const reports = res.data.allReports;

      const list: ReportListObject[] = [];
      if (reports)
        reports.forEach((item: any, key: number) => {
          list[key] = {} as ReportListObject;
          list[key].id = item.id;
          list[key].name = item.reportName;
          list[key].created_at = item.createdAt;
          list[key].clientTeamId = item.clientTeamId;
        });
      list.sort((a, b) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      });
      setCaseStatus(res.data.case.caseStatus);
      setReportList([...list]);

      if (res.data.medicines) setMedicines([...res.data.medicines]);

      const { doubleCheck } = res.data;
      if (
        doubleCheck &&
        !doubleCheck.confirmedAt &&
        !doubleCheck.changeRequestedAt
      ) {
        setDoubleCheckData(doubleCheck);
      } else {
        setDoubleCheckData(null);
      }

      if (publicReport) {
        const { allMembers, allClients } = res.data;
        setAllMembers(allMembers);
        setClients(allClients);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err && err.response && err.response.status === 406) {
        const { message } = err.response.data.error;
        const caseTeamIds = message.split(":");
        const teamNames = allTeams.filter((team: any) => {
          return caseTeamIds.includes(team.ID);
        });
        setSwitchTeam({ open: true, teams: teamNames });
        return;
      }
      exceptionHandler(err, t);
      errorBoundary(err);
    }
  };

  useEffect(() => {
    fetchCase();
    getMembers();
    getTeamClients();
    fetchPrescriptionTypes();
  }, [id, reportId, commentId]);

  const setDoubleCheckData = (value: any) => {
    if (value === null) {
      setCaseDoubleCheck(null);
      return;
    }
    setCaseDoubleCheck({
      reportId: value.reportId,
      completedAt: value.confirmedAt,
      changeRequestedAt: value.changeRequestedAt,
      id: value.id,
      userId: value.userId
    });
  };

  const handleStatus = (status: string) => {
    setCaseStatus(status);
  };

  const getMembers = async (id?: string) => {
    if (publicReport) return;
    try {
      let memberResponse: any;
      if (id) {
        memberResponse = await API.get(`members`, {
          params: {
            teamId: id
          }
        });
      } else {
        memberResponse = await API.get(`members`);
      }
      const filteredMembers = memberResponse.data.filter((item: any) => {
        return item.id !== "";
      });
      setAllMembers(filteredMembers);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const getTeamClients = async (id?: string) => {
    if (publicReport) return;
    try {
      let response: any;
      if (id) {
        response = await API.get(`/clients/`, {
          params: {
            id: id
          }
        });
      } else {
        response = await API.get(`/clients/`);
      }
      if (!response.data) {
        setClients([]);
        return;
      }

      const clients = response.data.filter((client: any) => {
        return client.type === "hospital";
      });

      setClients(clients);
    } catch (error) {
      setClients([]);
      exceptionHandler(error, t);
    }
  };

  const fetchPrescriptionTypes = async () => {
    try {
      const response = await API.get(`/prescription-types`);

      if (response.data.prescriptionTypes) {
        setPrescriptionTypes(response.data.prescriptionTypes);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const onSwitchTeamCancel = () => {
    history.push("/");
  };

  return (
    <ComponentWrapper className={classes.componentWrapper}>
      <div className={classes.containWrapper}>
        {!loading ? (
          <ReportWrapper
            caseStatus={caseStatus}
            caseState={caseState}
            setCaseState={setCaseState}
            lastCaseState={lastCaseState}
            setLastCaseState={setLastCaseState}
            allMembers={allMembers}
            handleStatus={handleStatus}
            reportList={reportList}
            setReportList={setReportList}
            medicineList={medicines}
            caseDoubleCheck={caseDoubleCheck}
            setDoubleCheckData={setDoubleCheckData}
            publicReport={publicReport}
            clients={clients}
            prescriptionTypes={prescriptionTypes}
            viewAsTeamOwner={viewAsTeamOwner}
          />
        ) : (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
      </div>
      <SwitchTeamModal
        visible={switchTeam.open}
        caseTeams={switchTeam.teams}
        onCancel={onSwitchTeamCancel}
      />
    </ComponentWrapper>
  );
};
