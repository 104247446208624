import { MoreOutlined } from "@ant-design/icons";
import { Popover, Tabs } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import {
  Alert,
  API,
  AuthContext,
  Button,
  DeleteConfirm,
  exceptionHandler,
  FilePlusIcon,
  theme
} from "shared-components";
import { TableComponent } from "shared-components/src/components/molecules/TableComponent";

import { ComponentWrapper } from "../../components";
import { RequestTag } from "../../components/atoms/RequestTag";
import {
  requestActions,
  requestType,
  statusLabel,
  statusValue
} from "./actionValueMap";
import { RequestActionModal } from "./RequestActionModal";

const useStyle = createUseStyles({
  wrapper: {
    width: "100%",
    paddingTop: 33,
    paddingBottom: 33,
    height: "calc(100vh - 100px) !important"
  },
  tableClassName: {
    "& .ant-table-tbody > tr > td": {
      "&:last-child": { padding: "0px 10px", maxWidth: "60px", width: "60px" },
      "&:nth-last-child(2)": { padding: "0px 6px", maxWidth: "60px" },
      "&:nth-last-child(3)": { padding: "0px 6px", maxWidth: "60px" }
    }
  },
  tabStyle: {
    "& .ant-tabs-nav-list > :first-child": {
      marginLeft: 38
    },
    "& .ant-tabs-nav::before": {
      borderBottom: "none"
    },
    fontFamily: "Noto Sans JP"
  },
  iconwrap: {
    fontSize: "24px"
  },
  deleteicon: {
    display: "flex",
    height: "35px",
    width: "184px",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      paddingLeft: 12
    }
  },
  tagStyle: {
    display: "flex",
    justifyContent: "center"
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 17
  },
  button: {
    background: theme.darkBlueButton,
    color: theme.textWhite,
    height: 32,
    borderRadius: 16,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: 17
    },
    "&:hover ": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:focus": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:active": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    boxShadow: " 0px 3px 6px #00000029",
    fontFamily: "Noto Sans JP"
  },
  iconMenu: {
    padding: "0px 5px",
    fontSize: "16px"
  },
  popoverContainer: {
    "& .ant-popover-inner-content": {
      padding: "0px !important"
    }
  },
  actions: {
    display: "flex",
    flexDirection: "column"
  },
  actionMenu: {
    padding: "10px 15px",
    minWidth: 120,
    cursor: "pointer",
    "&:hover": {
      background: theme.background
    }
  },
  actionMenuWrapper: {
    width: "60px",
    position: "absolute",
    left: 0,
    margin: {
      top: -8,
      right: -15
    }
  }
});

export const Request = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const { defaultTeam } = useContext(AuthContext);

  const [loading, setLoading] = useState(true as boolean);
  const [requests, setRequests] = useState([] as RequestData[]);
  const [totalRequests, setTotalRequests] = useState(0 as number);
  const [requestAction, setRequestAction] = useState({
    open: false,
    action: "cancel_request",
    handleConfirm: null,
    data: {} as RequestData
  } as RequestAction);

  const [pagination, setPagination] = useState({ page: 1, pageSize: 30 } as {
    page: number;
    pageSize?: number;
  });

  const [filters, setFilters] = useState(
    {} as {
      sortOrder?: string;
      sortKey?: string;
    }
  );

  const basicColumns = [
    {
      title: t("Status"),
      dataIndex: "status",
      render: (text: any) => {
        return (
          <div className={classes.iconwrap}>
            <RequestTag type={statusLabel[text]} />
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.status - b.status;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Type"),
      dataIndex: "requestType",
      key: "requestType",
      render: (text: any) => {
        return text ? <>{t(`${requestType[text]}`)}</> : "-";
      },
      sorter: (a: any, b: any) => {
        return a.requestType - b.requestType;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Client"),
      dataIndex: "client",
      key: "client",
      render: (text: string, data: any) => {
        return data && data.organization
          ? `${data.organization} ${data.client}`
          : "-";
      },
      sorter: (a: any, b: any) => {
        return a.client - b.client;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Transaction ID "),
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text: string, data: any) => {
        return data && data.transactionId
          ? `${data.transactionId}`.padStart(6, "0")
          : "--";
      },
      sorter: (a: any, b: any) => {
        return a.transactionId - b.transactionId;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      render: (text: string, data: any) => {
        return data && data.price ? data.price.toLocaleString() : "-";
      },
      sorter: (a: any, b: any) => {
        return a.price - b.price;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Due date"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text: string, data: any) => {
        return data && data.dueDate
          ? moment(data.dueDate).format("YYYY/MM/DD HH:mm:ss")
          : "-";
      },
      sorter: (a: any, b: any) => {
        return a.dueDate - b.dueDate;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Created at"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: string) => {
        return data ? moment(data).format("YYYY/MM/DD HH:mm:ss") : "-";
      },
      sorter: (a: any, b: any) => {
        return a.createdAt - b.createdAt;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Updated"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (data: string) => {
        return data ? moment(data).format("YYYY/MM/DD HH:mm:ss") : "-";
      },
      sorter: (a: any, b: any) => {
        return a.updatedAt - b.updatedAt;
      },
      sortDirections: ["descend", "ascend"]
    }
  ];

  const sentColumns = [
    ...basicColumns,
    {
      key: "action",
      render: (text: string, data: RequestData) => {
        return (
          <span
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              return event.stopPropagation();
            }}
            className={classes.actionMenuWrapper}
          >
            <Popover
              content={() => {
                return popOverContent(data);
              }}
              overlayClassName={classes.popoverContainer}
              trigger="hover"
            >
              <MoreOutlined className={classes.iconMenu} />
            </Popover>
          </span>
        );
      }
    }
  ];

  const receivedColumns = [...basicColumns];

  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("" as string);

  const columns = activeTab === "sent" ? sentColumns : receivedColumns;

  useEffect(() => {
    const pageSize = parseInt(localStorage.getItem("diagnosisPageSize") || "");
    if (pageSize && pageSize !== pagination.pageSize) {
      setPagination({ ...pagination, pageSize });
    }
    let tab = localStorage.getItem("diagnosisTab");
    if (tab === null) tab = "sent";
    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    if (activeTab !== "") getRequestList();
  }, [activeTab, pagination, filters]);

  const getRequestList = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(`requests`, {
        params: {
          request: activeTab,
          ...pagination,
          ...filters
        }
      });
      setLoading(false);
      if (!response.data) {
        setRequests([]);
        return;
      }
      const requestList = response.data.diagnosisRequests;
      setRequests(requestList);
      setTotalRequests(response.data.count);
    } catch (error) {
      setLoading(false);
      setRequests([]);
      setTotalRequests(0);
      exceptionHandler(error, t);
    }
  };

  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    localStorage.setItem("diagnosisTab", activeKey);
  };

  const handleRequestDuplicate = async (data: RequestData) => {
    history.push({
      pathname: `/requests/add`,
      state: { duplicateRequestID: data.id }
    });
  };

  const handleRequestCancel = async (params: {
    action: string;
    comment?: string;
    data: RequestData;
  }) => {
    try {
      if (
        ![
          statusValue.Accepting,
          statusValue.Estimating,
          statusValue.Ordering,
          statusValue.Checking,
          statusValue.Working,
          statusValue.Rejected,
          statusValue.Declined
        ].includes(params.data.status)
      ) {
        Alert("error", "error", t("Request cannot be canceled."), t);
        setLoading(false);
        return;
      }
      await API.post(`requests/${params.data.id}/actions`, {
        action: params.action,
        comment: params.comment
      });
      setRequestAction({ open: false, action: "", handleConfirm: null });
      getRequestList();
      setLoading(false);

      if (
        params.action === requestActions.REQUEST_CANCEL &&
        params.data &&
        params.data?.status > 16
      )
        Alert("success", "success", t("The cancel request was sent."), t);
      else Alert("success", "success", t("Request cancelled successfully."), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleRequestRemove = (data: RequestData) => {
    DeleteConfirm(async () => {
      setLoading(true);
      try {
        if (data.status !== statusValue.Editing) {
          Alert("error", "error", t("Request cannot be removed."), t);
          setLoading(false);
          return;
        }
        await API.delete(`requests/${data.id}`);
        getRequestList();
        setLoading(false);
        Alert("success", "success", t("Request removed successfully."), t);
      } catch (error) {
        setLoading(false);
        exceptionHandler(error, t);
      }
    }, "Are you sure you want to delete the request you are creating?");
  };

  const popOverContent = (data: RequestData) => {
    return (
      <div className={classes.actions}>
        <p
          className={classes.actionMenu}
          onClick={() => {
            return handleRequestDuplicate(data);
          }}
        >
          {t("Duplicate")}
        </p>
        {[
          statusValue.Accepting,
          statusValue.Rejected,
          statusValue.Ordering,
          statusValue.Working,
          statusValue.Checking,
          statusValue.Declined
        ].includes(data.status) &&
          data.teamId === defaultTeam && (
            <p
              className={classes.actionMenu}
              onClick={() => {
                return setRequestAction({
                  open: true,
                  action: requestActions.REQUEST_CANCEL,
                  handleConfirm: handleRequestCancel,
                  data: data
                });
              }}
            >
              {t("Cancel")}
            </p>
          )}
        {data.status === statusValue.Editing && data.teamId === defaultTeam && (
          <p
            className={classes.actionMenu}
            onClick={() => {
              return handleRequestRemove(data);
            }}
          >
            {t("Remove")}
          </p>
        )}
      </div>
    );
  };

  const handleRow = (record: RequestData) => {
    return {
      onClick: () => {
        history.push(`/requests/${record.id}`);
      }
    };
  };

  const handleCreateRequest = async () => {
    history.push(`/requests/add`);
  };

  const handleSizeChange = (page: number, pageSize: number) => {
    localStorage.setItem("diagnosisPageSize", `${pageSize}`);
    return setPagination({ page, pageSize });
  };

  const handleSortChange = (pagination: any, filters1: any, sorter: any) => {
    setLoading(true);
    const { columnKey, order } = sorter;
    const newFilters = {
      sortOrder: order,
      sortKey: order ? columnKey : undefined
    };
    setFilters({ ...newFilters });
  };

  return (
    <ComponentWrapper className={classes.wrapper}>
      <div className={classes.buttonHolder}>
        <Button
          icon={<FilePlusIcon />}
          className={classes.button}
          onClick={handleCreateRequest}
        >
          {" "}
          {t("Create request")}
        </Button>
      </div>
      <Tabs
        className={classes.tabStyle}
        onChange={handleTabChange}
        activeKey={activeTab}
      >
        <TabPane tab={t("Sent requests")} key="sent" />
        <TabPane tab={t("Received requests")} key="received" />
      </Tabs>
      <TableComponent
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={requests}
        handleRow={handleRow}
        className={classes.tableClassName}
        pagination
        currentPage={pagination.page}
        pageSize={pagination.pageSize}
        totalData={totalRequests}
        handleSizeChange={handleSizeChange}
        handlePaginationChange={(page: number, pageSize?: number) => {
          return setPagination({ page, pageSize });
        }}
        onChange={handleSortChange}
      />
      <RequestActionModal
        requestAction={requestAction}
        setRequestAction={setRequestAction}
      />
    </ComponentWrapper>
  );
};
