import { Form, Modal, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, TextArea } from "shared-components";

import { actionList, requestActions } from "./actionValueMap";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalBox: {
      height: 680,
      width: "850px !important",
      "& .ant-modal-content": {
        height: "100%",
        "& .ant-modal-body": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50
        }
      }
    },
    createButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      marginTop: 50,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    modal: {
      width: "60%",
      marginTop: "10%"
    },
    text: {
      maxWidth: "100%",
      fontSize: 16,
      color: theme.darkBackground
    },
    modalButton: {
      textAlign: "right"
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-around",
      marginBottom: 18,
      marginTop: 18
    },
    addButton: {
      backgroundColor: theme.darkBlueButton,
      width: 145,
      height: 28,
      padding: 2,
      borderRadius: 2
    }
  };
});

interface Props {
  requestAction: RequestAction;
  setRequestAction: React.Dispatch<React.SetStateAction<RequestAction>>;
}

export const RequestActionModal = ({
  requestAction,
  setRequestAction
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    await handleConfirm({
      comment,
      action: requestAction.action,
      data: requestAction.data
    });
    setComment("");
  };

  const [comment, setComment] = useState("" as string);

  const handleChange = (event: any) => {
    const { value } = event.target;
    setComment(value);
  };

  const { open, handleConfirm, action } = requestAction;

  const onClose = () => {
    setComment("");
    setRequestAction({
      open: false,
      action: "",
      handleConfirm: null
    });
  };

  return (
    <Modal
      onCancel={onClose}
      visible={open}
      mask={false}
      footer={null}
      className={classes.modalBox}
    >
      <Form form={form} className={classes.modal} onFinish={handleSubmit}>
        <Typography>
          {requestAction.action === requestActions.REQUEST_CANCEL &&
          requestAction.data &&
          requestAction.data?.status > 16
            ? t("Are you sure you want to cancel the request?")
            : t(actionList[action]?.message)}
        </Typography>
        <TextArea
          name="comment"
          inputClass={classes.text}
          rows={5}
          label={t("Comment")}
          value={comment}
          onChange={handleChange}
        />
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.addButton}
            type="primary"
            onClick={onClose}
          >
            {t("No")}
          </Button>
          <Button
            className={classes.addButton}
            type="primary"
            htmlType="submit"
          >
            {t("Yes")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
