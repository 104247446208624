import React from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "shared-components";

let useStyles = createUseStyles((theme: any) => {
  return {
    formTitleStyle: {
      color: theme.textBlue
    }
  };
});

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export function FormTitle({ children, className }: Props) {
  const classes = useStyles();
  return (
    <Typography
      className={`${classes.formTitleStyle} ${className}`}
      fontSize="24px"
      lineHeight="29px"
    >
      {children}
    </Typography>
  );
}
