import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {},
    labelStyle: {
      fontSize: "12px",
      lineHeight: "14px",
      marginBottom: 4,
      color: theme.gray6
    },
    valueStyle: {
      fontSize: "16px",
      lineHeight: "19px",
      whiteSpace: "break-spaces",
      color: theme.gray6
    }
  };
});

interface Props {
  label: string;
  value: string | number;
  className?: string;
}

export const ShowData = ({ label, value, className }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.labelStyle}>{t(`${label}`)}</div>
      <div className={classes.valueStyle}>{t(`${value}`)}</div>
    </div>
  );
};
