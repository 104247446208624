import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { Redirect, Route } from "react-router-dom";
import { AuthContext, Forbidden, Loader, NotFound } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    loader: {
      marginLeft: "48%",
      marginTop: "20%"
    }
  };
});

export const RestrictedRoute = ({
  component: RouteComponent,
  ...rest
}: any) => {
  const { loading, authenticated, user } = useContext(AuthContext);
  const classes = useStyles();
  const [error403, setError403] = useState(false as boolean);
  const [error404, setError404] = useState(false as boolean);

  const errorBoundary = (error?: any) => {
    if (error && error.response && error.response.status === 403) {
      setError403(true);
      return;
    }
    if (error && error.response && error.response.status === 404) {
      setError404(true);
    }
  };

  if (error403) {
    return <Forbidden />;
  }

  if (error404) {
    return <NotFound />;
  }

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (
          authenticated &&
          user?.emailVerified &&
          !routeProps.location.pathname.includes("public-report")
        ) {
          return (
            <Redirect to={{ pathname: "/", state: { prevPath: rest.path } }} />
          );
        }

        return <RouteComponent {...routeProps} errorBoundary={errorBoundary} />;
      }}
    />
  );
};
