import { Col, DatePicker, Form, Radio, Row } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  API,
  AuthContext,
  Button,
  DeleteConfirm,
  DeleteIcon,
  exceptionHandler,
  Loader,
  SelectComponent,
  TextArea,
  TextField,
  theme,
  Typography
} from "shared-components";
import { TableComponent } from "shared-components/src/components/molecules/TableComponent";

import { ComponentWrapper, SwitchTeamModal } from "../../components";
import { AgreementTags } from "../../components/atoms/AgreementTag";
import { CaseTags } from "../../components/atoms/CaseTag";
import { SwitchTeamValue } from "../cases/types/case";
import { actionValue, statusValue } from "./actionValueMap";
import { RequestActionModal } from "./RequestActionModal";
import { RequestActions } from "./RequestActions";
import { SelectCaseModal } from "./SelectCaseModal";

const useStyle = createUseStyles({
  wrapper: {
    paddingTop: 33,
    paddingBottom: 33,
    height: "calc(100vh - 100px) !important"
  },
  tableClassName: {
    "& .ant-table-tbody > tr > td": {
      "&:last-child": { padding: "0px 15px" },
      "&:nth-last-child(2)": { padding: "0px 10px", maxWidth: "80px" },
      "&:nth-last-child(3)": { padding: "0px 10px", maxWidth: "80px" },
      paddingLeft: 5
    },
    "& .ant-table-tbody > tr > :first-child": {
      paddingLeft: 0
    },
    marginBottom: 25
  },
  iconwrap: {
    fontSize: "24px",
    display: "flex"
  },
  delete: {
    fontSize: "16px"
  },
  tagStyle: {
    display: "flex",
    justifyContent: "center"
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: 92
  },
  button: {
    background: theme.darkBlueButton,
    color: theme.textWhite,
    height: 32,
    width: 145,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    "& svg": {
      fontSize: 17
    },
    "&:hover ": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:focus": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:active": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    boxShadow: " 0px 3px 6px #00000029"
  },
  formFields: {
    paddingLeft: 94,
    paddingRight: 91
  },
  headerText: {
    lineHeight: "29px",
    cursor: "pointer",
    color: theme.gray7
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 46,
    color: theme.gray7
  },
  label: {
    fontSize: 16,
    lineHeight: "19px",
    color: theme.gray7,
    marginBottom: 20
  },
  radio: {
    "& .ant-radio-group": {
      display: "flex",
      flexDirection: "column"
    },
    "& .ant-radio-wrapper": {
      marginBottom: 27
    },
    "& .ant-radio-group > :last-child": {
      marginBottom: 0
    },
    marginBottom: 40
  },
  back: {
    cursor: "pointer"
  },
  inputLabel: {
    fontSize: 16,
    lineHeight: "19px",
    color: theme.gray7,
    marginBottom: 14
  },
  select: {
    width: 325,
    "& .ant-select-selector": {
      height: "32px !important"
    }
  },
  toContainer: {
    marginBottom: 32
  },
  textArea: {
    width: 325
  },
  bookmarkIcon: {
    cursor: "pointer",
    borderRadius: "50%",
    color: theme.textYellow,
    padding: 10,
    fontSize: 21
  },
  flagIconGreen: {
    fontSize: 30,
    color: theme.textGreen
  },
  flagIconYellow: {
    fontSize: 30,
    color: theme.textYellow
  },
  teamicon: {
    marginLeft: "-18px"
  },
  disabledIcon: {
    "& svg": {
      fill: "#bbbbbb"
    }
  },
  submit: {
    width: "100%",
    "& .ant-form-item-control-input-content": {
      display: "flex",
      justifyContent: "center"
    }
  },
  logContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 50,
    paddingLeft: 20,
    paddingRight: 20
  },
  activityLog: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px !important"
  },
  seeAll: {
    marginTop: 30,
    fontSize: 14,
    color: theme.primary,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  logComment: {
    fontSize: 16,
    wordBreak: "break-all"
  },
  loader: {
    textAlign: "center",
    width: "100%",
    marginTop: "25%"
  },
  datePicker: {
    minWidth: 325,
    "& span": {
      display: "flex"
    }
  },
  formText: {
    maxWidth: 325,
    minWidth: 325
  },
  error: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 12,
    color: theme.error,
    fontWeight: 300,
    display: "flex",
    lineHeight: "20px"
  },
  caseList: {
    paddingLeft: 15,
    paddingRight: 15
  }
});

interface ParamTypes {
  id: string;
}

export const RequestDetails = ({ errorBoundary }: any) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: diagnosisRequestId } = useParams<ParamTypes>();
  const { state: { duplicateRequestID } = {} } = useLocation<any>();
  const {
    defaultTeam,
    organizationName,
    profile: { teams: allTeams }
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(true as boolean);

  const [form, setForm] = useState({} as RequestFormData);

  const [cases, setCases] = useState([] as CasesData[]);
  const [errors, setErrors] = useState({} as any);

  const [activities, setActivities] = useState([] as DiagnosisActivity[]);

  const [sender, setSender] = useState(true as boolean);
  const [selectCaseModal, setSelectCaseModal] = useState(false as boolean);
  const [isTracingReport, setIsTracingReport] = useState(true as boolean);

  const [requestAction, setRequestAction] = useState({
    open: false,
    action: "",
    handleConfirm: null
  } as RequestAction);

  const [clients, setClients] = useState([] as TeamClientData[]);

  const [senderEdit, setSenderEdit] = useState(false as boolean);
  const [recieverEdit, setReceiverEdit] = useState(false as boolean);
  const [senderCaseEdit, setSenderCaseEdit] = useState(false as boolean);

  const [switchTeam, setSwitchTeam] = useState({
    open: false,
    teams: []
  } as SwitchTeamValue);

  useEffect(() => {
    if (form && form.id && defaultTeam) {
      if ([1, 2, 4].includes(form.status)) {
        if (form.teamId === defaultTeam) {
          if (form.status === statusValue.Editing) {
            setSenderEdit(true);
            if (form.status !== 4) {
              setSenderCaseEdit(true);
            }
          } else {
            setSenderEdit(false);
          }
        } else {
          setSenderEdit(false);
          if (
            form.clientTeamId === defaultTeam &&
            [statusValue.Estimating, statusValue.Accepting].includes(
              form.status
            )
          ) {
            setReceiverEdit(true);
          } else {
            setReceiverEdit(false);
          }
        }
      } else {
        setSenderEdit(false);

        setReceiverEdit(false);
      }
    }

    if (form && !form.requestType) {
      setForm({
        ...form,
        requestType: "tracing"
      });
    }
  }, [form, defaultTeam, activities]);

  useEffect(() => {
    loadDiagnosisData();
    loadClients();
  }, [diagnosisRequestId, defaultTeam]);

  const loadClients = async () => {
    try {
      const response: any = await API.get(`clients/`);
      if (!response.data) {
        setClients([]);
        return;
      }
      setClients(response.data);
    } catch (error) {
      setClients([]);
      exceptionHandler(error, t);
    }
  };

  const columns = [
    {
      title: t("Case number"),
      dataIndex: "caseNumber",
      key: "caseNumber",
      align: "left"
    },
    {
      title: t("Patient"),
      dataIndex: "patient",
      key: "patient"
    },
    {
      title: t("Agreement"),
      dataIndex: "agreementStatus",
      key: "agreementStatus",
      render: (text: string) => {
        return (
          <div className={classes.tagStyle}>
            <AgreementTags type={text} />
          </div>
        );
      }
    },
    {
      title: t("Status"),
      dataIndex: "caseStatus",
      key: "caseStatus",
      render: (text: string) => {
        return (
          <div className={classes.tagStyle}>
            <CaseTags type={text} />
          </div>
        );
      }
    },
    {
      title: t("Pharmacist"),
      dataIndex: "pharmacist",
      key: "pharmacist"
    },
    {
      title: t("Physician"),
      dataIndex: "physician",
      key: "physician"
    },
    {
      title: t("Created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, data: any) => {
        return moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss");
      }
    },
    {
      title: t("Updated"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string, data: any) => {
        return moment(data.updatedAt).format("YYYY/MM/DD HH:mm:ss");
      }
    },
    {
      render: (record: any) => {
        if (form.status === statusValue.Editing && senderCaseEdit) {
          return (
            <DeleteIcon
              className={classes.delete}
              onClick={(event: any) => {
                event.stopPropagation();
                return handleDeleteCase(record.id);
              }}
            />
          );
        }
        return null;
      }
    }
  ];

  const handleFormChange = async (event: any) => {
    const { name, value } = event.target;

    if (name) {
      const oldForm = form;
      const newForm = {
        ...oldForm,
        [name]: name === "price" ? parseInt(value) : value
      };
      setForm(newForm);
      if (name === "requestType" && value !== "tracing") {
        setIsTracingReport(false);
        setCases([]);
      }
      if (name === "requestType" && value === "tracing") {
        setIsTracingReport(true);
      }
      setErrors({});
    }
  };

  const handleDeleteCase = async (id: number) => {
    const newCases = cases.filter((caseData: CasesData) => {
      return caseData.id !== id;
    });

    if (form.status === statusValue.Editing) {
      DeleteConfirm(async () => {
        setCases(newCases);
      });
    } else {
      setCases(newCases);
    }
  };

  const tracingOptions = sender
    ? clients
        .filter((client: any) => {
          return !client.address && client.type === "hospital";
        })
        .map((client: any) => {
          return {
            value: client.id,
            label: `${client.organizationName} ${client.teamName}`
          };
        })
    : [{ value: form.teamClientId, label: organizationName }];

  const otherOptions = sender
    ? clients.map((client: any) => {
        return {
          value: client.id,
          label: `${client.organizationName} ${client.teamName}`
        };
      })
    : [{ value: form.teamClientId, label: organizationName }];

  const loadDiagnosisData = async () => {
    if (
      diagnosisRequestId &&
      (diagnosisRequestId !== "add" || duplicateRequestID)
    ) {
      var requestID = diagnosisRequestId;
      if (duplicateRequestID) {
        requestID = duplicateRequestID;
      }
      setLoading(true);
      try {
        const response = await API.get(`/requests/${requestID}`);
        if (response.data) {
          const {
            diagnosisRequestCases,
            activities,
            diagnosisRequests
          } = response.data;
          setForm({
            ...diagnosisRequests,
            status: duplicateRequestID
              ? statusValue.Editing
              : diagnosisRequests.status,
            requestBody: duplicateRequestID ? "" : diagnosisRequests.requestBody
          });
          if (diagnosisRequests.requestType === "tracing") {
            setIsTracingReport(true);
          } else {
            setIsTracingReport(false);
          }

          if (diagnosisRequestCases) {
            setCases(diagnosisRequestCases);
          } else {
            setCases([]);
          }
          if (!duplicateRequestID) {
            if (activities) {
              setActivities(activities);
            } else {
              setActivities([]);
            }
          }

          if (diagnosisRequests.teamId === defaultTeam) {
            setSender(true);
          } else {
            setSender(false);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err && err.response && err.response.status === 406) {
          const { message } = err.response.data.error;
          const caseTeamIds = message.split(":");
          const teamNames = allTeams.filter((team: any) => {
            return caseTeamIds.includes(team.ID);
          });
          setSwitchTeam({ open: true, teams: teamNames });
          return;
        }
        exceptionHandler(err, t);
        errorBoundary(err);
      }
      return;
    }

    setSenderEdit(true);
    setSenderCaseEdit(true);
    setLoading(false);
  };

  const disabledToDate = (current: Moment) => {
    return current && current < moment().subtract(1, "days");
  };

  const onSwitchTeamCancel = () => {
    history.push("/request");
  };

  return (
    <ComponentWrapper className={classes.wrapper}>
      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <>
          <Form>
            <div className={classes.formFields}>
              <div
                className={classes.header}
                onClick={() => {
                  return history.push("/requests");
                }}
              >
                <Typography className={classes.headerText} fontSize={"24px"}>
                  {t("← Request")}
                </Typography>
              </div>
              <Form.Item
                id="requestType"
                required={false}
                name="requestType"
                className={classes.radio}
              >
                <>
                  <Typography className={classes.label}>
                    {t("Request Type")}
                  </Typography>
                  <Radio.Group
                    name="requestType"
                    value={form.requestType}
                    onChange={handleFormChange}
                    disabled={!senderEdit}
                  >
                    <Radio value={"tracing"}>{t("Tracing Report")}</Radio>
                    <Radio value={"inventory"}>
                      {t("Chemical inventory adjustment")}
                    </Radio>
                    <Radio value={"general"}>{t("General")}</Radio>
                  </Radio.Group>
                </>
              </Form.Item>
              <Form.Item className={classes.toContainer}>
                <Typography className={classes.inputLabel}>
                  {t("To")}
                </Typography>
                <SelectComponent
                  wrapperClassName={classes.select}
                  name={"teamClientId"}
                  disabled={!senderEdit}
                  error={errors.teamClientId}
                  options={isTracingReport ? tracingOptions : otherOptions}
                  onChange={handleFormChange}
                  value={form.teamClientId}
                />
              </Form.Item>
              {!isTracingReport && (
                <>
                  {" "}
                  <Form.Item className={classes.toContainer}>
                    <Typography className={classes.inputLabel}>
                      {t("Price")}
                    </Typography>
                    <TextField
                      positive
                      name="price"
                      type="number"
                      error={errors.price}
                      disabled={!recieverEdit}
                      editValue={form.price || undefined}
                      className={classes.formText}
                      onChange={handleFormChange}
                    />
                  </Form.Item>
                  <Form.Item className={classes.toContainer}>
                    <Typography className={classes.inputLabel}>
                      {t("Due date")}
                    </Typography>
                    <DatePicker
                      disabled={!recieverEdit}
                      className={classes.datePicker}
                      name="dueDate"
                      disabledDate={disabledToDate}
                      onChange={value => {
                        return handleFormChange({
                          target: { name: "dueDate", value: value?.toJSON() }
                        });
                      }}
                      value={form.dueDate ? moment(form.dueDate) : null}
                      placeholder={t("Select date")}
                    />
                    {errors.dueDate && (
                      <p className={classes.error}>{t(`${errors.dueDate}`)}</p>
                    )}
                  </Form.Item>{" "}
                </>
              )}
              <Form.Item>
                <Typography className={classes.inputLabel}>
                  {t("Message")}
                </Typography>
                <TextArea
                  rows={5}
                  containerClass={classes.textArea}
                  disabled={!senderEdit}
                  name={"requestBody"}
                  value={form.requestBody}
                  error={errors.requestBody}
                  onChange={handleFormChange}
                />
              </Form.Item>
            </div>

            {isTracingReport && (
              <>
                <div className={classes.buttonHolder}>
                  <Typography
                    className={`${classes.inputLabel} ${classes.formFields}`}
                  >
                    {t("Request Cases")}
                  </Typography>
                  {senderEdit && senderCaseEdit && (
                    <Button
                      className={classes.button}
                      onClick={() => {
                        return setSelectCaseModal(true);
                      }}
                    >
                      {t("Select cases")}
                    </Button>
                  )}
                </div>
                {form.requestType === "tracing" && (
                  <div className={classes.caseList}>
                    <TableComponent
                      rowKey="id"
                      columns={columns}
                      dataSource={cases}
                      className={classes.tableClassName}
                      handleRow={(record: any) => {
                        return {
                          onClick: () => {
                            return window.open(
                              `/cases/${record.id}${
                                record.diagnosisRequestId
                                  ? "?diagnosisRequestId=" +
                                    record.diagnosisRequestId
                                  : ""
                              }`,
                              "_blank",
                              "noopener noreferrer"
                            );
                          }
                        };
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </Form>

          <RequestActions
            {...{
              sender,
              form,
              errors,
              setErrors,
              cases,
              setForm,
              setRequestAction,
              activities,
              setActivities,
              requestAction,
              add: diagnosisRequestId === "add"
            }}
          />

          {activities && activities.length > 0 && (
            <div className={classes.logContainer}>
              <Typography fontSize="16px">{t("Activity log")}</Typography>

              {activities.map((log: DiagnosisActivity, index: number) => {
                return (
                  <Row className={classes.activityLog} key={index}>
                    <Col span={4}>
                      <Typography fontSize="16px">
                        {moment(log.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                      </Typography>
                    </Col>

                    <Col span={4}>
                      <Typography fontSize="16px">
                        {t(actionValue[log.action])}
                      </Typography>
                    </Col>

                    {log.teamName !== "" && (
                      <Col span={4}>
                        <Typography fontSize="16px">{log.teamName}</Typography>
                      </Col>
                    )}

                    {log.userName !== "" && (
                      <Col span={4}>
                        <Typography fontSize="16px">{log.userName}</Typography>
                      </Col>
                    )}

                    <Col span={log.teamName !== "" ? 8 : 16}>
                      <Typography className={classes.logComment}>
                        {log.comment}
                      </Typography>
                    </Col>
                  </Row>
                );
              })}
            </div>
          )}

          <SelectCaseModal
            open={selectCaseModal}
            onClose={() => {
              return setSelectCaseModal(false);
            }}
            cases={cases}
            setCases={setCases}
            form={form}
            latestActivity={activities[0]}
          />
          <RequestActionModal
            requestAction={requestAction}
            setRequestAction={setRequestAction}
          />
          <SwitchTeamModal
            visible={switchTeam.open}
            caseTeams={switchTeam.teams}
            onCancel={onSwitchTeamCancel}
          />
        </>
      )}
    </ComponentWrapper>
  );
};
