import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { SenderData } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      background: theme.formBackground,
      padding: "16px 40px 20px",
      marginTop: 9
    },
    titleStyle: {
      paddingBottom: 21
    },
    fromBlock: {
      display: "flex"
    },
    viewPatientInformation: {
      width: "33.3%"
    },
    lastColumn: {
      display: "flex",
      alignItems: "flex-end"
    },
    block: {
      marginBottom: 37
    }
  };
});

interface Props {
  agreementStatus: string;
  senderData?: SenderData;
}

export const Sender = ({ agreementStatus, senderData }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <FormTitle className={classes.titleStyle}>{t("Sender")}</FormTitle>
      <div className={classes.fromBlock}>
        <div className={classes.viewPatientInformation}>
          <ShowData
            className={classes.block}
            label="Pharmacy name"
            value={senderData?.name || "-"}
          />
          <ShowData label="Phone" value={senderData?.phone || "-"} />
        </div>
        <div className={classes.viewPatientInformation}>
          <ShowData
            className={classes.block}
            label="Address"
            value={senderData?.address || "-"}
          />
          <ShowData label="Fax" value={senderData?.fax || "-"} />
        </div>
        <div
          className={`${classes.viewPatientInformation} ${classes.lastColumn}`}
        >
          <ShowData
            label="Pharmacist"
            value={senderData?.pharmacist_name || "-"}
          />
        </div>
      </div>
    </div>
  );
};
