interface actions {
  SEND_REQUEST: string;
  REQUEST_QUOTE: string;
  EDIT_REQUEST: string;
  ACCEPT_REQUEST: string;
  SEND_BACK_REQUEST: string;
  REQUEST_CHECK: string;
  SEND_BACK_CHECK: string;
  COMPLETE_CHECK: string;
  ACCEPT_CANCEL: string;
  SEND_QUOTE: string;
  ORDER: string;
  REQUEST_CANCEL: string;
  [key: string]: string;
}

interface actionValue {
  send_request: string;
  request_quote: string;
  edit_request: string;
  accept_request: string;
  return_request: string;
  request_check: string;
  return_check: string;
  done: string;
  accept_cancel: string;
  send_quote: string;
  order: string;
  request_cancel: string;
  [key: string]: string;
}

interface statusList {
  Removed: number;
  Editing: number;
  Accepting: number;
  Estimating: number;
  Declined: number;
  Ordering: number;
  Working: number;
  Checking: number;
  Rejected: number;
  Done: number;
  Canceling: number;
  Canceled: number;
}

export const requestType: { [key: string]: string } = {
  tracing: "Tracing Report",
  inventory: "Chemical inventory adjustment",
  general: "General"
};

export const requestActions: actions = {
  SEND_REQUEST: "send_request",
  REQUEST_QUOTE: "request_quote",
  EDIT_REQUEST: "edit_request",
  ACCEPT_REQUEST: "accept_request",
  SEND_BACK_REQUEST: "return_request",
  REQUEST_CHECK: "request_check",
  SEND_BACK_CHECK: "return_check",
  COMPLETE_CHECK: "done",
  ACCEPT_CANCEL: "accept_cancel",
  SEND_QUOTE: "send_quote",
  ORDER: "order",
  REQUEST_CANCEL: "request_cancel"
};

export const actionValue: actionValue = {
  send_request: "Sent request",
  request_quote: "Requested quote",
  edit_request: "Edited request",
  accept_request: "Accepted request",
  return_request: "Returned request",
  request_check: "Requested check",
  return_check: "Returned check",
  done: "Completed check",
  accept_cancel: "Accepted cancellation",
  send_quote: "Sent quote",
  order: "Ordered",
  request_cancel: "Requested cancellation"
};

export const statusLabel: { [key: number]: string } = {
  0: "Removed",
  1: "Editing",
  2: "Accepting",
  4: "Estimating",
  8: "Declined",
  16: "Ordering",
  32: "Working",
  64: "Checking",
  128: "Rejected",
  256: "Done",
  512: "Canceling",
  1024: "Canceled"
};

export const statusValue: statusList = {
  Removed: 0,
  Editing: 1,
  Accepting: 2,
  Estimating: 4,
  Declined: 8,
  Ordering: 16,
  Working: 32,
  Checking: 64,
  Rejected: 128,
  Done: 256,
  Canceling: 512,
  Canceled: 1024
};

export const actionList: any = {
  send_request: { label: "Sent request", message: "" },
  request_quote: { label: "Requested quote", message: "" },
  edit_request: {
    label: "Edited request",
    message: "Are you sure you want to edit the request?"
  },
  accept_request: {
    label: "Accepted request",
    message: "Are you sure you want to accept request?"
  },
  order: {
    label: "Ordered",
    message: "Are you sure you want to order?"
  },
  return_request: {
    label: "Returned request",
    message: "Are you sure you want to return the request?"
  },
  send_quote: {
    label: "Sent quote",
    message: "Are you sure you want to send the quote?"
  },
  request_check: {
    label: "Requested check",
    message: "Are you sure you want to send the request check?"
  },
  return_check: {
    label: "Returned check",
    message: "Are you sure you want to return check?"
  },
  done: {
    label: "Completed check",
    message: "Are you sure you want to complete the check?"
  },
  request_cancel: {
    label: "Requested cancellation",
    message:
      "Are you sure you want to cancel the request? This item will be cancelled immediately. You can't undo this action."
  },
  accept_cancel: {
    label: "Accepted cancellation",
    message: "Are you sure you want to accept the cancellation?"
  }
};
