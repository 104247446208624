import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { TextArea } from "shared-components";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";

const useStyles = createUseStyles((theme: any) => {
  return {
    summaryWrapper: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9
    },
    title: {
      marginBottom: 21
    },
    summaryContent: {
      fontSize: 16,
      wordBreak: "break-word"
    }
  };
});

interface Props {
  complete: boolean;
  handleChange: (event: any) => void;
  answerComment: string;
  disabled?: boolean;
}

export const AnswerFeedback = ({
  complete,
  handleChange,
  answerComment,
  disabled
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.summaryWrapper}>
      <FormTitle className={classes.title}>{t("Answer/Feedback")}</FormTitle>

      {complete ? (
        <ShowData label={t("Comments")} value={answerComment || "-"} />
      ) : (
        <div>
          <TextArea
            name="answer_comment"
            value={answerComment}
            onChange={handleChange}
            rows={4}
            label={t("Comment")}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};
