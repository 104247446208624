import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  exceptionHandler,
  SelectComponent,
  TextField
} from "shared-components";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { Member } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9
    },
    titleStyle: {
      paddingBottom: 21
    },
    formBlock: {
      display: "flex",
      maxHeight: 47,
      justifyContent: "space-between"
    },
    bottomFormBlock: {
      display: "flex",
      maxHeight: 47,
      marginTop: 27
    },
    viewPatientTopInformation: {
      marginBottom: 17
    },
    selectOption: {
      marginBottom: 24
    },
    labelStyle: {
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0px",
      color: theme.gray6
    },
    patientIDStyle: { width: "22.9%" },
    patientNameStyle: { width: "48.6%" },
    sexBlockStyle: {
      position: "relative",
      top: 4,
      left: -4,
      "& span.ant-radio + *": {
        padding: {
          left: 2
        },
        position: "relative",
        bottom: -1,
        fontSize: "16px",
        lineHeight: "19px",
        color: theme.textLightBlack
      }
    },
    sexTextStyle: {
      position: "relative",
      left: 4
    },
    bottomSideField: {
      width: "31.4%"
    },
    gapStyle: {
      display: "block",
      width: "20px"
    },
    datePickerStyle: {
      width: "100%",
      marginTop: 1
    },
    selectStyle: {
      "& .ant-select-selector": {
        width: 325,
        height: "32px !important",
        borderColor: `${theme.colorGrey} !important`
      }
    }
  };
});

interface Props {
  complete: boolean;
  physicianUserId: string;
  pathologistName: string;
  administrativeStaff: string | null;
  clientStaffName: string;
  clients: any[];
  staffList: Member[];
  handleChange: (event: any) => void;
  disabled?: boolean;
  isReceiverReport: boolean;
  clientReport: boolean;
  clientTeamId: string;
  teamClientId: string;
}

export const Answerer = ({
  complete,
  physicianUserId,
  administrativeStaff,
  handleChange,
  disabled,
  clients,
  isReceiverReport,
  clientTeamId,
  teamClientId,
  pathologistName,
  clientStaffName
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [memberList, setMemberList] = useState([] as Member[]);

  const [showNameForms, setShowNameForms] = useState(false);
  const [selectedPhysicianName, setSelectedPhysicianName] = useState(
    "" as string
  );
  const [selectedStaffName, setSelectedStaffName] = useState("" as string);
  const [clientName, setClientName] = useState("" as string);

  useEffect(() => {
    if (teamClientId) {
      setShowNameForms(true);
      const client = clients.find((item: any) => {
        return item.id === teamClientId;
      });
      setClientName(
        `${client?.organizationName ? client.organizationName : ""} ${
          client?.teamName ? client.teamName : ""
        }`
      );
    } else if (clientTeamId) {
      getClientMembers(clientTeamId);

      const client = clients.find((item: any) => {
        return item.clientTeamId === clientTeamId;
      });
      setClientName(
        `${client?.organizationName ? client.organizationName : ""} ${
          client?.teamName ? client.teamName : ""
        }`
      );
    }
  }, []);

  const getClientMembers = async (teamId: string) => {
    try {
      const members = await API.get(`members`, {
        params: {
          teamId: teamId
        }
      });
      const filteredMembers = members.data.filter((item: any) => {
        return item.id !== "";
      });
      setMemberList(filteredMembers);

      const member = filteredMembers.find((item: any) => {
        return item.id === administrativeStaff;
      });
      setSelectedStaffName(member?.name);

      const physicianData = filteredMembers.find((item: any) => {
        return item.id === physicianUserId;
      });
      setSelectedPhysicianName(physicianData?.name);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleSelectChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    const member = memberList.find((item: any) => {
      return item.id === value;
    });

    if (name === "client_staff_user_id") {
      setSelectedStaffName(member?.name || "");
    } else {
      setSelectedPhysicianName(member?.name || "");
    }

    return handleChange({ target: { name, value } });
  };

  const handleChangeClient = (e: any) => {
    const value = e.target.value;

    const client = clients.find((item: any) => {
      return item.id === value;
    });

    setClientName(
      `${client?.organizationName ? client.organizationName : ""} ${
        client?.teamName ? client.teamName : ""
      }`
    );
    getClientMembers(client.clientTeamId);

    if (client.clientTeamId === "00000000-0000-0000-0000-000000000000") {
      setShowNameForms(true);
      handleChange({ changes: { clientTeamId: null, teamClientId: value } });
    } else {
      setShowNameForms(false);
      handleChange({
        changes: { clientTeamId: client.clientTeamId, teamClientId: null }
      });
    }
  };

  const handleChangeName = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    return handleChange({ target: { name, value } });
  };

  const physician = teamClientId ? pathologistName : selectedPhysicianName;
  const staff = teamClientId ? clientStaffName : selectedStaffName;

  return (
    <div className={classes.wrapper}>
      <FormTitle className={classes.titleStyle}>{t("Answerer")}</FormTitle>
      <div>
        {complete ? (
          <Row className={classes.viewPatientTopInformation}>
            {!isReceiverReport && (
              <Col span={8}>
                <ShowData label={t("Client")} value={clientName || "-"} />
              </Col>
            )}
            <Col span={8}>
              <ShowData label={t("Physician")} value={physician || "-"} />
            </Col>
            <Col span={8} style={{ paddingRight: 20 }}>
              <ShowData
                label={t("Administrative Staff")}
                value={staff || "-"}
              />
            </Col>
          </Row>
        ) : (
          <>
            {!isReceiverReport && (
              <Row
                className={classes.formBlock}
                style={{ marginBottom: "30px" }}
              >
                <Col span={11}>
                  <Form.Item required={false}>
                    <SelectComponent
                      labelFontSize={12}
                      onChange={(event: any) => {
                        handleChangeClient(event);
                      }}
                      name={"clientId"}
                      label={t("Client")}
                      value={clientName}
                      options={clients.map((client: any) => {
                        return {
                          label: `${client.organizationName} ${client.teamName}`,
                          value: client.id
                        };
                      })}
                      className={classes.selectStyle}
                      labelClassName={classes.labelStyle}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {!showNameForms && (
              <Row className={classes.formBlock}>
                <Col span={11}>
                  <Form.Item required={false}>
                    <SelectComponent
                      labelFontSize={12}
                      onChange={(event: any) => {
                        handleSelectChange(event);
                      }}
                      name={"pathologistUserId"}
                      label={t("Physician")}
                      value={selectedPhysicianName}
                      options={memberList.map((member: any) => {
                        return {
                          label: member.name,
                          value: member.id
                        };
                      })}
                      className={classes.selectStyle}
                      labelClassName={classes.labelStyle}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item required={false}>
                    <SelectComponent
                      labelFontSize={12}
                      onChange={handleSelectChange}
                      name="client_staff_user_id"
                      label={t("Administrative Staff")}
                      value={selectedStaffName}
                      options={memberList.map((member: any) => {
                        return {
                          label: member.name,
                          value: member.id
                        };
                      })}
                      className={classes.selectStyle}
                      labelClassName={classes.labelStyle}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {showNameForms && (
              <Row className={classes.formBlock}>
                <Col span={11}>
                  <Form.Item required={false} name="pathologistName">
                    <TextField
                      name="pathologistName"
                      label={t("Physician")}
                      editValue={pathologistName}
                      onChange={handleChangeName}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item required={false} name="clientStaffName">
                    <TextField
                      name="clientStaffName"
                      label={t("Administrative Staff")}
                      editValue={clientStaffName}
                      onChange={handleChangeName}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};
