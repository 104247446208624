import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Radio } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  Button,
  exceptionHandler,
  Loader,
  Modal,
  SelectComponent,
  TextField,
  Typography
} from "shared-components";

import { CsvErrorsComponent } from "../../components/atoms/CsvErrorsComponent.tsx";
import { Member } from "../cases/types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      flexDirection: "column",
      "& .ant-modal-body": {
        minWidth: 733,
        minHeight: 670,
        height: "max-content",
        display: "flex",
        flexDirection: "column",
        textAlign: "-webkit-match-parent",
        margin: 0
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      }
    },
    modalComponent: { marginLeft: 71 },
    header: ({ id }) => {
      return {
        display: "flex",
        color: theme.primary,
        fontSize: 24,
        letterSpacing: 0,
        marginBottom: id ? 28 : 19
      };
    },
    modal: {
      maxWidth: "100%",
      "& .ant-form-item": { marginBottom: 0 },
      "& .ant-form-item-explain, .ant-form-item-extra": { marginTop: -5 }
    },
    button: {
      height: 28,
      width: 145,
      fontSize: 14,
      lineHeight: "17px",
      letterSpacing: "0px",
      marginTop: 27
    },
    selectStyle: {
      "& .ant-select-selector": {
        maxWidth: 325,
        height: "32px !important",
        borderColor: `${theme.colorGrey} !important`
      }
    },
    nameField: ({ id }) => {
      return { marginTop: id ? 12 : 35 };
    },
    inputField: { width: 325, marginBottom: 9 },
    label: {
      color: theme.primary,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: "14px"
    },
    nameLabel: { marginBottom: 11 },
    addressLabel: { marginBottom: 2 },
    phoneLabel: { marginBottom: 7 },
    faxLabel: { marginBottom: 3 },
    faxField: { marginTop: 19 },
    phoneField: { marginTop: 14 },
    addressField: { marginTop: 15 },
    selectField: { width: 325, marginTop: 14 },
    radioLabelStyle: { margin: "16px 0px" },
    radioTextStyle: {
      "& .ant-radio-wrapper": {
        fontSize: 16,
        lineHeight: "19px",
        letterSpacing: 0,
        color: theme.textLightBlack,
        width: 332
      }
    },
    ownerNameFieldLabel: { margin: "16px 0px 7px" },
    ownerFieldLabel: { margin: "4px 0px 7px" },
    iconStyle: {
      fontSize: 18,
      position: "relative",
      top: 2
    },
    uploadButtonWrapper: {
      marginRight: 2,
      "& .ant-btn:hover, .ant-btn:focus, .ant-btn:active": {
        background: "#C7C7C7"
      },
      "& .ant-btn:hover, .ant-btn:focus": { color: "#000000" }
    },
    uploadCsvButtonStyle: {
      fontSize: 12,
      lineHeight: "26px",
      letteSpacing: "0.72px",
      color: "#000000",
      background: "#C7C7C7",
      border: "none",
      borderRadius: 2,
      "& .ant-btn:hover, .ant-btn:focus, .ant-btn:active": {
        background: "#C7C7C7"
      },
      "& .ant-btn:hover, .ant-btn:focus": {
        color: "#000000"
      }
    },
    downloadTemplateButtonStyle: {
      fontSize: 12,
      lineHeight: "14px",
      letteSpacing: 0,
      color: theme.textBlue,
      background: theme.lightBg,
      border: "none"
    },
    completeTextStyle: {
      fontSize: 16,
      lineHeight: "19px",
      letterSpacing: 0,
      color: theme.gray7,
      paddingTop: 17
    },
    processContentWrapper: {
      display: "flex",
      marginLeft: -71,
      height: 622,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    processingTitle: {
      paddingBottom: 20
    }
  };
});

interface Props {
  visible: boolean;
  onCancel: any;
  setVisibility: any;
  id?: string;
  members: Member[];
  setHasIdRef: (data: any) => Promise<void>;
  getMembers: any;
}
const CreateBranchModal = ({
  visible,
  onCancel,
  setVisibility,
  id,
  members,
  setHasIdRef,
  getMembers
}: Props) => {
  const classes = useStyles({ id });
  const { t } = useTranslation();
  const { organizationId, uid, getProfile, setProfile } = useContext(
    AuthContext
  );
  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState({} as TeamInterface);
  const [loading, setLoading] = useState(false as boolean);
  const [teamUsers, setTeamUsers] = useState({} as any);
  const [createOwner, setCreateOwner] = useState(false as boolean);

  const inputRef = useRef<HTMLInputElement>(null);
  const [downloading, setDownloading] = useState(false);
  const [processing, setProcessing] = useState(false as boolean);
  const [errors, setErrors] = useState([]);
  const [done, setDone] = useState(false as boolean);

  useEffect(() => {
    if (formValues.create) {
      setCreateOwner(true);
    } else {
      setCreateOwner(false);
    }
  }, [formValues.create]);

  useEffect(() => {
    if (id === "") {
      setFormValues({} as TeamInterface);
      form.resetFields();
      return;
    }
    fetchData(id);
  }, [visible]);

  const fetchData = async (id: any) => {
    const response = await API.get(`/teams/${id}`);
    if (response && response.data) {
      setFormValues(response.data.teams);
      setTeamUsers(response.data.team_users);
      form.setFieldsValue({
        name: response.data.teams.name,
        address: response.data.teams.address,
        phone: response.data.teams.phone,
        fax: response.data.teams.fax,
        user_id: response.data.team_users.userId,
        create: response.data.teams.create
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = !id
        ? await API.post("/teams", {
            ...formValues,
            organizationId
          })
        : await API.put(`/teams/${id}`, {
            ...formValues,
            organizationId,
            teamUserId: `${teamUsers.id}`
          });
      if (response && response.data) {
        setVisibility(false);
        setLoading(false);
        setFormValues({} as TeamInterface);
      }
      setProfile(await getProfile(uid));
      getMembers();
      form.resetFields();
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (!name) {
      return;
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (e: any) => {
    var name = e.target.name;
    var value = e.target.value;
    return handleChange({ target: { name, value } });
  };

  const handleTemplateDownload = async (e: any) => {
    const templateName = "team-template.csv";
    setDownloading(true);
    try {
      const res: any = await API.get(
        `/download-csv-templates?templateName=${templateName}`
      );
      if (res && res.data && res.data.templateUrl) {
        window.open(res.data.templateUrl);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setDownloading(false);
  };

  const handleCsvUpload = async (event: any) => {
    setProcessing(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    try {
      const res: any = await API.post("teams/bulk-registration", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      if (res && res.message === "success") {
        setDone(true);
        setProcessing(false);
      }
    } catch (error) {
      setErrors(error.response.data.csvDataError);
      setProcessing(false);
      exceptionHandler(t, error);
    }
  };

  const handleCloseModal = () => {
    getMembers();
    onCancel();
    setDone(false);
    setProcessing(false);
    setErrors([]);
  };

  return (
    <Modal
      className={classes.modalContainer}
      visible={visible}
      onCancel={handleCloseModal}
      width="850px"
      mask={true}
    >
      <div className={classes.modalComponent}>
        {done ? (
          <>
            <Typography className={classes.header}>
              {t("Contact information batch registration")}
            </Typography>
            <Typography className={classes.completeTextStyle}>
              {t("Bulk registration of contacts has been completed.")}
            </Typography>
          </>
        ) : processing ? (
          <div className={classes.processContentWrapper}>
            <Typography
              className={`${classes.header} ${classes.processingTitle}`}
            >
              {t("Batch registration in progress")}
            </Typography>
            <Loader size="large" />
          </div>
        ) : errors && errors.length ? (
          <CsvErrorsComponent
            errors={errors}
            handleCSVUpload={handleCsvUpload}
          />
        ) : (
          <>
            <Typography className={classes.header}>
              {id ? t("Edit Branch/Department") : t("Create Branch/Department")}
            </Typography>

            {!id && (
              <div style={{ display: "flex" }}>
                <div className={classes.uploadButtonWrapper}>
                  <input
                    ref={inputRef}
                    onChange={file => {
                      handleCsvUpload(file);
                    }}
                    type="file"
                    accept=".csv"
                    multiple={false}
                    style={{ display: "none" }}
                  />
                  <Button
                    icon={<UploadOutlined className={classes.iconStyle} />}
                    className={classes.uploadCsvButtonStyle}
                    onClick={() => {
                      return inputRef.current && inputRef.current.click();
                    }}
                  >
                    {t("Bulk registration of contacts (CSV upload)")}
                  </Button>
                </div>
                <Button
                  icon={<DownloadOutlined className={classes.iconStyle} />}
                  className={classes.downloadTemplateButtonStyle}
                  loading={downloading}
                  onClick={handleTemplateDownload}
                >
                  {t("Download template CSV")}
                </Button>
              </div>
            )}

            <Form className={classes.modal} onFinish={handleSubmit} form={form}>
              <Form.Item
                id="name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Required")
                  }
                ]}
              >
                <div className={classes.nameField}>
                  <Typography
                    className={`${classes.label} ${classes.nameLabel}`}
                  >
                    {t("Name2")}
                  </Typography>
                  <TextField
                    className={classes.inputField}
                    autoComplete="off"
                    name="name"
                    onChange={handleChange}
                    maxLength={45}
                    editValue={formValues.name}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: t("Required")
                  }
                ]}
              >
                <div className={classes.addressField}>
                  <Typography
                    className={`${classes.label} ${classes.addressLabel}`}
                  >
                    {t("Address")}
                  </Typography>
                  <TextField
                    className={classes.inputField}
                    autoComplete="off"
                    name="address"
                    onChange={handleChange}
                    maxLength={200}
                    editValue={formValues.address}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("Required")
                  }
                ]}
              >
                <div className={classes.phoneField}>
                  <Typography
                    className={`${classes.label} ${classes.phoneLabel}`}
                  >
                    {t("Phone")}
                  </Typography>
                  <TextField
                    className={classes.inputField}
                    autoComplete="off"
                    name="phone"
                    onChange={handleChange}
                    maxLength={20}
                    editValue={formValues.phone}
                    type="tel"
                  />
                </div>
              </Form.Item>
              <Form.Item name="fax">
                <div className={classes.faxField}>
                  <Typography
                    className={`${classes.label} ${classes.faxLabel}`}
                  >
                    {t("Fax")}
                  </Typography>
                  <TextField
                    className={classes.inputField}
                    autoComplete="off"
                    name="fax"
                    onChange={handleChange}
                    maxLength={20}
                    editValue={formValues.fax}
                    type="tel"
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <Typography
                  className={`${classes.label} ${classes.radioLabelStyle}`}
                >
                  {t("Team owner")}
                </Typography>
                <Radio.Group
                  className={classes.radioTextStyle}
                  value={formValues.create || false}
                  name="create"
                  onChange={handleChange}
                >
                  <Radio value={false}>
                    {t("Select from organization members")}
                  </Radio>
                  <Radio value={true}>{t("Create1")}</Radio>
                </Radio.Group>
              </Form.Item>
              {!createOwner ? (
                <Form.Item
                  id="user_id"
                  name="user_id"
                  rules={[
                    {
                      required: true,
                      message: t("Required")
                    }
                  ]}
                >
                  <SelectComponent
                    onChange={handleSelectChange}
                    name="user_id"
                    id="user_id"
                    value={formValues.user_id}
                    options={members.map((user: any) => {
                      return {
                        label: user.name,
                        value: user.id
                      };
                    })}
                    className={classes.selectStyle}
                    labelClassName={`${classes.label} ${classes.faxLabel}`}
                    wrapperClassName={classes.selectField}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    required={false}
                    name="owner_name"
                    rules={[
                      {
                        required: true,
                        message: t("Required")
                      },
                      {
                        max: 60,
                        message: t("Max Length Reached")
                      }
                    ]}
                  >
                    <TextField
                      label={t("Owner name")}
                      labelClassName={`${classes.label} ${classes.ownerNameFieldLabel}`}
                      className={classes.inputField}
                      name="owner_name"
                      onChange={handleChange}
                      maxLength={60}
                      editValue={formValues.owner_name}
                    />
                  </Form.Item>
                  <Form.Item
                    id="email"
                    required={false}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("Required")
                      },
                      {
                        type: "email",
                        message: t("Not a valid email")
                      },
                      {
                        max: 60,
                        message: t("Max Length Reached")
                      }
                    ]}
                  >
                    <TextField
                      label={t("Owner email")}
                      labelClassName={`${classes.label} ${classes.ownerFieldLabel}`}
                      className={classes.inputField}
                      name="email"
                      onChange={handleChange}
                      maxLength={60}
                      editValue={formValues.email}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: t("Required")
                      },
                      {
                        min: 8,
                        message: t("Must be at least 8 characters")
                      }
                    ]}
                  >
                    <TextField
                      label={t("Owner password")}
                      labelClassName={`${classes.label} ${classes.ownerFieldLabel}`}
                      className={classes.inputField}
                      name="password"
                      onChange={handleChange}
                      maxLength={60}
                      editValue={formValues.password}
                      type="password"
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    dependencies={["password"]}
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: t("Required")
                      },
                      ({ getFieldValue }: any) => {
                        return {
                          validator(rule: any, value: string) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              t("Password did not matched")
                            );
                          }
                        };
                      }
                    ]}
                  >
                    <TextField
                      name="confirm_password"
                      type="password"
                      label={t("Confirm Password")}
                      labelClassName={`${classes.label} ${classes.ownerFieldLabel}`}
                      className={classes.inputField}
                      onChange={handleChange}
                      editValue={formValues.confirm_password}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item>
                <Button
                  className={classes.button}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {id ? t("Update") : t("Create")}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </Modal>
  );
};

export { CreateBranchModal };
