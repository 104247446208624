import { Form, Radio } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, TextArea, Typography } from "shared-components";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { CaseData } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      background: theme.formBackground,
      padding: "24px 40px 8px",
      marginTop: 24
    },
    titleStyle: {
      paddingBottom: 23
    },
    labelStyle: {
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0px",
      color: theme.gray6
    },
    answerBlock: {
      marginBottom: 25
    },
    answerRadioBlock: {
      marginTop: 13,
      "& span.ant-radio + *": {
        padding: {
          left: 15
        },
        fontSize: "16px",
        lineHeight: "19px",
        color: theme.textLightBlack
      }
    },
    agreeRadioBlock: { marginBottom: 7 },
    patientCommentBlock: {
      marginBottom: 4
    },
    buttonStyle: {
      marginLeft: 18,
      width: 180,
      height: 28,
      fontSize: 14,
      lineHeight: "17px",
      letteSpacing: "0.84px",
      position: "relative",
      top: -2
    },
    viewComment: {
      margin: {
        top: 24,
        bottom: 28
      }
    },
    viewDescription: { marginBottom: 110 },
    agreementTextStyle: {
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0px",
      color: theme.gray6,
      marginBottom: 7
    },
    answerTextStyle: {
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0px",
      color: theme.textLightBlack
    }
  };
});

interface Props {
  caseState: CaseData;
  handleChange?: any;
  agreementStatus: string;
  handleWithDrawAgreement?: any;
  isPatient?: boolean;
  viewAsTeamOwner?: boolean;
}

export const AgreementInformation = ({
  caseState,
  handleChange,
  agreementStatus,
  handleWithDrawAgreement,
  isPatient,
  viewAsTeamOwner
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <FormTitle className={classes.titleStyle}>
        {t("Agreement Information")}
        {agreementStatus === "agree" && (
          <Button
            size="large"
            type="primary"
            onClick={handleWithDrawAgreement}
            className={classes.buttonStyle}
            disabled={viewAsTeamOwner}
          >
            {t("Withdraw agreement")}
          </Button>
        )}
      </FormTitle>
      {agreementStatus === "creating" ||
      (isPatient && agreementStatus === "waiting") ? (
        <div>
          <Form.Item
            id="answer"
            required={false}
            name="answer"
            className={classes.answerBlock}
          >
            <>
              <Typography className={classes.labelStyle}>
                {t("Agreement status")}
              </Typography>
              <Radio.Group
                name="answer"
                onChange={handleChange}
                value={caseState.answer || "agree"}
                className={classes.answerRadioBlock}
                disabled={viewAsTeamOwner}
              >
                <Radio value={"agree"} className={classes.agreeRadioBlock}>
                  {t("Agree")}
                </Radio>
                <br />
                <Radio value={"disagree"}>{t("Disagree")}</Radio>
              </Radio.Group>
            </>
          </Form.Item>

          <Form.Item
            required={false}
            name="patient_comment"
            rules={[
              {
                max: 3000,
                message: t("Max Length Reached")
              }
            ]}
            className={classes.patientCommentBlock}
          >
            <TextArea
              name="patient_comment"
              label={t(
                "Patient comment (terms of agreement, reasons for disagreement)"
              )}
              value={caseState.patient_comment}
              onChange={handleChange}
              rows={4}
              disabled={viewAsTeamOwner}
            />
            {""}
          </Form.Item>

          {!isPatient ? (
            <Form.Item
              required={false}
              name="agreement_description"
              rules={[
                {
                  max: 3000,
                  message: t("Max Length Reached")
                }
              ]}
            >
              <TextArea
                name="agreement_description"
                label={t("Description")}
                value={caseState.agreement_description}
                onChange={handleChange}
                rows={4}
                disabled={viewAsTeamOwner}
              />
              {""}
            </Form.Item>
          ) : (
            <ShowData
              label="Description"
              value={caseState.agreement_description || "-"}
              className={classes.viewDescription}
            />
          )}
        </div>
      ) : (
        <div>
          <>
            <Typography className={classes.agreementTextStyle}>
              {t("Agreement status")}
            </Typography>
            <Typography className={classes.answerTextStyle}>
              {agreementStatus === "disagree"
                ? t("Disagree")
                : agreementStatus === "agree"
                ? t("Agree")
                : t(
                    caseState.answer.charAt(0).toUpperCase() +
                      caseState.answer.slice(1)
                  )}
            </Typography>
          </>
          <ShowData
            label="Patient comment (terms of agreement, reasons for disagreement)"
            value={caseState.patient_comment || "-"}
            className={classes.viewComment}
          />
          <ShowData
            label="Description"
            value={caseState.agreement_description || "-"}
            className={classes.viewDescription}
          />
        </div>
      )}
    </div>
  );
};
