import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Radio } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Button,
  Loader,
  Modal,
  TextArea,
  TextField,
  theme,
  Typography
} from "shared-components";

import { CsvErrorsComponent } from "../../components/atoms/CsvErrorsComponent.tsx";

const useStyle = createUseStyles({
  formWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  modal: {
    marginTop: "-5px",
    "& .ant-form-item": {
      marginBottom: "14px !important"
    }
  },
  addButton: {
    backgroundColor: theme.darkBlueButton,
    width: "145px",
    height: "28px",
    padding: "2px",
    borderRadius: "2px"
  },
  text: {
    width: "325px",
    color: theme.darkBackground,
    flex: "inherit",
    marginTop: "11px"
  },

  modalBox: {
    "& .ant-modal-body": {
      textAlign: "-webkit-match-parent",
      // paddingLeft: "153px",
      height: "auto"
    },
    "& .ant-form-item-control-input-content": {
      flex: "inherit",
      maxwidth: "100%"
    },
    "& .ant-modal-content": {
      width: "733px"
    },
    "& .ant-radio": {
      paddingBottom: "2px"
    }
  },
  buttonWrapper: {
    display: "flex"
  },
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: theme.primary,
    marginTop: "-5px",
    marginBottom: "19px",
    letterSpacing: "0px"
  },
  input: {
    display: "none"
  },

  labeltext: {
    color: theme.textBlue,
    fontSize: "12px",
    letterSpacing: 0,
    fontWeight: "normal"
  },
  radiobutton: {
    fontSize: "16px !important",
    letterSpacing: 0,
    color: theme.textLightBlack,
    "& .ant-radio-checked": {
      "& :after": {
        background: "#000000"
      }
    }
  },

  csvMenu: {
    display: "flex",
    marginBottom: 43
  },
  csvButton: {
    maxWidth: 319,
    backgroundColor: "#C7C7C7",
    color: "#000000",
    letterSpacing: "0.72px",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:focus": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:active": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    }
  },
  csvLink: {
    border: "none",
    maxWidth: 202,
    color: theme.darkBlueButton,
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: 0,
    marginLeft: 13,
    "&:hover ": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:focus": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:active": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    }
  },
  buttonIcon: {
    fontSize: 16
  },

  inProgress: {
    display: "flex",
    height: 500,
    flexDirection: "column",
    justifyContent: "center"
  },
  doneInfo: {
    marginTop: 17
  },
  message: {
    fontFamily: "Noto Sans JP",
    fontSize: 16,
    lineHeight: "19px",
    color: theme.gray7,
    letterSpacing: 0
  },
  doneModal: {
    height: 428,
    paddingLeft: 129
  },

  errorModal: {
    height: 756,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 129
  }
});

interface AddClientProps {
  toggleVisibility?: any;
  visibility?: any;
  handleCSVUpload?: any;
  handleTemplateDownload?: any;
  handleSubmitCreateClient?: any;
  handleChangeClientData?: any;
  errors?: any;
  formValue?: any;
  templateDownloadLoading?: boolean;
  processing?: boolean;
  done?: boolean;
  form?: FormInstance<any>;
}

export const AddClient = ({
  toggleVisibility,
  visibility,
  handleCSVUpload,
  handleTemplateDownload,
  handleSubmitCreateClient,
  handleChangeClientData,
  errors,
  formValue,
  templateDownloadLoading,
  processing,
  done,
  form
}: AddClientProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Modal
      onCancel={toggleVisibility}
      visible={visibility}
      mask={false}
      className={classes.modalBox}
      centered
    >
      {processing ? (
        <div className={`${classes.inProgress} ${classes.formWrapper}`}>
          <Typography className={classes.title}>
            {t("Batch registration in progress")}
          </Typography>
          <Loader size="large" />
        </div>
      ) : done ? (
        <div className={classes.doneModal}>
          <Typography className={classes.title}>
            {t("Contact information batch registration")}
          </Typography>
          <div className={classes.doneInfo}>
            <Typography className={classes.message}>
              {t("Bulk registration of contacts has been completed.")}
            </Typography>
          </div>
        </div>
      ) : errors && errors.length ? (
        <div className={classes.errorModal}>
          <CsvErrorsComponent
            errors={errors}
            handleCSVUpload={handleCSVUpload}
          />
        </div>
      ) : (
        <div style={{ paddingLeft: 129 }}>
          <Typography className={classes.title}>
            {formValue.id ? t("Edit client") : t("Add client")}
          </Typography>
          {!formValue.id && (
            <div className={classes.csvMenu}>
              <input
                ref={inputRef}
                onChange={file => {
                  handleCSVUpload(file);
                }}
                type="file"
                accept=".csv"
                multiple={false}
                className={classes.input}
              />
              <Button
                icon={<UploadOutlined className={classes.buttonIcon} />}
                className={classes.csvButton}
                onClick={() => {
                  return inputRef.current && inputRef.current.click();
                }}
              >
                {t("Bulk registration of contacts (CSV upload)")}
              </Button>
              <div onClick={handleTemplateDownload}>
                <Button
                  className={classes.csvLink}
                  icon={<DownloadOutlined className={classes.buttonIcon} />}
                  loading={templateDownloadLoading}
                >
                  {t("Download template CSV")}
                </Button>
              </div>
            </div>
          )}
          <Form
            form={form}
            className={classes.modal}
            onFinish={handleSubmitCreateClient}
            layout="vertical"
          >
            <Form.Item
              name="organizationName"
              rules={[
                {
                  required: true,
                  message: t("Required")
                }
              ]}
            >
              <TextField
                className={classes.text}
                label={t("Organization name")}
                name="organizationName"
                maxLength={100}
                labelClassName={classes.labeltext}
                editValue={formValue.organizationName}
                onChange={handleChangeClientData}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              id="organization Type"
              required={false}
              name="type"
              label={
                <span className={classes.labeltext}>
                  {t("Organization type")}
                </span>
              }
            >
              <Radio.Group
                name="type"
                defaultValue="hospital"
                onChange={handleChangeClientData}
                value={formValue.type}
              >
                <Radio value={"hospital"} className={classes.radiobutton}>
                  {t("Hospital")}
                </Radio>
                <Radio value={"pharmacy"} className={classes.radiobutton}>
                  {t("Pharmacy")}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="teamName"
              rules={[
                {
                  required: true,
                  message: t("Required")
                }
              ]}
            >
              <TextField
                className={classes.text}
                label={t("Branch/Department")}
                name="teamName"
                maxLength={45}
                labelClassName={classes.labeltext}
                editValue={formValue.teamName}
                onChange={handleChangeClientData}
              />
            </Form.Item>

            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: t("Required")
                }
              ]}
            >
              <TextField
                className={classes.text}
                label={t("Address")}
                name="address"
                maxLength={200}
                labelClassName={classes.labeltext}
                editValue={formValue.address}
                onChange={handleChangeClientData}
              />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("Required")
                }
              ]}
            >
              <TextField
                className={classes.text}
                label={t("Phone")}
                name="phone"
                maxLength={20}
                labelClassName={classes.labeltext}
                editValue={formValue.phone}
                onChange={handleChangeClientData}
                type="tel"
              />
            </Form.Item>
            <Form.Item name="fax">
              <TextField
                className={classes.text}
                label={t("Fax")}
                name="fax"
                maxLength={20}
                labelClassName={classes.labeltext}
                editValue={formValue.fax}
                onChange={handleChangeClientData}
                type="tel"
              />
            </Form.Item>
            <Form.Item
              name="pic"
              rules={[
                {
                  required: true,
                  message: t("Required")
                }
              ]}
            >
              <TextField
                className={classes.text}
                name="pic"
                label={t("Person in charge")}
                labelClassName={classes.labeltext}
                editValue={formValue.pic}
                onChange={handleChangeClientData}
              />
            </Form.Item>

            <Form.Item name="memo">
              <TextArea
                inputClass={classes.text}
                onChange={handleChangeClientData}
                name="memo"
                rows={5}
                label={t("Memo")}
                maxLength={3000}
                value={formValue.memo}
                labelClassName={classes.labeltext}
              />
            </Form.Item>

            <div className={classes.buttonWrapper}>
              <Button
                className={classes.addButton}
                type="primary"
                htmlType="submit"
              >
                {formValue.id ? t("Update") : t("Create")}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
};
