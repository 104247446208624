import { Col, Empty, Row } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  AuthContext,
  CaseCard,
  DatePickerComponent,
  debounceFunction,
  exceptionHandler,
  Loader,
  Search,
  Typography
} from "shared-components";

let useStyles = createUseStyles((theme: any) => {
  return {
    title: {
      fontSize: 14,
      color: theme.formLabelColor,
      lineHeight: "20px"
    },
    itemContainer: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column"
    },
    datePicker: {
      background: "white",
      border: `1px solid ${theme.colorLight}`,
      borderRadius: 3,
      marginBottom: 10,
      "& input": {
        background: "white"
      }
    },
    card: {
      width: 320
    },
    wrapper: {
      padding: "0px 4px 50px 25px"
    },
    rightDiv: {
      float: "right"
    },
    cardWrapper: {
      marginTop: 22,
      display: "flex",
      flexDirection: "column"
    },
    search: {
      width: 320,
      border: `1px solid ${theme.colorLight}`,
      borderRadius: 3,
      background: "white",
      "& input": {
        background: "white"
      },
      marginBottom: 10
    },
    row: {
      width: 330
    },
    count: {
      fontSize: 16,
      color: theme.colorLight,
      lineHeight: "21px"
    },
    emptyContainer: {
      width: 290
    }
  };
});

interface Props {
  handleTeamBookmark: (caseId?: string, team_id?: string) => Promise<void>;
  handleMyBookmark: (caseId?: string, userId?: string) => Promise<void>;
}

const SeeCase: React.FC<Props> = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false as boolean);
  const [cases, setCases] = useState([] as any);
  const { t } = useTranslation();
  const [filterChanged, setFilterChanged] = useState(false as boolean);
  const [filters, setFilters] = useState({
    from: "",
    to: "",
    search: ""
  });
  const { uid, defaultTeam } = useContext(AuthContext);

  useEffect(() => {
    debounceFunction(() => {
      if (filters.to === "" && filters.from === "" && filters.search === "") {
        setCases([]);
        setLoading(false);
      } else {
        getCases(filters);
      }
    });
  }, [filters]);

  const handleMyBookmark = async (caseId: string, userId: string) => {
    try {
      const response: any = await API.post("/toggle-user-marks", {
        case_id: caseId,
        user_id: userId
      });
      cases.forEach((item: any) => {
        if (item.id === caseId) {
          item.userMarked = !item.userMarked;
        }
      });
      setCases([...cases]);
      if (response && response.data) {
        Alert("success", "success", t("Case is added as your bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from your bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleTeamBookmark = async (caseId: string, team_id: string) => {
    try {
      const response: any = await API.post("/toggle-team-marks", {
        case_id: caseId,
        team_id: team_id
      });

      cases.forEach((item: any) => {
        if (item.id === caseId) {
          item.marked = !item.marked;
        }
      });
      setCases([...cases]);
      if (response && response.data) {
        Alert("success", "success", t("Case is added as team bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from team bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const disabledToDate = (current: Moment) => {
    return current && current < moment(filters.from);
  };

  const disabledFromDate = (current: Moment) => {
    return current && current > moment(filters.to);
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    try {
      await API.delete(`cases/${id}`);
      await getCases(filters);
      setLoading(false);
      Alert("success", "success", t("Case is deleted successfully"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };
  const getCases = async (filters: any) => {
    setLoading(true);
    try {
      const response = await API.get("cases", {
        params: filters
      });
      if (response && response.data) {
        setCases(response.data.cases);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLoading(false);
  };

  const handleFilterChange = (
    name: string,
    value: string | number | boolean
  ) => {
    setLoading(true);
    if (value) {
      setFilterChanged(true);
    } else {
      setFilterChanged(false);
    }
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
  };
  const handleCaseCardClick = (caseId: string) => {
    window.open(`/cases/${caseId}`);
  };
  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>{t("See case")}</span>
      <div className={classes.rightDiv}>
        {cases && cases.length !== 0 && (
          <span className={classes.count}>
            {cases.length} {t("cases")}
          </span>
        )}
      </div>

      <div className={classes.itemContainer}>
        <Search
          name="search"
          className={classes.search}
          placeholder={t("Hospital name, Doctor name, etc...")}
          onChange={handleFilterChange}
        />
        <Row gutter={10} className={classes.row}>
          <Col span={12}>
            <DatePickerComponent
              className={classes.datePicker}
              name="from"
              disabledDate={disabledFromDate}
              placeholder={t("From Date")}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={12}>
            <DatePickerComponent
              className={classes.datePicker}
              name="to"
              disabledDate={disabledToDate}
              placeholder={t("To Date")}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
        <div className={classes.cardWrapper}>
          {loading ? (
            <Loader size="large" />
          ) : filterChanged && cases && cases.length === 0 ? (
            <Empty
              description={t(
                "There are no cases that match your search conditions."
              )}
              className={classes.emptyContainer}
            />
          ) : cases ? (
            cases.length !== 0 &&
            cases.map((patient: any, index: number) => {
              return (
                <>
                  <CaseCard
                    noMargin
                    className={classes.card}
                    onClick={() => {
                      handleCaseCardClick(patient.id);
                    }}
                    patientInfo={patient}
                    handleMyBookmark={() => {
                      handleMyBookmark(patient.id, uid);
                    }}
                    handleTeamBookmark={() => {
                      handleTeamBookmark(patient.id, defaultTeam);
                    }}
                    handleDelete={() => {
                      handleDelete(patient.id);
                    }}
                  />
                </>
              );
            })
          ) : (
            <Typography fontSize={"14px"}>
              {t(
                "We couldn't found any cases . Please check for typos or search again using different keywords ."
              )}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export { SeeCase };
