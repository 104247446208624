import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Button,
  Loader,
  Modal,
  TextField,
  theme,
  Typography
} from "shared-components";

const useStyle = createUseStyles({
  formWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },

  addButton: {
    backgroundColor: theme.darkBlueButton,
    width: "145px",
    height: "28px",
    padding: "2px",
    borderRadius: "2px"
  },

  teamcodetext: {
    width: "325px",
    color: theme.darkBackground,
    flex: "inherit",
    marginTop: "11px"
  },

  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: theme.primary,
    marginTop: "-5px",
    marginBottom: "19px",
    letterSpacing: "0px"
  },

  codetextfield: {
    width: "530px",
    fontSize: "16px",
    marginBottom: "7px",
    color: theme.colorTextGrey,
    letterSpacing: "0px"
  },

  teamcodemodal: {
    "& .ant-modal-body": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "auto",
      textAlign: "-webkit-match-parent",
      minHeight: 480
    },
    width: "850px !important",
    minHeight: "480px"
  },
  addButtonWrapper: {
    borderRadius: "2px",
    marginBottom: 51,
    display: "flex",
    justifyContent: "center"
  },

  csvButton: {
    maxWidth: 319,
    backgroundColor: "#C7C7C7",
    color: "#000000",
    letterSpacing: "0.72px",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:focus": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:active": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    }
  },
  errorCsvButton: {
    maxWidth: 155,
    marginTop: 21,
    marginBottom: 26,
    backgroundColor: "#C7C7C7",
    color: "#000000",
    letterSpacing: "0.72px",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:focus": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:active": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    }
  },

  buttonIcon: {
    fontSize: 16
  },
  csvTeamCode: {
    border: "none",
    maxWidth: 202,
    color: theme.darkBlueButton,
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: 0,
    marginTop: 26,
    "&:hover ": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:focus": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:active": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    }
  },
  inProgress: {
    display: "flex",
    height: 500,
    flexDirection: "column",
    justifyContent: "center"
  },
  doneInfo: {
    marginTop: 17
  },
  message: {
    fontFamily: "Noto Sans JP",
    fontSize: 16,
    lineHeight: "19px",
    color: theme.gray7,
    letterSpacing: 0
  },

  errorMessage: {
    fontFamily: "Noto Sans JP",
    fontSize: 14,
    lineHeight: "22px",
    color: theme.gray7,
    letterSpacing: 0
  },
  teamCodeDone: {
    paddingLeft: 153,
    height: 428
  },
  input: {
    display: "none"
  },

  teamCodeError: {
    paddingLeft: 153,
    minHeight: 756,
    maxHeight: "fit-content",
    display: "flex",
    flexDirection: "column"
  }
});

interface TeamCodeProps {
  TeamCodeToggleVisibility?: any;
  teamcodevisibility?: any;
  handleCSVUpload?: any;
  handleTemplateDownload?: any;
  handleChange?: any;
  handleSubmit?: any;
  teamCodeError?: any;
  templateDownloadLoading?: boolean;
  processing?: boolean;
  done?: boolean;
  teamCodeForm?: FormInstance<any>;
}

export const AddTeamCode = ({
  TeamCodeToggleVisibility,
  teamcodevisibility,
  handleCSVUpload,
  handleTemplateDownload,
  handleChange,
  handleSubmit,
  teamCodeError,
  templateDownloadLoading,
  processing,
  done,
  teamCodeForm
}: TeamCodeProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const inputRef = useRef<HTMLInputElement>(null);

  const errorDisplay = (errors: any) => {
    let rowData;
    let columnData;
    let errorData;
    if (errors.includes("Row number:")) {
      const array = errors?.split(",") || [];
      const data = array.map((item: any) => {
        return item.substring(item.indexOf(",") + 0);
      });
      rowData = data[0].includes("Row number:") && data[0].split(": ")[1];
      columnData = data[1].includes("Column:") && data[1].split(": ")[1];
      errorData = data[2].includes("Error:") && data[2].split(": ")[1];
    }
    let errorMessage =
      t("Row number: ") +
      rowData +
      ", " +
      t("Column: ") +
      columnData +
      ", " +
      t("Error: ") +
      t(errorData);
    return errorMessage;
  };

  return (
    <Modal
      onCancel={TeamCodeToggleVisibility}
      visible={teamcodevisibility}
      mask={false}
      className={classes.teamcodemodal}
      centered
    >
      {processing ? (
        <div className={`${classes.inProgress} ${classes.formWrapper}`}>
          <Typography className={classes.title}>
            {t("Batch registration in progress")}
          </Typography>
          <Loader size="large" />
        </div>
      ) : done ? (
        <div className={classes.teamCodeDone}>
          <Typography className={classes.title}>
            {t("Contact information batch registration")}
          </Typography>
          <div className={classes.doneInfo}>
            <Typography className={classes.message}>
              {t("Bulk registration of contacts has been completed.")}
            </Typography>
          </div>
        </div>
      ) : teamCodeError !== "" ? (
        <div className={classes.teamCodeError}>
          <Typography className={classes.title}>
            {t("Contact information batch registration")}
          </Typography>
          <div className={classes.doneInfo}>
            <Typography className={classes.message}>
              {t("The following error has occurred.")}
            </Typography>
            <Typography className={classes.message}>
              {t("Please correct the incorrect part and upload the CSV again.")}
            </Typography>
          </div>
          <input
            ref={inputRef}
            onChange={file => {
              handleCSVUpload(file);
            }}
            type="file"
            accept=".csv"
            multiple={false}
            className={classes.input}
          />
          <Button
            icon={<UploadOutlined className={classes.buttonIcon} />}
            className={classes.errorCsvButton}
            onClick={() => {
              return inputRef.current && inputRef.current.click();
            }}
          >
            {t("Upload CSV")}
          </Button>
          <Typography className={classes.errorMessage}>
            *{" "}
            {teamCodeError.includes("Row number:")
              ? errorDisplay(teamCodeError)
              : t(teamCodeError)}
          </Typography>
        </div>
      ) : (
        <div className={classes.formWrapper}>
          <Form form={teamCodeForm} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="invitationCode"
              rules={[
                {
                  required: true,
                  message: t("Required")
                }
              ]}
            >
              <TextField
                label={t("Team Code")}
                name="invitationCode"
                onChange={handleChange}
                labelClassName={classes.codetextfield}
              />
            </Form.Item>
            <div className={classes.addButtonWrapper}>
              <Button
                className={classes.addButton}
                type="primary"
                htmlType="submit"
              >
                {t("Add")}
              </Button>
            </div>
          </Form>
          <input
            ref={inputRef}
            onChange={file => {
              handleCSVUpload(file);
            }}
            type="file"
            accept=".csv"
            multiple={false}
            className={classes.input}
          />
          <Button
            icon={<UploadOutlined className={classes.buttonIcon} />}
            className={classes.csvButton}
            htmlType={"button"}
            onClick={() => {
              return inputRef.current && inputRef.current.click();
            }}
          >
            {t("Bulk registration of contacts (CSV upload)")}
          </Button>
          <div onClick={handleTemplateDownload}>
            <Button
              className={classes.csvTeamCode}
              icon={<DownloadOutlined className={classes.buttonIcon} />}
              loading={templateDownloadLoading}
            >
              {t("Download template CSV")}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
