import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";

import { i18n } from "../../config";

const dialogStyle = {};

const DeleteConfirm = (
  handleDelete: any,
  title?: string,
  noContent?: boolean,
  contentText?: string,
  okText?: string,
  cancelText?: string
) => {
  const { confirm } = Modal;

  confirm({
    style: dialogStyle,
    title: title ? i18n.t(title) : i18n.t("Do you want to remove the item?"),
    icon: <ExclamationCircleOutlined />,
    content: noContent
      ? undefined
      : contentText
      ? i18n.t(contentText)
      : i18n.t(
          "This item will be removed immediately. You can’t undo this action."
        ),
    okText: okText ? i18n.t(okText) : i18n.t("Yes"),
    okType: "primary",
    centered: true,
    cancelText: cancelText ? i18n.t(cancelText) : i18n.t("No"),
    onOk() {
      handleDelete();
    }
  });
};

export { DeleteConfirm };
