import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { TextArea } from "shared-components";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";

const useStyles = createUseStyles((theme: any) => {
  return {
    summaryWrapper: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9,
      wordBreak: "break-word"
    },
    textClassName: {
      marginTop: 7,
      paddingBottom: 35
    }
  };
});

interface props {
  handleChange?: any;
  isEdit?: boolean;
  summary: string;
  disabled?: boolean;
}

export const Summary = ({ isEdit, handleChange, summary, disabled }: props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.summaryWrapper}>
      <FormTitle>{t("Summary")}</FormTitle>

      {isEdit ? (
        <div>
          <TextArea
            onChange={handleChange}
            name="summary"
            rows={5}
            value={summary}
            label={t("Comments")}
            maxLength={3000}
            disabled={disabled}
          />
        </div>
      ) : (
        <div>
          <ShowData
            label={t("Comments")}
            value={summary || "-"}
            className={classes.textClassName}
          />
        </div>
      )}
    </div>
  );
};
