import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { ReportListObject } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    mainContainer: {
      display: "flex",
      justifyContent: "flex-start",
      background: theme.background,
      width: "100%",
      overflowX: "scroll",
      scrollbarColor: "#F5F5F5 #fff",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        height: "3px"
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightBg
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor,
        borderRadius: "10px"
      }
    },
    tabs: {
      "&:hover": {
        cursor: "pointer"
      },
      "&.selected": {
        backgroundColor: theme.textWhite,
        borderRadius: "3px 3px 0px 0px"
      },
      display: "block",
      fontSize: "14px",
      maxWidth: "250px",
      minHeight: "33px",
      alignItems: "center",
      padding: "8px 20px",
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      textAlign: "center",
      justifyContent: "center",
      lineHeight: "17px",
      letterSpacing: "0.84px",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    addIcon: {
      minWidth: 80,
      minHeight: 33,
      fontSize: 20,
      marginLeft: 5,
      textAlign: "center",
      "&:hover": {
        cursor: "pointer"
      }
    },
    tooltip: {
      "& .ant-tooltip-content": {
        width: 464
      }
    }
  };
});

interface Props {
  onClick?: any;
  selected: "agreement" | string;
  reportList: ReportListObject[];
  addReport: any;
  caseStatus: string;
  handleStatus: (status: string) => void;
  publicReport?: boolean;
  showAgreementForm: boolean;
  clients: TeamClientData[];
  viewAsTeamOwner?: boolean;
}

export const ReportNavigation: React.FC<Props> = ({
  onClick,
  selected,
  reportList,
  addReport,
  caseStatus,
  handleStatus,
  publicReport,
  showAgreementForm,
  clients,
  viewAsTeamOwner
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getOranizationName = (clientTeamID: string) => {
    return clients.find((client: TeamClientData) => {
      return client.clientTeamId === clientTeamID;
    })?.organizationName;
  };

  return (
    <div className={classes.mainContainer}>
      {reportList.length > 0 &&
        reportList.map((item, key) => {
          return (
            <div
              className={`${classes.tabs}  ${
                selected === item.id ? "selected" : ""
              }`}
              onClick={() => {
                return onClick(item.id);
              }}
              key={key}
            >
              <span>
                {(item.clientTeamId
                  ? "{ " + getOranizationName(item.clientTeamId) + " }"
                  : "") + item.name && item.name}
              </span>
            </div>
          );
        })}
      {!publicReport && showAgreementForm && (
        <div
          className={`${classes.tabs} ${
            selected === "agreement" ? "selected" : ""
          }`}
          onClick={() => {
            return onClick("agreement");
          }}
        >
          <span>{t("Patient Agreement")}</span>
        </div>
      )}
    </div>
  );
};
