import { UploadOutlined } from "@ant-design/icons";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, theme, Typography } from "shared-components";

const useStyle = createUseStyles({
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: theme.primary,
    marginTop: "-5px",
    marginBottom: "19px",
    letterSpacing: "0px"
  },
  doneInfo: {
    marginTop: 17
  },
  message: {
    fontFamily: "Noto Sans JP",
    fontSize: 16,
    lineHeight: "19px",
    color: theme.gray7,
    letterSpacing: 0
  },
  buttonIcon: {
    fontSize: 16
  },
  errorCsvButton: {
    maxWidth: 155,
    marginTop: 21,
    marginBottom: 26,
    backgroundColor: "#C7C7C7",
    color: "#000000",
    letterSpacing: "0.72px",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:focus": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    },
    "&:active": {
      backgroundColor: "#C7C7C7",
      color: "#000000"
    }
  },
  errorDivision: {
    height: 530,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5
    },
    "&::-webkit-scrollbar-track": {
      background: theme.lightGrey
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.scrollColor,
      borderRadius: 10
    }
  },
  errorMessage: {
    fontFamily: "Noto Sans JP",
    fontSize: 14,
    lineHeight: "22px",
    color: theme.gray7,
    letterSpacing: 0
  }
});

interface Props {
  className?: string;
  handleCSVUpload?: any;
  errors: any[];
}

export const CsvErrorsComponent = ({
  className,
  handleCSVUpload,
  errors
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={`${className}`}>
      <Typography className={classes.title}>
        {t("Contact information batch registration")}
      </Typography>
      <div className={classes.doneInfo}>
        <Typography className={classes.message}>
          {t("The following error has occurred.")}
        </Typography>
        <Typography className={classes.message}>
          {t("Please correct the incorrect part and upload the CSV again.")}
        </Typography>
      </div>
      <input
        ref={inputRef}
        onChange={file => {
          handleCSVUpload(file);
        }}
        type="file"
        accept=".csv"
        multiple={false}
        style={{ display: "none" }}
      />
      <Button
        icon={<UploadOutlined className={classes.buttonIcon} />}
        className={classes.errorCsvButton}
        onClick={() => {
          return inputRef.current && inputRef.current.click();
        }}
      >
        {t("Upload CSV")}
      </Button>
      <div className={classes.errorDivision}>
        {errors.map((error: any) => {
          let rowData;
          let columnData;
          let errorData;

          if (error.includes("Row number:")) {
            const array = error?.split(",") || [];
            const data = array.map((item: any) => {
              return item.substring(item.indexOf(",") + 0);
            });

            rowData = data[0].includes("Row number:") && data[0].split(": ")[1];
            columnData = data[1].includes("Column:") && data[1].split(": ")[1];
            errorData = data[2].includes("Error:") && data[2].split(": ")[1];
          }

          return (
            <Typography className={classes.errorMessage}>
              *{" "}
              {!error.includes("Row number:")
                ? t(error)
                : `${t("Row number: ")} ${rowData}, ${t("Column: ")} ${t(
                    columnData
                  )}, ${t("Error: ")} ${t(errorData)}`}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};
