import { Form, Radio } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  AuthContext,
  Button,
  exceptionHandler,
  Modal,
  TextField,
  Typography
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      flexDirection: "column",
      "& .ant-modal-body": {
        minWidth: 733,
        minHeight: 706,
        display: "flex",
        flexDirection: "column",
        textAlign: "-webkit-match-parent",
        margin: 0
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      }
    },
    modalComponent: {
      marginLeft: 121
    },
    header: {
      display: "flex",
      color: theme.primary,
      fontSize: 24,
      letterSpacing: 0,
      marginBottom: 28
    },
    modal: {
      maxWidth: "100%",
      "& .ant-form-item-explain, .ant-form-item-extra": {
        marginTop: -5,
        marginBottom: 10
      }
    },
    button: {
      height: 28,
      width: 145,
      fontSize: 14,
      lineHeight: "17px",
      letterSpacing: "0px"
    },
    inputField: {
      width: "fit-content",
      marginBottom: 5
    },
    label: {
      color: theme.primary,
      fontSize: 12,
      letterSpacing: 0
    },
    nameLabel: {
      marginBottom: 11
    },
    typeLabel: {
      marginBottom: 16
    },
    addressLabel: {
      marginBottom: 2
    },
    phoneLabel: {
      marginBottom: 7
    },
    faxLabel: {
      marginBottom: 3
    },
    faxField: {
      marginTop: -5
    },
    phoneField: {
      marginTop: -10
    },
    addressField: {
      marginTop: 5
    },
    radioButton: {
      "& .ant-radio-checked > .ant-radio-inner": {
        borderColor: "#E6E6E6"
      },
      "& .ant-radio-checked ": {
        // backgroundColor: "#000000",
      }
    }
  };
});

interface Props {
  visible: boolean;
  onCancel: any;
  setVisibility?: any;
  form: OrganizationInterface;
  handleChange: any;
  onComplete: any;
  setForm: any;
}
const EditOrganizationModal = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false as boolean);
  const { organizationId } = useContext(AuthContext);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await API.get(`/organizations/${organizationId}`);
    if (response && response.data) {
      form.setFieldsValue({
        name: response.data.name,
        phone: response.data.phone,
        type: response.data.type,
        address: response.data.address,
        fax: response.data.fax
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.put(`/organizations/${organizationId}`, props.form);
      Alert("success", "success", t("Organization Information is updated."), t);
      props.onComplete();
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLoading(false);
    props.setVisibility(false);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Modal
      className={classes.modalContainer}
      visible={props.visible}
      onCancel={handleCancel}
      width="850px"
      mask={false}
    >
      <div className={classes.modalComponent}>
        <Typography className={classes.header}>
          {t("Edit Organization")}
        </Typography>
        <Form className={classes.modal} onFinish={handleSubmit} form={form}>
          <Form.Item
            id="name"
            name="name"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: t("Required")
              }
            ]}
          >
            <div>
              <Typography className={`${classes.label} ${classes.nameLabel}`}>
                {t("Name1")}
              </Typography>
              <TextField
                className={classes.inputField}
                autoComplete="off"
                name="name"
                onChange={props.handleChange}
                maxLength={100}
                editValue={props.form.name}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: t("Required")
              }
            ]}
          >
            <div>
              <Typography className={`${classes.label} ${classes.typeLabel}`}>
                {t("Type")}
              </Typography>
              <Radio.Group
                className={classes.radioButton}
                value={props.form.type}
                name="type"
                onChange={props.handleChange}
              >
                <Radio value={"hospital"}>{t("Hospital")}</Radio>
                <Radio value={"pharmacy"}>{t("Pharmacy")}</Radio>
              </Radio.Group>
            </div>
          </Form.Item>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: t("Required")
              }
            ]}
          >
            <div className={classes.addressField}>
              <Typography
                className={`${classes.label} ${classes.addressLabel}`}
              >
                {t("Address")}
              </Typography>
              <TextField
                className={classes.inputField}
                autoComplete="off"
                name="address"
                onChange={props.handleChange}
                maxLength={200}
                editValue={props.form.address}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: t("Required")
              }
            ]}
          >
            <div className={classes.phoneField}>
              <Typography className={`${classes.label} ${classes.phoneLabel}`}>
                {t("Phone")}
              </Typography>
              <TextField
                className={classes.inputField}
                autoComplete="off"
                name="phone"
                onChange={props.handleChange}
                maxLength={20}
                editValue={props.form.phone}
                type="tel"
              />
            </div>
          </Form.Item>
          <Form.Item name="fax">
            <div className={classes.faxField}>
              <Typography className={`${classes.label} ${classes.faxLabel}`}>
                {t("Fax")}
              </Typography>
              <TextField
                className={classes.inputField}
                autoComplete="off"
                name="fax"
                onChange={props.handleChange}
                maxLength={20}
                editValue={props.form.fax}
                type="tel"
              />
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              className={classes.button}
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {t("Update")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export { EditOrganizationModal };
