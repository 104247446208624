import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Radio } from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  Button,
  exceptionHandler,
  Loader,
  Modal,
  TextField,
  Typography
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    title: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "28px",
      color: theme.primary,
      marginTop: "-5px",
      marginBottom: "19px",
      letterSpacing: "0px"
    },
    buttonWrapper: {
      textAlign: "center",
      marginBottom: 16,
      marginTop: 33
    },
    modal: {
      maxWidth: "60%"
    },
    modalButton: {
      textAlign: "right"
    },
    text: {
      maxWidth: "100%"
    },
    left: {
      float: "left"
    },
    inline: {
      display: "flex",
      alignItems: "center",
      marginBottom: 4
    },
    member: {
      padding: 6
    },
    button: {
      backgroundColor: theme.darkBlueButton,
      width: 160,
      height: 28,
      padding: 0,
      borderRadius: 3
    },
    modalBox: {
      width: 850,
      marginTop: 5,
      minHeight: 480,
      "& .ant-modal-body": {
        maxHeight: 756,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "-webkit-match-parent"
      }
    },
    teamCode: {
      color: "#5A5A5A",
      "& span": {
        marginLeft: 20,
        fontSize: 22,
        color: "#2E2E2E"
      }
    },
    firstDesc: {
      marginTop: 18
    },
    radiobutton: {
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "19px",
      marginRight: 30,
      color: theme.textLightBlack,
      "& .ant-radio-checked": {
        "& :after": {
          background: "#000000"
        }
      }
    },
    labeltext: {
      color: theme.textBlue,
      fontSize: "12px",
      letterSpacing: 0,
      lineHeight: "14px"
    },
    nameLabel: {
      marginBottom: 7
    },
    emailLabel: {
      marginBottom: 3
    },
    radioGroup: {
      display: "flex"
    },
    formItem: {
      marginBottom: 19
    },
    input: {
      display: "none"
    },
    formWrapper: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    inProgress: {
      display: "flex",
      height: 500,
      flexDirection: "column",
      justifyContent: "center"
    },
    csvButton: {
      maxWidth: 319,
      backgroundColor: "#C7C7C7",
      color: "#000000",
      letterSpacing: "0.72px",
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:focus": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:active": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      }
    },
    csvTeamCode: {
      border: "none",
      maxWidth: 202,
      color: theme.darkBlueButton,
      fontSize: 12,
      lineHeight: "14px",
      letterSpacing: 0,
      marginTop: 24,
      "&:hover ": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      },
      "&:focus": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      },
      "&:active": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      }
    },
    buttonIcon: {
      fontSize: 16
    },
    doneModal: {
      height: 428
    },
    doneInfo: {
      marginTop: 17
    },
    message: {
      fontFamily: "Noto Sans JP",
      fontSize: 16,
      lineHeight: "19px",
      color: theme.gray7,
      letterSpacing: 0
    },
    errorModal: {
      height: 480,
      display: "flex",
      flexDirection: "column"
    },
    errorCsvButton: {
      maxWidth: 155,
      marginTop: 21,
      marginBottom: 26,
      backgroundColor: "#C7C7C7",
      color: "#000000",
      letterSpacing: "0.72px",
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:focus": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:active": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      }
    },
    errorDivision: {
      height: 500,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor,
        borderRadius: 10
      }
    },
    errorMessage: {
      fontFamily: "Noto Sans JP",
      fontSize: 14,
      lineHeight: "22px",
      color: theme.gray7,
      letterSpacing: 0
    }
  };
});

interface Props {
  visible: boolean;
  onCancel: any;
  processing: boolean;
  done: boolean;
  errors: any;
  form: any;
  loading: boolean;
  handleCSVUpload: any;
  handleSubmit: any;
  handleChange: any;
  state: any;
}

const InviteMemberModal = ({
  visible,
  onCancel,
  processing,
  done,
  errors,
  loading,
  form,
  handleCSVUpload,
  handleSubmit,
  handleChange,
  state
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [templateDownloadLoading, setTemplateDownloadLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTemplateDownload = async () => {
    const templateName = "members-sample.csv";
    setTemplateDownloadLoading(true);

    try {
      const res = await API.get(
        `/download-csv-templates?templateName=${templateName}`
      );

      if (res && res.data && res.data.templateUrl) {
        window.open(res.data.templateUrl);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setTemplateDownloadLoading(false);
  };

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      mask={false}
      className={classes.modalBox}
      centered
    >
      {processing ? (
        <div className={`${classes.inProgress} ${classes.formWrapper}`}>
          <Typography className={classes.title}>
            {t("Batch registration in progress")}
          </Typography>
          <Loader size="large" />
        </div>
      ) : done ? (
        <div className={classes.doneModal}>
          <Typography className={classes.title}>
            {t("Contact information batch registration")}
          </Typography>
          <div className={classes.doneInfo}>
            <Typography className={classes.message}>
              {t("Bulk registration of contacts has been completed.")}
            </Typography>
          </div>
        </div>
      ) : errors && errors.length ? (
        <div className={classes.errorModal}>
          <Typography className={classes.title}>
            {t("Contact information batch registration")}
          </Typography>
          <div className={classes.doneInfo}>
            <Typography className={classes.message}>
              {t("The following error has occurred.")}
            </Typography>
            <Typography className={classes.message}>
              {t("Please correct the incorrect part and upload the CSV again.")}
            </Typography>
          </div>
          <input
            ref={inputRef}
            onChange={file => {
              handleCSVUpload(file);
            }}
            type="file"
            accept=".csv"
            multiple={false}
            className={classes.input}
          />
          <Button
            icon={<UploadOutlined className={classes.buttonIcon} />}
            className={classes.errorCsvButton}
            onClick={() => {
              return inputRef.current && inputRef.current.click();
            }}
          >
            {t("Upload CSV")}
          </Button>
          <div className={classes.errorDivision}>
            {errors.map((error: any) => {
              let rowData;
              let columnData;
              let errorData;

              if (error.includes("Row number:")) {
                const array = error?.split(",") || [];
                const data = array.map((item: any) => {
                  return item.substring(item.indexOf(",") + 0);
                });

                rowData =
                  data[0].includes("Row number:") && data[0].split(": ")[1];
                columnData =
                  data[1].includes("Column:") && data[1].split(": ")[1];
                errorData =
                  data[2].includes("Error:") && data[2].split(": ")[1];
              }

              return (
                <Typography className={classes.errorMessage}>
                  *{" "}
                  {!error.includes("Row number:")
                    ? t(error)
                    : `${t("Row number: ")} ${rowData}, ${t("Column: ")} ${t(
                        columnData
                      )}, ${t("Error: ")} ${t(errorData)}`}
                </Typography>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <Form form={form} className={classes.modal} onFinish={handleSubmit}>
            <Form.Item
              id="name"
              name="name"
              className={classes.formItem}
              rules={[
                {
                  required: true,
                  message: t("Required")
                },
                {
                  max: 60,
                  message: t("Maximum input limit is 60 characters")
                }
              ]}
            >
              <div>
                <Typography
                  className={`${classes.labeltext} ${classes.nameLabel}`}
                >
                  {t("Name")}
                </Typography>
                <TextField
                  autoComplete="off"
                  name="name"
                  onChange={handleChange}
                  maxLength={60}
                />
              </div>
            </Form.Item>
            <Form.Item
              id="email"
              name="email"
              className={classes.formItem}
              rules={[
                {
                  type: "email",
                  message: t("Not a valid email")
                },
                {
                  required: true,
                  message: t("Required")
                },
                {
                  max: 60,
                  message: t("Maximum input limit is 60 characters")
                }
              ]}
            >
              <div>
                <Typography
                  className={`${classes.labeltext} ${classes.emailLabel}`}
                >
                  {t("Email")}
                </Typography>
                <TextField
                  autoComplete="off"
                  name="email"
                  onChange={handleChange}
                />
              </div>
            </Form.Item>
            <Form.Item
              colon={false}
              id="language"
              required={true}
              name="language"
            >
              <div>
                <Typography
                  className={`${classes.labeltext} ${classes.emailLabel}`}
                >
                  {t("Language of invitation mail")}
                </Typography>
                <Radio.Group
                  name="language"
                  value={state.language}
                  className={classes.radioGroup}
                  onChange={handleChange}
                >
                  <Radio value={"japanese"} className={classes.radiobutton}>
                    {t("Japanese")}
                  </Radio>
                  <Radio value={"english"} className={classes.radiobutton}>
                    {t("English")}
                  </Radio>
                </Radio.Group>
              </div>
            </Form.Item>
            <Form.Item className={classes.modalButton}>
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.button}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("Submit")}
                </Button>
              </div>
            </Form.Item>
          </Form>

          <input
            ref={inputRef}
            onChange={file => {
              handleCSVUpload(file);
            }}
            type="file"
            accept=".csv"
            multiple={false}
            className={classes.input}
          />
          <Button
            icon={<UploadOutlined className={classes.buttonIcon} />}
            className={classes.csvButton}
            htmlType={"button"}
            onClick={() => {
              return inputRef.current && inputRef.current.click();
            }}
          >
            {t("Bulk registration of contacts (CSV upload)")}
          </Button>
          <Button
            className={classes.csvTeamCode}
            icon={<DownloadOutlined className={classes.buttonIcon} />}
            loading={templateDownloadLoading}
            onClick={handleTemplateDownload}
          >
            {t("Download template CSV")}
          </Button>
        </>
      )}
    </Modal>
  );
};

export { InviteMemberModal };
