import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined
} from "@ant-design/icons";
import { Col, Row, Switch, Tabs } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  Button,
  CrossIcon,
  DatePickerComponent,
  exceptionHandler,
  FilePlusIcon,
  FlagIcon,
  language,
  Search,
  TeamBookmarkIcon,
  Typography,
  UserBookmarkIcon
} from "shared-components";

import { CreateCaseModal } from "../../components/molecules/CreateCaseModal";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      padding: "15px 9px 40px 29px",
      paddingBottom: 0,
      background: theme.textWhite,
      borderBottom: `1px solid ${theme.colorGrey}`,
      "& .ant-tabs-nav": {
        "&:before": {
          borderBottom: "none"
        }
      }
    },
    createButton: {
      float: "right",
      width: 145,
      height: 32,
      borderRadius: 16,
      paddingLeft: 30,
      paddingRight: 30,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      display: "flex",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      boxShadow: " 0px 3px 6px #00000029"
    },
    rowWrapper: {
      display: "flex",
      marginBottom: 26,
      marginTop: 7
    },
    rowWrapper1: {
      display: "flex",
      marginBottom: 26
    },
    text: {
      color: "#5A5A5A",
      fontSize: 16,
      fontFamily: "Noto Sans JP",
      marginRight: 9,
      letterSpacing: 0
    },
    displayCases: {
      display: "flex",
      marginRight: 32
    },
    filterRow: {
      marginTop: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Noto Sans JP"
    },
    pagination: {
      display: "flex",
      alignItems: "center",
      marginBottom: 22
    },
    paginationText: {
      fontSize: 14,
      fontFamily: "Noto Sans JP"
    },
    leftArrow: {
      marginLeft: 21,
      marginRight: 25
    },
    closeButton: {
      position: "absolute",
      padding: 5,
      marginTop: 21,
      borderRadius: "50%",
      right: "-28px",
      top: "0px",
      zIndex: 1,
      border: "none",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "none",
        cursor: "pointer"
      }
    },
    paginationButton: {
      marginLeft: 5,
      borderRadius: "50%",
      border: "none",
      "&:hover": {
        backgroundColor: "none"
      }
    },
    icons: {
      fontSize: 16
    },
    flagIconGreen: {
      fontSize: 30,
      color: theme.textGreen
    },
    flagIconYellow: {
      fontSize: 30,
      color: theme.textYellow
    },
    bookmarkIcons: {
      fontSize: 30
      // marginLeft: -30
    },
    bookmarkCase: {
      display: "flex",
      marginRight: 32,
      cursor: "pointer"
    },
    title: {
      fontSize: 10,
      fontFamily: "Noto Sans JP"
    },
    bookmarkText: {
      paddingLeft: 22
    },
    bookmarkButton: {
      position: "relative",
      top: -3,
      marginLeft: -10,
      borderRadius: "50%",
      border: "none",
      "&:hover": {
        backgroundColor: "none"
      }
    },
    switch: {
      "&.ant-switch-checked": {
        backgroundColor: theme.blue6
      }
    },
    exportButton: {
      width: "fit-Content",
      height: "30px",
      paddingLeft: "9px",
      paddingRight: "16px",
      backgroundColor: theme.textWhite,
      color: theme.gray8,
      marginRight: "20px",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: theme.textWhite,
        color: theme.gray8
      },
      "&:focus": {
        backgroundColor: theme.textWhite,
        color: theme.gray8
      },
      "&:active": {
        backgroundColor: theme.textWhite,
        color: theme.gray8
      }
    },
    btnRow: {
      display: "flex",
      justifyContent: "flex-end"
    }
  };
});

interface Props {
  openCaseModal: boolean;
  clearFilters: () => void;
  filters: CaseFilters;
  setFilters: React.Dispatch<React.SetStateAction<CaseFilters>>;
  count: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  duplicateWithCaseID: string;
  setDuplicateWithCaseID: React.Dispatch<React.SetStateAction<string>>;
}

const FilterComponent = ({
  openCaseModal,
  clearFilters,
  filters,
  setFilters,
  count,
  setLoading,
  duplicateWithCaseID,
  setDuplicateWithCaseID
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [clear, setClear] = useState(true as boolean);
  const [visibility, setVisibility] = useState(false);
  const [myBookmark, setMyBookmark] = useState(false);
  const [teamBookmark, setTeamBookmark] = useState(false);
  const { profile, defaultTeamName, organizationName } = useContext(
    AuthContext
  );
  const [userSettings, setUserSettings] = useState({} as any);
  const [exportBtnLoading, setExportBtnLoading] = useState(false);

  const settings = () => {
    const userConfig: any = {};
    for (const setting of profile.userSettings || []) {
      userConfig[setting.name] = setting.value;
    }
    setUserSettings(userConfig);
  };

  useEffect(() => {
    settings();
  }, []);

  useEffect(() => {
    const tabName = localStorage.getItem("selectedTabName");
    if (tabName) {
      handleFilterChange("status", tabName);
    }
  }, []);

  const handleFilterChange = (
    name: string,
    value: string | number | boolean
  ) => {
    setLoading(true);

    if (name === "status") {
      localStorage.setItem("selectedTabName", value as string);
    }
    const newFilters = { ...filters, [name]: value };
    if (name !== "page") {
      newFilters.page = 1;
    }
    setFilters(newFilters);
  };

  const toggleVisibilty = () => {
    setVisibility(!visibility);
    setDuplicateWithCaseID("");
  };

  const handleMyBookmark = () => {
    setMyBookmark(!myBookmark);
  };

  const handleTeamBookmark = () => {
    setTeamBookmark(!teamBookmark);
  };

  useEffect(() => {
    if (openCaseModal || duplicateWithCaseID) {
      setVisibility(true);
    }
  }, [openCaseModal, duplicateWithCaseID]);

  const clearAllFilters = () => {
    clearFilters();
    setUserSettings({
      ...userSettings,
      case_keyword: "",
      case_from_date: "",
      case_to_date: ""
    });
    setClear(true);
  };

  const disabledToDate = (current: Moment) => {
    return current && current < moment(filters.from);
  };

  const disabledFromDate = (current: Moment) => {
    return current && current > moment(filters.to);
  };

  const handleCasesDownload = async () => {
    try {
      setExportBtnLoading(true);
      const response: Blob = await API.get("/export-cases", {
        params: {
          language: language,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...filters
        },
        responseType: "blob"
      });

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      const dateString = moment(new Date()).format("YYYYMMDD_HHmmss");
      link.setAttribute(
        "download",
        `${dateString}_${organizationName}_${defaultTeamName}_trepo_caselist.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      exceptionHandler(err, t);
    }
    setExportBtnLoading(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.btnRow}>
        <Button
          icon={<DownloadOutlined />}
          className={classes.exportButton}
          onClick={handleCasesDownload}
          loading={exportBtnLoading}
        >
          {t("Export Case")}
        </Button>
        <Button
          icon={<FilePlusIcon />}
          className={classes.createButton}
          onClick={toggleVisibilty}
        >
          {t("Create New")}
        </Button>
      </div>
      <Row gutter={15} className={classes.rowWrapper}>
        <Col lg={8} xs={24}>
          <Typography className={classes.title}>{t("Search")}</Typography>
          <Search
            allowClear={true}
            clear={clear}
            setClear={setClear}
            name="search"
            placeholder=""
            onChange={handleFilterChange}
            initialValue={userSettings.case_keyword}
          />
        </Col>
        <Col lg={5} xs={24}>
          <Typography className={classes.title}>{t("From Date")}</Typography>
          <DatePickerComponent
            clear={clear}
            setClear={setClear}
            disabledDate={disabledFromDate}
            name="from"
            placeholder=""
            onChange={(name, value) => {
              handleFilterChange(name, value);
              setUserSettings({ ...userSettings, case_from_date: value });
            }}
            initialValue={
              userSettings.case_from_date && moment(userSettings.case_from_date)
            }
            onClear={() => {
              return setUserSettings({
                ...userSettings,
                case_from_date: ""
              });
            }}
          />
        </Col>
        <Col lg={5} xs={24}>
          <Typography className={classes.title}>{t("To Date")}</Typography>
          <DatePickerComponent
            clear={clear}
            setClear={setClear}
            disabledDate={disabledToDate}
            name="to"
            placeholder=""
            onChange={(name, value) => {
              handleFilterChange(name, value);
              setUserSettings({ ...userSettings, case_to_date: value });
            }}
            initialValue={
              userSettings.case_to_date && moment(userSettings.case_to_date)
            }
            onClear={() => {
              return setUserSettings({
                ...userSettings,
                case_to_date: ""
              });
            }}
          />
          {(filters.from || filters.search || filters.to) && (
            <Button
              onClick={clearAllFilters}
              className={classes.closeButton}
              shape="circle"
              icon={<CrossIcon />}
            />
          )}
        </Col>
      </Row>
      <div className={classes.rowWrapper1}>
        <div className={classes.displayCases}>
          <Typography className={classes.text}>
            {t("Show cases involving me")}
          </Typography>
          <Switch
            className={classes.switch}
            checked={filters.own}
            size="default"
            onChange={checked => {
              return handleFilterChange("own", checked);
            }}
          />
        </div>
        <div
          onClick={() => {
            handleMyBookmark();
            handleFilterChange("mybookmark", !filters.mybookmark);
          }}
          className={classes.bookmarkCase}
        >
          <>
            <Typography className={classes.text}>{t("My Bookmark")}</Typography>
            <Button
              className={classes.bookmarkButton}
              shape="circle"
              icon={
                filters.mybookmark ? (
                  <UserBookmarkIcon className={classes.bookmarkIcons} />
                ) : (
                  <FlagIcon className={classes.flagIconYellow} />
                )
              }
            />
          </>
        </div>
        <div
          onClick={() => {
            handleTeamBookmark();
            handleFilterChange("teambookmark", !filters.teambookmark);
          }}
          className={classes.bookmarkCase}
        >
          <>
            <Typography className={classes.text}>
              {t("Team Bookmark")}
            </Typography>
            <Button
              className={classes.bookmarkButton}
              shape="circle"
              icon={
                filters.teambookmark ? (
                  <TeamBookmarkIcon className={classes.bookmarkIcons} />
                ) : (
                  <FlagIcon className={classes.flagIconGreen} />
                )
              }
            />
          </>
        </div>
        <div className={classes.displayCases}>
          <Typography className={classes.text}>
            {t("Show disagreed cases")}
          </Typography>
          <Switch
            className={classes.switch}
            checked={filters.disagreed}
            size="default"
            onChange={checked => {
              return handleFilterChange("disagreed", checked);
            }}
          />
        </div>
      </div>
      <div className={classes.filterRow}>
        <div>
          <Tabs
            onChange={(key: string) => {
              return handleFilterChange("status", key);
            }}
            activeKey={filters.status}
            defaultActiveKey="all"
          >
            <Tabs.TabPane tab={t("Creating")} key="creating" />
            <Tabs.TabPane
              tab={t("Pharmacist Checking")}
              key="pharmacistChecking"
            />
            <Tabs.TabPane tab={t("Sending")} key="sending" />
            <Tabs.TabPane tab={t("Accepting")} key="accepting" />
            <Tabs.TabPane tab={t("Confirming")} key="confirming" />
            <Tabs.TabPane tab={t("Answered")} key="answered" />
            <Tabs.TabPane tab={t("Canceled")} key="canceled" />
            <Tabs.TabPane tab={t("All")} key="all" />
          </Tabs>
        </div>
        <div className={classes.pagination}>
          <Typography className={classes.paginationText}>{`${
            count > 0 ? (filters.page - 1) * filters.pageSize + 1 : 0
          } - ${
            filters.pageSize * filters.page >= count
              ? count
              : filters.pageSize * filters.page
          } of ${count}`}</Typography>
          <Button
            className={classes.paginationButton}
            shape="circle"
            icon={<LeftOutlined className={classes.icons} />}
            onClick={() => {
              return handleFilterChange("page", filters.page - 1);
            }}
            disabled={filters.page === 1}
          />
          <Button
            className={classes.paginationButton}
            shape="circle"
            icon={<RightOutlined className={classes.icons} />}
            onClick={() => {
              return handleFilterChange("page", filters.page + 1);
            }}
            disabled={filters.pageSize * filters.page >= count}
          />
        </div>
      </div>
      <CreateCaseModal
        visible={visibility}
        onCancel={toggleVisibilty}
        duplicateWithCaseID={duplicateWithCaseID}
      />
    </div>
  );
};

export { FilterComponent };
