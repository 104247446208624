import { Checkbox, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  Button,
  exceptionHandler,
  Loader,
  TextArea,
  TextField,
  Typography
} from "shared-components";
import validator from "validator";

let useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      padding: "0px 4px 50px 25px"
    },
    title: {
      fontSize: 14,
      color: theme.gray7,
      lineHeight: "20px",
      letterSpacing: 0
    },
    pdfSent: {
      marginTop: 25
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: 25
    },
    formItem: {
      marginBottom: 28
    },
    formLabel: {
      marginBottom: 0,
      letterSpacing: 0,
      color: theme.gray7
    },
    formText: {
      maxWidth: 325,
      minWidth: 325,
      marginTop: 0
    },
    messageContainer: {
      marginTop: 0
    },
    checkbox: {
      maxWidth: 325,
      minWidth: 325
    },
    button: {
      marginRight: 50,
      marginLeft: 70,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 180,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    loader: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

interface Props {
  onClose?: (event?: any, clickedOn?: string | undefined) => void;
  pathologyNumber?: string;
  reportID?: string | number;
}

interface SendPDFFormData {
  email: string;
  subject: string;
  message: string;
  personalInformation: boolean;
}

interface Errors {
  email?: string;
  message?: string;
  subject?: string;
}

const SendPDF: React.FC<Props> = ({ reportID }) => {
  const classes = useStyles();
  const [pdfSent, setPdfSent] = useState(false as boolean);
  const { t } = useTranslation();
  const [form, setForm] = useState({} as SendPDFFormData);
  const [pdfUploading, setPdfUploading] = useState(false as boolean);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as Errors);
  useEffect(() => {
    const timer = setInterval(() => {
      const existingReportNumbers = JSON.parse(
        localStorage.getItem("pdfUploading") || JSON.stringify([])
      );
      if (existingReportNumbers.length > 0) {
        setPdfUploading(true);
      } else {
        setPdfUploading(false);
      }
      return () => {
        clearInterval(timer);
      };
    }, 100);
  }, [pdfUploading, pdfSent]);

  window.onunload = () => {
    return localStorage.removeItem("pdfUploading");
  };

  const setReportIdToStorage = () => {
    const existingReportIds = JSON.parse(
      localStorage.getItem("pdfUploading") || JSON.stringify([])
    );
    existingReportIds.push(reportID);
    localStorage.setItem("pdfUploading", JSON.stringify(existingReportIds));
  };

  const removeReportIdFromStorage = () => {
    const existingReportIds = JSON.parse(
      localStorage.getItem("pdfUploading") || JSON.stringify([])
    );
    if (existingReportIds.length <= 1) {
      localStorage.removeItem("pdfUploading");
    } else {
      existingReportIds.pop();
      localStorage.setItem("pdfUploading", JSON.stringify(existingReportIds));
    }
    setPdfUploading(false);
  };

  const validateForm = () => {
    let newErrors: Errors = {};

    if (!form.email) {
      newErrors = { ...newErrors, email: t("Required") };
    }
    if (form.email && !validator.isEmail(form.email)) {
      newErrors = {
        ...newErrors,
        email: t("Please provide a valid email")
      };
    }
    if (!form.subject) {
      newErrors = { ...newErrors, subject: t("Required") };
    }
    if (form.subject && form.subject.length > 101) {
      newErrors = {
        ...newErrors,
        subject: t("Maximum limit has exceeded")
      };
    }
    if (!form.message) {
      newErrors = { ...newErrors, message: t("Required") };
    }
    if (form.message && form.message.length > 3000) {
      newErrors = {
        ...newErrors,
        message: t("Maximum limit has exceeded")
      };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleFormChange = async (event: any) => {
    const { name, value } = event.target;
    if (name) {
      const newForm = {
        ...form,
        [name]:
          name === "personalInformation"
            ? (form.personalInformation = !form.personalInformation)
            : value
      };
      setForm(newForm);
      setErrors({});
    }
  };
  const handleSendPdf = async () => {
    setReportIdToStorage();
    try {
      setLoading(true);
      const response: any = await API.post(`/sendPdf/${reportID}`, {
        email: form.email,
        subject: form.subject,
        message: form.message,
        personalInformation: form.personalInformation
      });
      if (response.data) {
        Alert("success", "success", t("PDF sent."), t);
        setPdfSent(true);
        setLoading(false);
      }
    } catch (error) {
      Alert("error", "error", t("PDF not sent."), t);
      exceptionHandler(error);
      setLoading(false);
    }
    removeReportIdFromStorage();
  };

  return (
    <div className={classes.container}>
      <span className={classes.title}>{t("Send PDF")}</span>
      {pdfSent ? (
        <div className={`${classes.pdfSent} ${classes.title}`}>
          {t("PDF sent.")}
        </div>
      ) : pdfUploading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <Form>
          <div className={classes.formContainer}>
            <div className={classes.formItem}>
              <Typography fontSize="14px" className={classes.formLabel}>
                {t("Email")}
              </Typography>
              <TextField
                name="email"
                className={classes.formText}
                editValue={form.email}
                onChange={handleFormChange}
                error={errors.email}
              />
            </div>
            <div className={classes.formItem}>
              <Typography fontSize="14px" className={classes.formLabel}>
                {t("Subject")}
              </Typography>
              <TextField
                name="subject"
                className={classes.formText}
                editValue={form.subject}
                onChange={handleFormChange}
                error={errors.subject}
              />
            </div>
            <div>
              <Typography fontSize="14px" className={classes.formLabel}>
                {t("Message")}
              </Typography>
              <TextArea
                name="message"
                rows={5}
                inputClass={classes.formText}
                containerClass={classes.messageContainer}
                value={form.message}
                onChange={handleFormChange}
                error={errors.message}
              />
            </div>
            <div className={classes.formItem}>
              <Checkbox
                name="personalInformation"
                className={classes.checkbox}
                checked={form.personalInformation}
                onChange={handleFormChange}
              >
                {t("Include the patient's personal information in the PDF")}
              </Checkbox>
            </div>
            <Button
              htmlType={"submit"}
              type="primary"
              size="small"
              loading={loading}
              onClick={() => {
                const valid = validateForm();
                if (!valid) {
                  return;
                }
                return handleSendPdf();
              }}
              className={classes.button}
            >
              {t("Submit")}{" "}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export { SendPDF };
