import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { theme, Typography } from "shared-components";

const useStyles = createUseStyles(() => {
  return {
    text: {
      color: theme.textDarkGrey,
      fontSize: 14,
      wordBreak: "break-all",
      lineHeight: "16px",
      letterSpacing: 0,
      whiteSpace: "break-spaces"
    },
    textAreaClass: {
      resize: "none"
    }
  };
});

interface Props {
  isEdit?: boolean;
  name: string;
  handleChange?: any;
  recordId: number;
  disabled?: boolean;
  value: any;
}

export const DrugDataComponent = ({
  isEdit,
  name,
  recordId,
  handleChange,
  disabled,
  value
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {!isEdit ? (
        <Typography className={classes.text}>
          {value ? t(`${value}`) : "-"}
        </Typography>
      ) : (
        <TextArea
          name={name}
          value={value}
          onChange={e => {
            return handleChange(recordId, e);
          }}
          disabled={disabled}
          autoSize
          className={classes.textAreaClass}
        />
      )}
    </>
  );
};
