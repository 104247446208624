import { Form, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import {
  Alert,
  API,
  AuthContext,
  Avatar,
  BorderBoxWrapper,
  Button,
  exceptionHandler,
  resizePhoto,
  TextField,
  Typography
} from "shared-components";

import { ComponentWrapper } from "../../components";

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      marginBottom: 20,
      marginTop: 20,
      maxWidth: "256px",
      margin: "auto",
      "& label": {
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.gray9,
        fontWeight: 300,
        marginBottom: 2
      },

      "& .ant-form-item-explain": {
        fontWeight: 300
      },
      "& > :first-child": {
        padding: 0
      }
    },

    fullWidth: {
      maxWidth: "100%",
      fontSize: "16px"
    },
    uploadButton: {
      background: "white",
      padding: "1px 8px",
      fontWeight: "300",
      marginTop: "10px"
    },
    uploadInfo: {
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "20px",
      color: "black",
      textAlign: "center"
    },
    upload: {
      "& .ant-upload": {
        background: "transparent",
        border: "none",
        height: "auto",
        width: "100%",
        margin: 0,
        padding: 0
      },
      "& span": {
        padding: "0px !important"
      }
    },
    button: {
      fontWeight: 300,
      margin: "auto",
      display: "block",
      marginBottom: 40
    },
    profilePicWrapper: {
      textAlign: "center"
    },
    avatar: {
      display: "flex",
      justifyContent: "center"
    },
    "@media screen and (max-width: 600px)": {
      formItem: {
        width: "auto",
        padding: {
          left: 30,
          right: 30
        }
      }
    }
  };
});

export const EditProfile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, setProfile } = useContext(AuthContext);
  const history = useHistory();

  const [state, setState] = useState({ name: "", ProfileImage: "" });
  const [photo, setPhoto] = useState("" as any);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({} as any);
  const [imageFile, setImageFile] = useState({} as any);

  useEffect(() => {
    getProfile();
  }, [user]);

  const getProfile = async () => {
    if (!user) {
      return;
    }

    const id = user.uid;

    try {
      const response = await API.get(`users/${id}`);
      setUserData(response.data);

      setState(s => {
        return {
          ...s,
          name: response.data.name,
          ProfileImage: response.data.ProfileImage
        };
      });
      setPhoto(response.data.ProfileImage);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(s => {
      return { ...s, [name]: value };
    });
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleUpload = async (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const newImageObj = info.file.originFileObj;
      const resizedPhoto = (await resizePhoto(newImageObj, 200, 200)) as any;
      const isLessThan2MB = resizedPhoto.obj.size / 1024 / 1024 < 2;
      if (!isLessThan2MB) {
        Alert("error", "error", t("Image Size Exceeded"), t);
        return;
      }
      const file = new File([resizedPhoto.obj], newImageObj.name);
      setImageFile(file);
      setPhoto(resizedPhoto.url);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", state.name);
    if (imageFile.constructor === File) {
      formData.append("profilePic", imageFile);
    }

    try {
      const response = await API.put(`users/${userData.id}`, formData);
      setProfile(response.data);
      setLoading(false);
      Alert("success", "success", t("Your profile has been updated."), t);
      history.push("/account-settings");
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <ComponentWrapper>
      <BorderBoxWrapper heading={t("Edit Profile")}>
        <div className={classes.avatar}>
          <Avatar
            name={userData.name || t("Not Set")}
            photo={photo}
            color={userData.color}
          />
        </div>

        <div className={classes.upload}>
          <Upload
            customRequest={dummyRequest}
            showUploadList={false}
            listType="picture-card"
            accept=".png, .jpg, .jpeg"
            onChange={handleUpload}
          >
            <Button className={classes.uploadButton}>
              {t("Upload Image")}
            </Button>
          </Upload>
        </div>

        <Typography className={classes.uploadInfo}>
          {t("Maximum upload file size: 10MB")}
        </Typography>

        <Form onFinish={handleSubmit} layout="vertical">
          <Form.Item
            id="name"
            name="name"
            label={t("Name")}
            required={false}
            className={classes.formItem}
            rules={[
              {
                required: !state.name,
                message: t("Required")
              },
              {
                max: 60,
                message: t("Max Length Reached")
              }
            ]}
          >
            <TextField
              name="name"
              className={classes.fullWidth}
              editValue={state.name}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className={classes.button}
              loading={loading}
              size={"middle"}
              htmlType="submit"
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </BorderBoxWrapper>
    </ComponentWrapper>
  );
};
