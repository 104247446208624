import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { Redirect, Route } from "react-router-dom";
import { AuthContext, Forbidden, Loader, NotFound } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    loader: {
      marginLeft: "48%",
      marginTop: "20%"
    }
  };
});

export const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const classes = useStyles();

  const { loading, authenticated, user, externalUser } = useContext(
    AuthContext
  );

  const [error403, setError403] = useState(false as boolean);
  const [error404, setError404] = useState(false as boolean);

  const errorBoundary = (error?: any) => {
    if (error && error.response && error.response.status === 403) {
      setError403(true);
      return;
    }
    if (error && error.response && error.response.status === 404) {
      setError404(true);
    }
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader size="large" />
      </div>
    );
  }

  if (error403) {
    return <Forbidden externalUser={externalUser} />;
  }

  if (error404) {
    return <NotFound externalUser={externalUser} />;
  }

  if (
    externalUser &&
    ![
      "/",
      "/cases",
      "/cases/:id",
      "/external-case",
      "/edit-profile",
      "/change-password"
    ].includes(rest.computedMatch.path)
  ) {
    return <Forbidden externalUser={externalUser} />;
  }
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (authenticated && user && !user.emailVerified) {
          return (
            <Redirect
              to={{
                pathname: "/email-confirmation",
                state: { prevPath: rest.path }
              }}
            />
          );
        }
        if (!authenticated) {
          return (
            <Redirect
              to={{ pathname: "/sign-in", state: { prevPath: rest.path } }}
            />
          );
        }

        if (user && user.email === null) {
          return (
            <Redirect
              to={{ pathname: "/sign-up", state: { prevPath: rest.path } }}
            />
          );
        }

        if (
          routeProps.location.pathname.includes("/cases") &&
          routeProps.location.search.includes("?pathology_number=")
        ) {
          const pathologyNumber = rest.location.search.split(
            "?pathology_number="
          );

          if (
            routeProps.location.search.includes("&pathpresenter_name=") &&
            routeProps.location.search.includes("&pathpresenter_token=")
          ) {
            const pathpresenterName = rest.location.search.split(
              "&pathpresenter_name="
            );
            const pathpresenterToken = rest.location.search.split(
              "&pathpresenter_token="
            );
            if (pathologyNumber[1]) {
              if (pathpresenterName[1] && pathpresenterToken[1]) {
                return (
                  <Redirect
                    to={{
                      pathname: "external-case",
                      state: {
                        pathologyNumber: pathologyNumber[1].split("&")[0],
                        pathpresenterName: pathpresenterName[1].split("&")[0],
                        pathpresenterToken: pathpresenterToken[1].split("&")[0]
                      }
                    }}
                  />
                );
              }
            }
          }

          if (pathologyNumber[1]) {
            return (
              <Redirect
                to={{
                  pathname: "external-case",
                  state: { pathologyNumber: pathologyNumber[1].split("&")[0] }
                }}
              />
            );
          }
        }

        return <RouteComponent {...routeProps} errorBoundary={errorBoundary} />;
      }}
    />
  );
};
