import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { DeleteIcon, theme, Typography } from "shared-components";

import { DrugDataComponent } from "./DrugDataComponent";

const useStyles = createUseStyles(() => {
  return {
    drugContainer: {
      background: "white",
      padding: "20px 15px 21px 20px"
    },
    cardContainer: {
      display: "flex"
    },
    row: {
      marginBottom: 15
    },
    lastRow: {
      marginBottom: 0
    },
    deleteIcon: {
      color: "#ED5E68",
      fontSize: 15,
      height: 16
    },
    labelName: {
      color: theme.gray6,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: "14px",
      marginBottom: 4
    },
    firstColumn: {
      paddingRight: "15px"
    },
    secondColumn: ({ isEdit }) => {
      return {
        paddingRight: isEdit ? "15px" : "0px",
        paddingLeft: isEdit ? "0px" : "15px"
      };
    },
    lineStyle: {
      display: "block",
      backgroundColor: "#CCCCCC",
      height: "1px",
      marginBottom: 21,
      marginTop: -21
    }
  };
});

interface Props {
  isEdit?: boolean;
  handleChange?: any;
  handleDelete?: any;
  recordId: number;
  disabled?: boolean;
  data: any;
  cardkey?: number;
  source?: boolean;
}

export const DrugCaseComponent = ({
  isEdit,
  handleChange,
  handleDelete,
  recordId,
  disabled,
  data,
  cardkey,
  source
}: Props) => {
  const classes = useStyles({ isEdit });
  const { t } = useTranslation();
  return (
    <div className={classes.drugContainer}>
      {cardkey !== 0 && <span className={classes.lineStyle} />}
      <div className={classes.cardContainer}>
        <div style={{ width: "100%" }}>
          <Row className={classes.row}>
            <Col span={12} className={classes.firstColumn}>
              <Typography className={classes.labelName}>
                {t("Drug Name")}
              </Typography>
              <DrugDataComponent
                isEdit={isEdit}
                name="name"
                handleChange={handleChange}
                recordId={recordId}
                disabled={disabled}
                value={data.name}
              />
            </Col>

            <Col span={12} className={classes.secondColumn}>
              <Typography className={classes.labelName}>
                {t("Dosage")}
              </Typography>
              <DrugDataComponent
                isEdit={isEdit}
                name="dosage"
                handleChange={handleChange}
                recordId={recordId}
                disabled={disabled}
                value={data.dosage}
              />
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col span={12} className={classes.firstColumn}>
              <Typography className={classes.labelName}>
                {t("Usage")}
              </Typography>
              <DrugDataComponent
                isEdit={isEdit}
                name="usage"
                handleChange={handleChange}
                recordId={recordId}
                disabled={disabled}
                value={data.usage}
              />
            </Col>
            <Col span={12} className={classes.secondColumn}>
              <Typography className={classes.labelName}>
                {source ? t("Prescription Days") : t("Changes")}
              </Typography>
              <DrugDataComponent
                isEdit={isEdit}
                name={source ? "prescriptionDays" : "changes"}
                handleChange={handleChange}
                recordId={recordId}
                disabled={disabled}
                value={source ? data.prescriptionDays : data.changes}
              />
            </Col>
          </Row>
          {source && (
            <Row className={classes.row}>
              <Col span={12} className={classes.firstColumn}>
                <Typography className={classes.labelName}>
                  {t("Start at")}
                </Typography>
                <DrugDataComponent
                  isEdit={isEdit}
                  name="startAt"
                  handleChange={handleChange}
                  recordId={recordId}
                  disabled={disabled}
                  value={data.startAt}
                />
              </Col>
              <Col span={12} className={classes.secondColumn}>
                <Typography className={classes.labelName}>
                  {t("Medical Agency")}
                </Typography>
                <DrugDataComponent
                  isEdit={isEdit}
                  name="medicalAgency"
                  handleChange={handleChange}
                  recordId={recordId}
                  disabled={disabled}
                  value={data.medicalAgency}
                />
              </Col>
            </Row>
          )}
          <Row className={classes.lastRow}>
            <Col span={12} className={classes.firstColumn}>
              <Typography className={classes.labelName}>
                {source ? t("Department") : t("Details")}
              </Typography>
              <DrugDataComponent
                isEdit={isEdit}
                name={source ? "department" : "details"}
                handleChange={handleChange}
                recordId={recordId}
                disabled={disabled}
                value={source ? data.department : data.details}
              />
            </Col>
            {source && (
              <Col span={12} className={classes.secondColumn}>
                <Typography className={classes.labelName}>
                  {t("Prescriber")}
                </Typography>
                <DrugDataComponent
                  isEdit={isEdit}
                  name="prescriber"
                  handleChange={handleChange}
                  recordId={recordId}
                  disabled={disabled}
                  value={data.prescriber}
                />
              </Col>
            )}
          </Row>
        </div>
        {!disabled && isEdit && (
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={() => {
              return handleDelete(recordId);
            }}
          />
        )}
      </div>
    </div>
  );
};
