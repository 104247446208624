import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  capitalizeFirstLetter,
  SelectComponent,
  TextArea
} from "shared-components";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { PrescriptionType } from "../types/case";
import { PrescriptionChangeTableContainer } from "./PrescriptionChangeTable";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9
    },
    sectionContent: {
      fontSize: 16,
      wordBreak: "break-word"
    },
    tableContainer: {
      marginTop: 26
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      maxHeight: 34
    },
    selectWrapper: {
      maxWidth: 194,
      position: "relative",
      top: 15
    },
    selectStyle: {
      maxWidth: 194,
      "& .ant-select-selector": {
        height: "28px !important"
      }
    },
    lineStyle: {
      display: "block",
      backgroundColor: theme.scrollColor,
      height: "1.2px",
      marginBottom: 13
    },
    buttonStyle: {
      width: 79,
      height: 28,
      fontSize: 14,
      lineHeight: "17px",
      letteSpacing: "0.84px",
      marginTop: 29,
      padding: "unset"
    },
    buttonWrapper: ({ isEdit }) => {
      return {
        display: "flex",
        justifyContent: "center",
        height: !isEdit && 28
      };
    },
    showComment: {
      padding: {
        top: 21,
        bottom: 35
      }
    },
    noDrugInfo: {
      fontSize: 12,
      color: theme.gray6,
      textAlign: "center"
    }
  };
});

interface Props {
  isEdit: boolean;
  reportType: string;
  comment: any;
  handleChange: any;
  prescriptionTypes: PrescriptionType[];
  reportId: string;
  isPublicReport: boolean;
  disabled?: boolean;
}

export const ReportDetail = ({
  isEdit,
  reportType,
  comment,
  handleChange,
  reportId,
  isPublicReport,
  disabled
}: Props) => {
  const classes = useStyles({ isEdit });
  const { t } = useTranslation();

  const [type, setType] = useState("");

  useEffect(() => {
    setType(reportType);
  }, [reportType]);

  const handleSelectChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    return handleChange({ target: { name, value } });
  };

  return (
    <div className={classes.container}>
      <FormTitle className={classes.titleWrapper}>
        <span>{t(`${capitalizeFirstLetter(type)} Details`)}</span>
        {isEdit && (
          <SelectComponent
            labelFontSize={12}
            onChange={handleSelectChange}
            name="tracing_type"
            value={capitalizeFirstLetter(type)}
            options={[
              { label: "Report", value: "Report" },
              { label: "Request", value: "Request" },
              { label: "Suggestion", value: "Suggestion" },
              { label: "Confirmation", value: "Confirmation" }
            ]}
            wrapperClassName={classes.selectWrapper}
            className={classes.selectStyle}
            disabled={disabled}
          />
        )}
      </FormTitle>

      {!isEdit ? (
        <ShowData
          label="Notes"
          value={comment || "-"}
          className={classes.showComment}
        />
      ) : (
        <TextArea
          label={t("Notes")}
          name="tracing_comment"
          value={comment}
          onChange={handleChange}
          rows={5}
          maxLength={3000}
          disabled={disabled}
        />
      )}

      <span className={classes.lineStyle} />

      <PrescriptionChangeTableContainer
        isEditable={isEdit && !disabled}
        reportId={reportId}
        isPublicReport={isPublicReport}
      />
    </div>
  );
};
