import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API } from "shared-components";

import { CaseDoubleCheck } from "../../../components/organisms/DoubleCheck";
import { RightSidebar } from "../../../components/organisms/RightSidebar";
import {
  CaseData,
  Member,
  PrescriptionType,
  ReportListObject
} from "../types/case";
import { PatientAgreement } from "./PatientAgreement";
import { Report } from "./Report";
import { ReportNavigation } from "./ReportNavigation";

const useStyles = createUseStyles((theme: any) => {
  return {
    reportWrapper: (publicReport: boolean) => {
      return {
        fontFamily: theme.brandFont,
        color: theme.gray6,
        margin: publicReport ? "24px 16.6% 66px 16.6%" : "24px 20% 66px 10px",
        positive: "relative"
      };
    },
    contents: {
      background: theme.lightBg
    },
    bottomContainer: {
      position: "fixed",
      display: "flex",
      alignItems: "center",
      paddingLeft: 145,
      width: "-webkit-fill-available",
      bottom: 0,
      left: 0,
      height: 70,
      backgroundColor: theme.background,
      zIndex: 1002
    },
    moz: {
      width: "-moz-available"
    },
    buttonWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
    },
    emptybutton: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginRight: 20,
      minWidth: 160,
      backgroundColor: theme.textWhite,
      color: theme.textPrimary,
      "&:hover ": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      },
      "&:focus": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      },
      "&:active": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      }
    },
    blueButton: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginRight: 30,
      minWidth: 160,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    savingTag: {
      background: theme.backgroundPink,
      fontSize: 12,
      lineHeight: "14px",
      padding: "3px 9px",
      position: "fixed",
      top: "43px",
      right: "364px",
      zIndex: "1000"
    }
  };
});

interface Props {
  caseState: CaseData;
  setCaseState: any;
  lastCaseState: any;
  setLastCaseState: any;
  caseStatus: string;
  allMembers: Member[];
  handleStatus: (status: string) => void;
  reportList: ReportListObject[];
  setReportList: React.Dispatch<React.SetStateAction<ReportListObject[]>>;
  medicineList: any[];
  caseDoubleCheck?: CaseDoubleCheck | null;
  setDoubleCheckData: (value: any) => void;
  publicReport?: boolean;
  clients: TeamClientData[];
  viewAsTeamOwner?: boolean;
  prescriptionTypes: PrescriptionType[];
}

export const ReportWrapper = ({
  caseState,
  setCaseState,
  lastCaseState,
  setLastCaseState,
  caseStatus,
  handleStatus,
  reportList,
  setReportList,
  allMembers,
  medicineList,
  caseDoubleCheck,
  setDoubleCheckData,
  publicReport,
  clients,
  prescriptionTypes,
  viewAsTeamOwner
}: Props) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false as boolean);
  const classes = useStyles(publicReport);

  const [selected, setSelected] = useState("");
  const [isReceiverReport, setIsReceiver] = useState(false as boolean);

  const [supervisorPharmacist, setSupervisorPharmacist] = useState(
    ("" as string) || undefined
  );
  const [commentNotification, setCommentNotification] = useState(
    false as boolean
  );

  useEffect(() => {
    if (caseState.diagnosisRequestId === null) {
      setSelected(reportList[0].id);
    } else if (caseState.reportId) {
      setIsReceiver(true);
      setSelected(caseState.reportId);
    }
  }, [reportList]);

  useEffect(() => {
    if (allMembers) {
      if (allMembers.length > 0) {
        const currentUser = allMembers?.find(member => {
          return (
            member.id !== "" && member.id === caseState.supervisory_pharmacist
          );
        });
        if (currentUser) {
          setSupervisorPharmacist(currentUser.name);
        }
      } else {
        setSupervisorPharmacist(undefined);
      }
    }
  }, [allMembers, caseState]);

  const addReport = async () => {
    const res = await API.post("reports", {
      caseId: caseState.id
    });
    reportList.push({
      id: res.data.id,
      created_at: res.data.createdAt,
      name: res.data.reportName
    });
    setReportList([...reportList]);
    setSelected(reportList[reportList.length - 1].id);
  };

  const handleReportNameChange = (id: string, value: string) => {
    reportList.forEach(item => {
      if (item.id === id) {
        item.name = value;
      }
    });
    setReportList([...reportList]);
  };

  useEffect(() => {
    if (publicReport && reportList.length) {
      setSelected(reportList[0].id);
    }
  }, [reportList, publicReport]);

  return (
    <div className={classes.reportWrapper}>
      <div className={classes.contents}>
        <ReportNavigation
          addReport={addReport}
          reportList={reportList}
          onClick={setSelected}
          selected={selected}
          caseStatus={caseStatus}
          handleStatus={handleStatus}
          publicReport={publicReport}
          showAgreementForm={!isReceiverReport}
          clients={clients}
          viewAsTeamOwner={viewAsTeamOwner}
        />
        {saving && <div className={classes.savingTag}>{t("Saving...")}</div>}
        {selected === "agreement" && !isReceiverReport && !publicReport && (
          <>
            <PatientAgreement
              caseState={caseState}
              lastCaseState={lastCaseState}
              setLastCaseState={setLastCaseState}
              allMembers={allMembers}
              setCaseState={setCaseState}
              supervisorPharmacist={supervisorPharmacist}
              setSaving={setSaving}
              viewAsTeamOwner={viewAsTeamOwner}
            />
            <RightSidebar
              typeAgreement
              caseID={caseState.id}
              myBookmark={caseState.myBookmark}
              teamBookmark={caseState.marked}
              commentNotification={commentNotification}
              setCommentNotification={setCommentNotification}
            />
          </>
        )}

        {reportList.length > 0 &&
          reportList.map((item, key) => {
            if (selected === item.id) {
              return (
                <div key={key}>
                  <Report
                    caseState={caseState}
                    setCaseState={setCaseState}
                    caseStatus={caseStatus}
                    lastCaseState={lastCaseState}
                    setLastCaseState={setLastCaseState}
                    summary={""}
                    name={item.name}
                    reportId={item.id}
                    handleReportNameChange={handleReportNameChange}
                    medicineList={medicineList}
                    allMembers={allMembers}
                    handleCaseStatus={handleStatus}
                    caseDoubleCheck={caseDoubleCheck}
                    setDoubleCheckData={setDoubleCheckData}
                    supervisorPharmacist={supervisorPharmacist}
                    setSupervisorPharmacist={setSupervisorPharmacist}
                    handleStatus={handleStatus}
                    setSaving={setSaving}
                    publicReport={publicReport}
                    clients={clients}
                    prescriptionTypes={prescriptionTypes}
                    isReceiverReport={isReceiverReport}
                    viewAsTeamOwner={viewAsTeamOwner}
                    commentNotification={commentNotification}
                    setCommentNotification={setCommentNotification}
                  />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};
