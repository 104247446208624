import {
  ApiOutlined,
  FileProtectOutlined,
  GlobalOutlined,
  SettingFilled
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Link, useLocation } from "react-router-dom";
import { AuthContext, CaseListIcon, TeamIcon } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    teamDetail: {
      height: 65,
      margin: 16,
      marginLeft: 25
    },
    team: {
      lineHeight: "16px",
      "& article": {
        fontSize: 12
      }
    },
    menuIcon: {
      lineHeight: "10px"
    },
    menu: {
      height: "100%",
      maxWidth: 200,
      background: theme.background,
      paddingTop: 10,
      borderRight: "none"
    },
    settingsMenu: {
      bottom: 0,
      position: "absolute !important",
      width: "100%",
      border: 0,
      textAlign: "left"
    },
    settingsMenuIcon: {
      lineHeight: "10px !important"
    },
    settingsWrapper: {
      maxWidth: 150
    },
    settingsItem: {
      textAlign: "left",
      width: "100%",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "24px",
      color: "#263238"
    },
    menuItem: {
      color: theme.textBlack,
      "&:hover": {
        color: theme.textBlack,
        backgroundColor: theme.gray4,
        "& a": {
          color: theme.textBlack
        }
      },
      "& .anticon": {
        fontSize: "20px"
      },
      "&::after": {
        right: "auto !important",
        left: "0px !important"
      },
      "&.ant-menu-item-selected": {
        color: theme.primary,
        "&:hover a": {
          color: theme.primary
        },
        background: "none !important"
      }
    },
    sidebar: {
      marginTop: 30,
      "&.ant-layout-sider-collapsed": {
        marginRight: 0,
        "& .anticon": {
          fontSize: "20px"
        }
      }
    },
    settingsIcon: {
      position: "absolute !important",
      bottom: "0",
      borderTop: `1px solid ${theme.colorGrey}`,
      marginBottom: "0px !important"
    }
  };
});

interface Props {
  collapsed?: boolean;
}

export const SidebarComponent: React.FC<Props> = () => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const { authenticatedOwner } = useContext(AuthContext);

  const keys = [
    "cases",
    "members",
    "clients",
    "requests",
    "settings",
    "organization"
  ];

  return (
    <div className={classes.sidebar}>
      <Menu
        className={classes.menu}
        mode="inline"
        selectedKeys={[
          keys.find(val => {
            if (location.pathname === "/") return "cases";
            return location.pathname.includes(val);
          }) || ""
        ]}
      >
        <Menu.Item
          className={classes.menuItem}
          icon={<CaseListIcon />}
          key="cases"
        >
          <Link to="/cases">{t("Case List")}</Link>
        </Menu.Item>

        <Menu.Item
          className={classes.menuItem}
          icon={<FileProtectOutlined />}
          key="requests"
        >
          <Link to="/requests">{t("Requests2")}</Link>
        </Menu.Item>

        {authenticatedOwner && (
          <Menu.Item
            className={classes.menuItem}
            icon={<TeamIcon />}
            key="members"
          >
            <Link to="/members">{t("Member")}</Link>
          </Menu.Item>
        )}

        <Menu.Item
          className={classes.menuItem}
          icon={<ApiOutlined />}
          key="clients"
        >
          <Link to="/clients">{t("Clients")}</Link>
        </Menu.Item>

        <Menu.Item
          className={classes.menuItem}
          icon={<GlobalOutlined />}
          key="organization"
        >
          <Link to="/organization">{t("Organization")}</Link>
        </Menu.Item>

        <Menu.Item
          className={`${classes.menuItem} ${classes.settingsIcon}`}
          icon={<SettingFilled />}
          key="settings"
        >
          <Link to="/settings">{t("Configuration")}</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
