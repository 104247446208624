import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { API } from "shared-components";

import { DrugData } from "../pages/cases/types/case";

export const useFetchMedicines = (
  reportId: string,
  isPublicReport: boolean
) => {
  const { id, reportCode } = useParams<{ [K in string]: string }>();

  return useQuery<DrugData[]>(
    ["case", id, reportCode, reportId, isPublicReport],
    async () => {
      const { data } = await API.get(`cases/${id || reportCode}`, {
        params: {
          public: isPublicReport,
          reportId: reportId
        }
      });
      return data.medicines;
    }
  );
};

export const useUpdateMedicines = (
  reportId: string,
  isPublicReport: boolean
) => {
  const queryClient = useQueryClient();
  const { id, reportCode } = useParams<{ [K in string]: string }>();
  const queryKeys = ["case", id, reportCode, reportId, isPublicReport];

  return useMutation<DrugData, unknown, DrugData>(
    async newMedicine => {
      const res = await API.put(`medicines/${newMedicine.id}`, newMedicine);
      return res.data;
    },
    {
      onMutate: async medicineData => {
        await queryClient.cancelQueries(queryKeys);
        const previosData = queryClient.getQueryData<DrugData[]>(queryKeys);
        const newData = previosData?.map(data => {
          return medicineData.id === data.id ? medicineData : data;
        });
        queryClient.setQueryData(queryKeys, newData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys);
      }
    }
  );
};

export const useCreateMedicines = (
  reportId: string,
  isPublicReport: boolean,
  location: "change" | "source" | "report"
) => {
  const queryClient = useQueryClient();
  const { id, reportCode } = useParams<{ [K in string]: string }>();

  return useMutation<DrugData, unknown, null>(
    () => {
      return API.post("medicines", { reportId: reportId, location: location });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "case",
          id,
          reportCode,
          reportId,
          isPublicReport
        ]);
      }
    }
  );
};

export const useDeleteMedicines = (
  reportId: string,
  isPublicReport: boolean
) => {
  const queryClient = useQueryClient();
  const { id, reportCode } = useParams<{ [K in string]: string }>();

  return useMutation<DrugData, unknown, number>(
    medicineId => {
      return API.delete(`medicines/${medicineId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "case",
          id,
          reportCode,
          reportId,
          isPublicReport
        ]);
      }
    }
  );
};
