import { Button, Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { TextArea } from "shared-components";

interface Props {
  onSubmit: any;
  handleChange: any;
  addComment: any;
  loading: boolean;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    commentContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 15
    },
    textHolder: {
      width: "280px",
      background: theme.textWhite,
      padding: "10px 10px 3px 10px"
    },
    textArea: {
      padding: 0,
      minHeight: "100px !important",
      border: "none",
      resize: "none",
      "&:active": {
        boxShadow: "none"
      },
      "&:focus": {
        boxShadow: "none"
      },
      fontSize: 16,
      "&:disabled": {
        color: theme.textBlack,
        cursor: "default"
      }
    },
    addButton: {
      width: "40%",
      margin: "auto"
    },
    commentForm: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: 20
    },
    editText: {
      marginLeft: 24
    },
    error: {
      color: theme.red6,
      fontSize: 12,
      marginLeft: 25,
      marginBottom: 5
    },
    comment: {
      width: "305px",
      padding: "10px 10px 3px 30px"
    }
  };
});

const CommentForm: React.FC<Props> = ({
  onSubmit,
  handleChange,
  addComment,
  loading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Form onFinish={onSubmit} className={classes.commentForm}>
      <div className={classes.commentContainer}>
        <div className={`${classes.textHolder} ${classes.editText}`}>
          <TextArea
            name={"comment"}
            onChange={handleChange}
            value={addComment.comment}
            autoSize
            inputClass={classes.textArea}
            autoFocus
          />
        </div>
      </div>
      {addComment.error && <p className={classes.error}>{addComment.error}</p>}
      <Button
        className={classes.addButton}
        type="primary"
        loading={loading}
        htmlType="submit"
      >
        {t("Add")}
      </Button>
    </Form>
  );
};

export { CommentForm };
