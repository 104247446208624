import { Card, Form, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  AuthContext,
  BorderBoxWrapper,
  Button,
  CancelInvitationIcon,
  Circle,
  DividerComponent,
  exceptionHandler,
  ResendInvitationIcon,
  Typography
} from "shared-components";

import { TeamCodeBox } from "../../components/atoms/TeamCodeBox";
import { ComponentWrapper } from "../../components/molecules";
import { InviteMemberModal } from "./InviteMemberModal";
import { OwnerTeam } from "./OwnerTeam";

interface Members {
  color?: string;
  name: string;
  email: string;
  status: string;
  teamId: string;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      height: "calc(100vh - 100px) !important"
    },
    card: {
      borderRadius: 3,
      "& > div": {
        padding: "7px 0px 0px 10px"
      },
      marginBottom: 10
    },
    divider: {
      border: `1px solid ${theme.gray4}`
    },
    borderBox: {
      "& > div": {
        padding: 0,
        marginLeft: (isAdminTeam: boolean) => {
          return isAdminTeam && 36;
        }
      }
    },
    wrapper: {
      padding: "21px 0px 18px 30px",
      overflow: "hidden",
      "&:hover": {
        "overflow-y": "auto"
      },
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1c1",
        borderRadius: "10px"
      }
    },
    inviteUserWrapperHeight: {
      height: 373
    },
    buttonWrapper: {
      textAlign: "center",
      marginBottom: 16,
      marginTop: 33
    },
    text: {
      maxWidth: "100%"
    },
    left: {
      float: "left"
    },
    inline: {
      display: "flex",
      alignItems: "center",
      marginBottom: 4
    },
    member: {
      padding: 6
    },
    button: {
      backgroundColor: theme.darkBlueButton,
      width: 160,
      height: 28,
      padding: 0,
      borderRadius: 3
    },
    invitationIconWrapper: {
      marginLeft: 10,
      cursor: "pointer"
    },
    invitationResendIcon: {
      "& > svg": {
        height: 25,
        width: 25
      }
    },
    invitationCancelIcon: {
      marginLeft: 5,
      "& > svg": {
        height: 25,
        width: 25
      }
    },
    "& > .ant-tag-red": {
      marginTop: 10
    }
  };
});

const Member = () => {
  const { defaultTeam, invitationCode, isAdminTeam } = useContext(AuthContext);
  const { t } = useTranslation();
  const [members, setMembers] = useState([] as Members[]);
  const [state, setState] = useState({
    email: "",
    name: "",
    language: "japanese"
  });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [response, setResponse] = useState({} as any);
  const [showTeamCode, setShowTeamCode] = useState(true as boolean);
  const classes = useStyles(isAdminTeam);

  const [processing, setProcessing] = useState(false);
  const [done, setDone] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fetchMembers, setFetchMembers] = useState(false);

  useEffect(() => {
    getMembers();
  }, [response, defaultTeam]);

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  const getMembers = async () => {
    try {
      const response = await API.get(`members`);
      if (!response.data) {
        response.data = [];
      }
      const sortedData = response.data.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
      });
      setMembers(sortedData);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { name, email, language } = state;
      const response = await API.post(`members/invite-users`, {
        name,
        email,
        language
      });
      setResponse(response);
      setLoading(false);
      form.resetFields();
      setVisibility(false);
      Alert("success", "success", t("Member has been invited."), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleCSVUpload = async (event: any) => {
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    try {
      const response: any = await API.post(
        "/members/bulk-registration",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      );

      if (response && response.message === "success") {
        setFetchMembers(true);
        setProcessing(false);
        setDone(true);
      }
    } catch (error) {
      setProcessing(false);
      setErrors(error.response.data.error);
      exceptionHandler(t, error);
    }
  };

  const toggleModal = () => {
    setVisibility(!visibility);
    setDone(false);
    setProcessing(false);
    setErrors([]);
  };

  const handleResendInvitation = async (email: string) => {
    try {
      const response: any = await API.post(`invite-users/resend-invitation`, {
        email: email,
        teamId: defaultTeam
      });
      if (response) {
        if (
          response.data &&
          response.data.code &&
          response.data.code === "info"
        ) {
          Alert(
            "",
            "info",
            t(
              "Less than 10 minutes have passed since the last resend. Please resend after a while."
            ),
            t
          );
        } else {
          Alert("success", "success", t("Invitation email is sent"), t);
        }
      }
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleCancelInvitation = async (email: string) => {
    try {
      await API.post(`invite-users/cancel-invitation`, {
        email: email,
        teamId: defaultTeam
      });
      getMembers();
      Alert("success", "success", t("Invitation is cancelled"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <ComponentWrapper sidebar className={classes.componentWrapper}>
      {isAdminTeam ? (
        <OwnerTeam
          response={response}
          setShowTeamCode={setShowTeamCode}
          toggleModal={toggleModal}
          fetchMember={fetchMembers}
        />
      ) : (
        <BorderBoxWrapper heading={t("Member")} className={classes.borderBox}>
          <div
            className={`${classes.wrapper} ${classes.inviteUserWrapperHeight}`}
          >
            {members.map((member: Members, index: number) => {
              return (
                <div className={classes.inline} key={index}>
                  <div className={classes.left}>
                    <Circle size="25" color={member.color} />
                  </div>
                  <Card key={index} bordered={false} className={classes.card}>
                    <Typography type="boldBody" className={classes.member}>
                      {member.name || member.email}
                    </Typography>
                  </Card>
                  {member.status === "Inviting" && (
                    <div className={classes.invitationIconWrapper}>
                      <Tooltip
                        placement="bottom"
                        title={t("Resend Invitation")}
                      >
                        <ResendInvitationIcon
                          className={classes.invitationResendIcon}
                          onClick={() => {
                            return handleResendInvitation(member.email);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        placement="bottom"
                        title={t("Cancel Invitation")}
                      >
                        <CancelInvitationIcon
                          className={classes.invitationCancelIcon}
                          onClick={() => {
                            return handleCancelInvitation(member.email);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div>
            <DividerComponent className={classes.divider} />
            <div className={classes.buttonWrapper}>
              <Button
                type="primary"
                size="large"
                onClick={toggleModal}
                className={classes.button}
              >
                {t("Invite")}
              </Button>
            </div>
          </div>
        </BorderBoxWrapper>
      )}
      {showTeamCode && (
        <TeamCodeBox
          invitationCode={invitationCode}
          className={classes.borderBox}
        />
      )}

      <InviteMemberModal
        visible={visibility}
        onCancel={toggleModal}
        done={done}
        processing={processing}
        errors={errors}
        loading={loading}
        form={form}
        state={state}
        handleCSVUpload={handleCSVUpload}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
      />
    </ComponentWrapper>
  );
};

export { Member };
