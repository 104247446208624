import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, Typography } from "shared-components";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      width: "100%",
      paddingLeft: 24
    },
    container: {
      width: "100%",
      padding: "40px 50px 20px 50px"
    },
    lowerContainer: {
      width: "100%",
      padding: "40px 50px 20px 50px",
      backgroundColor: theme.formBgColor
    },
    button: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginBottom: 10,
      width: "100%",
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    },
    icon: {
      position: "absolute",
      top: "25%",
      right: "-5px"
    },
    doubleTick: {
      marginRight: 5
    },
    member: {
      borderRadius: 3,
      marginBottom: 10,
      height: 30,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer"
      }
    },
    memberName: {
      margin: "0 15px",
      fontSize: 16,
      fontColor: theme.textPrimary
    },
    divider: {
      borderColor: theme.border
    },
    listContainer: {
      marginTop: 15
    },
    buttonLabel: {
      color: theme.formLabelColor,
      fontSize: 14,
      marginBottom: 10
    }
  };
});

interface Props {
  onClose?: (event?: any, clickedOn?: string | undefined) => void;
  filename?: string;
  downloadPDF: () => Promise<void>;
  loading?: boolean;
}

const Export: React.FC<Props> = ({ downloadPDF, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div>
        <Typography className={classes.buttonLabel}>{t("Export")}</Typography>
        <Button
          size="middle"
          className={classes.button}
          onClick={downloadPDF}
          loading={loading}
        >
          {t("Download PDF")}
        </Button>
      </div>
    </div>
  );
};

export { Export };
