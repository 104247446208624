import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { SelectComponent } from "shared-components";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { Member, ReportData } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      background: theme.formBackground,
      padding: "16px 40px"
    },
    titleStyle: {
      paddingBottom: 20
    },
    formInformationBlock: {
      display: "flex",
      maxHeight: 47
    },
    pharmacist: {
      width: "50%",
      paddingRight: 48
    },
    selectStyle: {
      "& .ant-select-selector": {
        height: "32px !important",
        borderColor: `${theme.colorGrey} !important`
      }
    }
  };
});

interface Props {
  blockTitleName: string;
  members: Member[];
  handleChange: any;
  isEdit?: boolean;
  selectFieldName: string;
  className?: string;
  supervisoryPharmacist?: string;
  supervisorId?: string;
  setSupervisorName?: any;
  pharmacist?: string;
  reportData?: ReportData;
  disabled?: boolean;
}

export const AgreementBearer = ({
  blockTitleName,
  members,
  handleChange,
  isEdit,
  selectFieldName,
  className,
  supervisoryPharmacist,
  supervisorId,
  setSupervisorName,
  pharmacist,
  reportData,
  disabled
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [pharmacistName, setPharmacistName] = useState("" as any);

  useEffect(() => {
    if (pharmacist && members) {
      const member = getPharmacistName(pharmacist);
      setPharmacistName(member?.name);
    }
  }, [pharmacist, members]);

  useEffect(() => {
    if (supervisorId && members) {
      const member = getPharmacistName(supervisorId);
      setSupervisorName(member?.name);
    }
  }, [supervisorId, members]);

  const handleSelectChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    const member = getPharmacistName(value);
    setPharmacistName(member?.name);

    return handleChange({ target: { name, value } });
  };

  const getPharmacistName = (value: string) => {
    const member = members.find(item => {
      return item.id === value;
    });
    return member;
  };

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <FormTitle className={classes.titleStyle}>
        {t(`${blockTitleName}`)}
      </FormTitle>
      <div className={classes.formInformationBlock}>
        {isEdit ? (
          <Form.Item required={false} className={classes.pharmacist}>
            <SelectComponent
              labelFontSize={12}
              onChange={handleSelectChange}
              name={selectFieldName}
              label={t("Pharmacist")}
              value={reportData?.pharmacist_user_id || pharmacist}
              options={members.map((member: Member) => {
                return {
                  label: member.name,
                  value: member.id
                };
              })}
              className={classes.selectStyle}
              disabled={disabled}
            />{" "}
          </Form.Item>
        ) : (
          <ShowData
            label="Pharmacist"
            value={pharmacistName || "-"}
            className={classes.pharmacist}
          />
        )}
        <ShowData
          label="Supervisory Pharmacist"
          value={supervisoryPharmacist || "-"}
        />
      </div>
    </div>
  );
};
