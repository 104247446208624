import { Layout } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Route, Switch } from "react-router-dom";
import { theme } from "shared-components";

import { HeaderWrapper } from "../components";
import {
  AccountSetting,
  ChangeEmail,
  ChangePassword,
  EditProfile
} from "../pages/auth";
import { Case } from "../pages/cases/Case";
import { PatientPage } from "../pages/cases/PatientPage";
import { HomeWithSiderBar } from "./HomeWIthSiderBar";
import { PrivateRoute } from "./PrivateRoute";
import { RestrictedRoute } from "./RestrictedRoute";

const useStyles = createUseStyles(() => {
  return {
    mainContainer: {
      width: "100%",
      minHeight: "100vh",
      backgroundColor: theme.background
    }
  };
});

export const Home = () => {
  const classes = useStyles();

  return (
    <Layout className={classes.mainContainer}>
      <HeaderWrapper />
      <Switch>
        <RestrictedRoute
          exact
          path="/public-report/:reportCode"
          component={(props: any) => {
            return <Case publicReport {...props} />;
          }}
        />
        <PrivateRoute exact path="/change-email" component={ChangeEmail} />
        <PrivateRoute exact path="/edit-profile" component={EditProfile} />
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePassword}
        />
        <PrivateRoute
          exact
          path="/account-settings"
          component={AccountSetting}
        />
        <Route exact path="/patient/agreement/:id" component={PatientPage} />
        <PrivateRoute path="/" component={HomeWithSiderBar} />
      </Switch>
    </Layout>
  );
};
