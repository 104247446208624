import { Tabs, Tooltip } from "antd";
import Form from "antd/lib/form/Form";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Alert,
  API,
  AuthContext,
  Button,
  DeleteConfirm,
  exceptionHandler,
  TextField,
  Typography
} from "shared-components";

import { CaseTags } from "../../../components/atoms/CaseTag";
import { CaseDoubleCheck } from "../../../components/organisms/DoubleCheck";
import { RightSidebar } from "../../../components/organisms/RightSidebar";
import {
  CaseData,
  DrugData,
  Member,
  PrescriptionType,
  ReportData,
  SenderData
} from "../types/case";
import { AgreementBearer } from "./AgreementBearer";
import { Answerer } from "./Answerer";
import { AnswerFeedback } from "./AnswerFeedback";
import { Header } from "./Header";
import { PatientInformation } from "./PatientInformation";
import { PrescriptionChange } from "./PrescriptionChange";
import { ReportDetail } from "./ReportDetail";
import { Sender } from "./Sender";
import { SourceInformation } from "./SourceInformation";
import { Summary } from "./Summary";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      padding: "0px 10px 17px",
      position: "relative"
    },
    contents: {
      marginTop: -52,
      background: theme.lightBg,
      "& > .ant-tabs-tab": {
        padding: "12px 12px !important"
      }
    },
    tabContainer: {},
    tabBar: {
      "& .ant-tabs-tab": {
        padding: "12px 12px"
      },
      "& .ant-tabs-nav::before": {
        borderBottom: "none"
      },
      "& .ant-tabs-nav": {
        marginBottom: 35
      }
    },
    titleBlock: {
      color: theme.gray6,
      marginBottom: 21
    },
    sectionWrapper: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9
    },
    sectionContent: {
      fontSize: 16,
      wordBreak: "break-word"
    },
    tableContainer: {
      marginTop: 26
    },
    tag: {
      marginLeft: "auto",
      position: "absolute",
      top: "-53px",
      right: 0
    },
    reportInput: {
      width: "40%",
      marginBottom: 9
    },
    tooltip: {
      "& .ant-tooltip-content": {
        width: 315
      }
    },
    submitContainer: {
      position: "absolute",
      display: "flex",
      bottom: "-50px",
      justifyContent: "center",
      width: "100%"
    },
    submitAnswer: {
      margin: "auto",
      width: "160px",
      background: theme.primary,
      color: theme.textWhite
    },
    bottomContainer: {
      position: "fixed",
      display: "flex",
      alignItems: "center",
      paddingLeft: 145,
      width: "-webkit-fill-available",
      bottom: 0,
      left: 0,
      height: 70,
      backgroundColor: theme.background
    },
    moz: {
      width: "-moz-available"
    },
    buttonWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
    },
    emptybutton: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginRight: 20,
      minWidth: 160,
      backgroundColor: theme.textWhite,
      color: theme.textPrimary,
      "&:hover ": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      },
      "&:focus": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      },
      "&:active": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      }
    },
    blueButton: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginRight: 30,
      minWidth: 160,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    member: {
      borderRadius: 3,
      height: 24,
      position: "absolute",
      minWidth: 200
    },
    memberName: {
      margin: "0 5px",
      fontSize: 16,
      fontColor: theme.textPrimary
    }
  };
});

interface Props {
  name: string;
  summary: string;
  caseStatus: string;
  tracing_type?: string;
  tracing_comment?: string;
  source_comment?: string;
  source_reference?: string;
  pharmacist_user_id?: string;
  caseState: CaseData;
  setCaseState: React.Dispatch<React.SetStateAction<CaseData>>;
  lastCaseState: CaseData;
  setLastCaseState: React.Dispatch<React.SetStateAction<CaseData>>;
  reportData?: ReportData;
  reportId: string;
  handleReportNameChange: (id: string, value: string) => void;
  allMembers: Member[];
  medicineList: any;
  handleCaseStatus: (status: string) => void;
  caseDoubleCheck?: CaseDoubleCheck | null;
  setDoubleCheckData: (value: any) => void;
  supervisorPharmacist: string | undefined;
  setSupervisorPharmacist: any;
  handleStatus: (status: string) => void;
  setSaving: any;
  publicReport?: boolean;
  clients: TeamClientData[];
  prescriptionTypes: PrescriptionType[];
  isReceiverReport: boolean;
  viewAsTeamOwner?: boolean;
  setCommentNotification?: React.Dispatch<React.SetStateAction<boolean>>;
  commentNotification?: boolean;
}

export const Report: React.FC<Props> = ({
  caseStatus,
  allMembers,
  handleReportNameChange,
  reportId,
  caseState,
  setCaseState,
  lastCaseState,
  setLastCaseState,
  medicineList,
  handleCaseStatus,
  caseDoubleCheck,
  setDoubleCheckData,
  supervisorPharmacist,
  setSupervisorPharmacist,
  handleStatus,
  setSaving,
  publicReport,
  clients,
  prescriptionTypes,
  isReceiverReport,
  viewAsTeamOwner,
  setCommentNotification,
  commentNotification
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [agreementStatus] = useState("Creating");

  const [drugData, setDrugData] = useState([] as DrugData[]);
  const [sourceMedicines, setSourceMedicines] = useState([] as DrugData[]);
  const [changeMedicines, setChangeMedicines] = useState([] as DrugData[]);
  const { id, reportCode }: any = useParams();
  const [reportData, setReportData] = useState({} as ReportData);
  const [lastReportData, setLastReportData] = useState({} as ReportData);
  const [senderData, setSenderData] = useState({} as SenderData);
  const [senderMembers, setSenderMembers] = useState([] as Member[]);
  const [lastMedicines, setLastMedicines] = useState({} as any);
  const [answerDisableText, setAnswerDisableText] = useState("" as string);

  const [confirmRequest, setConfirmRequest] = useState(false as boolean);
  const [doubleCheckUser, setDoubleCheckUser] = useState(null as Member | null);
  const [doubleCheckConfirm, setDoubleCheckConfirm] = useState(
    false as boolean
  );
  const [submitAnswerLoading, setSubmitAnswerLoading] = useState(
    false as boolean
  );
  const [answered, setAnswered] = useState(false as boolean);
  const [isPharmacist, setIsPharmacist] = useState(true as boolean);
  const { defaultTeam } = useContext(AuthContext);

  const { TabPane } = Tabs;

  const handleChange = (e: any) => {
    if (e.changes) {
      setReportData({ ...reportData, ...e.changes });
    } else {
      setReportData({ ...reportData, [e.target.name]: e.target.value });
      if (e.target.name === "report_name") {
        handleReportNameChange(reportId, e.target.value);
      }
    }
  };
  const handleChangeMedicine = (location: string, data: any, id?: string) => {
    if (location === "source") {
      setSourceMedicines([...data]);
    }
    if (location === "report") {
      setDrugData([...data]);
    }
    if (location === "change") {
      setChangeMedicines([...data]);
    }
    setLastMedicines({ location: location, data: data, id: id });
  };
  const handleDeleteMedicine = (
    location: string,
    data: DrugData[],
    id: number
  ) => {
    return DeleteConfirm(async () => {
      setSaving(true);
      try {
        await API.delete(`medicines/${id}`);
        let items = data;
        items = items.filter(item => {
          return item.id !== id;
        });
        if (handleChangeMedicine) {
          handleChangeMedicine(location, items);
        }
      } catch (error) {
        exceptionHandler(error, t);
      }
      setSaving(false);
    });
  };

  const handleChangePatientInformation = (event: any) => {
    const { name, value } = event.target;
    if (!name) {
      return;
    }
    setCaseState({ ...caseState, [name]: value });
  };

  const handleChangeDate = (time: any) => {
    if (caseState.age) {
      setCaseState({ ...caseState, birthday: time });
    } else {
      if (caseState.birthday && caseState.age === 0) {
        setCaseState({ ...caseState, age: caseState.age });
        return;
      }
      const birthDate = moment(time, "YYYY-MM-DD");
      const today = moment();
      const ageDiff = today.diff(birthDate, "y");
      setCaseState({ ...caseState, age: ageDiff, birthday: time });
    }
  };

  const handleRevertMedicine = (location: string, reportId: string) => {
    return DeleteConfirm(
      async () => {
        setSaving(true);
        try {
          const res = await API.post(`revert-medicines/${reportId}`);
          const changeMedicine: DrugData[] = [];
          res.data.forEach((item: DrugData) => {
            if (item.location === "change") {
              changeMedicine.push(item);
            }
          });
          setChangeMedicines([...changeMedicine]);
        } catch (error) {
          exceptionHandler(error, t);
        }
        setSaving(false);
      },
      "Are you sure you want to revert your answer to the proposal?",
      true
    );
  };

  useEffect(() => {
    updateMedicineList(medicineList);
  }, [medicineList]);

  const updateMedicineList = (medicineList: any[]) => {
    // setMedicines(medicineList);
    // setLastMedicines(medicineList);
    const sourceMedicine: DrugData[] = [];
    const reportMedicine: DrugData[] = [];
    const changeMedicine: DrugData[] = [];
    medicineList.forEach((item: DrugData) => {
      if (item.location === "source") {
        sourceMedicine.push(item);
      }
      if (item.location === "report") {
        reportMedicine.push(item);
      }
      if (item.location === "change") {
        changeMedicine.push(item);
      }
    });
    setSourceMedicines([...sourceMedicine]);
    setDrugData([...reportMedicine]);
    setChangeMedicines([...changeMedicine]);
  };

  useEffect(() => {
    if (viewAsTeamOwner) {
      setAnswerDisableText("");
      return;
    }
    if (!isReceiverReport) {
      if (reportData.confirmed_at === null) {
        setAnswerDisableText(
          t("Can answer after the report creation is complete")
        );
      } else if (
        (caseStatus === "accepting" || caseStatus === "confirming") &&
        !reportData.canceled &&
        reportData.answered_at === null
      ) {
        setAnswerDisableText(
          t(`Available for viewing after the doctor's answer is complete`)
        );
      } else setAnswerDisableText("");
    } else setAnswerDisableText("");
  }, [reportData, isReceiverReport, caseStatus]);

  useEffect(() => {
    if (id && location.pathname) {
      if (doubleCheckUser || confirmRequest || doubleCheckConfirm) {
        history.replace({
          pathname: `/cases/${id}`,
          search: location.search,
          state: { clear: true }
        });
      } else {
        history.replace({
          pathname: `/cases/${id}`,
          search: location.search,
          state: { clear: false }
        });
      }
    }
  }, [doubleCheckUser, confirmRequest, doubleCheckConfirm, location.pathname]);

  const fetchReport = async () => {
    const res = await API.get(`cases/${id || reportCode}`, {
      params: {
        public: publicReport || undefined,
        reportId: reportId
      }
    });
    setReportData({
      answered_at: res.data.report.answeredAt,
      assigned_at: res.data.report.assignedAt,
      canceled: res.data.report.canceled,
      case_id: res.data.report.caseId,
      change_requested_at: res.data.report.changeRequestedAt,
      confirmed_at: res.data.report.confirmedAt,
      created_at: res.data.report.createdAt,
      id: res.data.report.id,
      pathologistUserId: res.data.report.pathologistUserId,
      pathologistName: res.data.report.pathologistName,
      pharmacist_user_id: res.data.report.pharmacistUserId,
      public_url: res.data.report.publicUrl,
      report_name: res.data.report.reportName,
      source_comment: res.data.report.sourceComment,
      source_reference: res.data.report.sourceReference,
      summary: res.data.report.summary,
      teamClientId: res.data.report.teamClientId,
      team_id: res.data.report.teamId,
      tracing_comment: res.data.report.tracingComment,
      tracing_type:
        res.data.report.tracingType === 0
          ? "Report"
          : res.data.report.tracingType,
      updated_at: res.data.report.updatedAt,
      user_id: res.data.report.userId,
      clientCheckUserId: res.data.report.clientCheckUserId,
      client_staff_user_id: res.data.report.clientStaffUserId,
      clientStaffName: res.data.report.clientStaffName,
      answer_comment: res.data.report.answerComment,
      change_prescription: res.data.report.changePrescription,
      change_comment: res.data.report.changeComment,
      clientTeamId: res.data.report.clientTeamId
    });
    setLastReportData({
      answered_at: res.data.report.answeredAt,
      assigned_at: res.data.report.assignedAt,
      canceled: res.data.report.canceled,
      case_id: res.data.report.caseId,
      change_requested_at: res.data.report.changeRequestedAt,
      confirmed_at: res.data.report.confirmedAt,
      created_at: res.data.report.createdAt,
      id: res.data.report.id,
      pathologistUserId: res.data.report.pathologistUserId,
      pathologistName: res.data.report.pathologistName,
      pharmacist_user_id: res.data.report.pharmacistUserId,
      public_url: res.data.report.publicUrl,
      report_name: res.data.report.reportName,
      source_comment: res.data.report.sourceComment,
      source_reference: res.data.report.sourceReference,
      summary: res.data.report.summary,
      teamClientId: res.data.report.teamClientId,
      team_id: res.data.report.teamId,
      tracing_comment: res.data.report.tracingComment,
      tracing_type:
        res.data.report.tracingType === ""
          ? "Report"
          : res.data.report.tracingType,
      updated_at: res.data.report.updatedAt,
      user_id: res.data.report.userId,
      clientCheckUserId: res.data.report.clientCheckUserId,
      client_staff_user_id: res.data.report.clientStaffUserId,
      clientStaffName: res.data.report.clientStaffName,
      answer_comment: res.data.report.answerComment,
      change_prescription: res.data.report.changePrescription,
      change_comment: res.data.report.changeComment,
      clientTeamId: res.data.report.clientTeamId
    });
    setSenderData({
      name: `${res.data.team.organization.name} ${res.data.team.name}`,
      address: res.data.team.address,
      phone: res.data.team.phone,
      fax: res.data.team.fax,
      teamId: res.data.team.ID
    });

    updateMedicineList(res.data.medicines);

    if (res.data.case.teamId === defaultTeam) {
      setIsPharmacist(true);
    } else {
      setIsPharmacist(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      fetchReport();
    }
  }, [reportId, answered]);

  useEffect(() => {
    if (isReceiverReport && senderData.teamId) getCaseMembers();
  }, [isReceiverReport, senderData.teamId]);

  const getCaseMembers = async () => {
    try {
      const res = await API.get(`members`, {
        params: {
          teamId: senderData.teamId
        }
      });
      const filteredMembers = res.data.filter((item: any) => {
        return item.id !== "";
      });
      setSenderMembers(filteredMembers);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const saveMedicines = async () => {
    if (lastMedicines.id) {
      let medicine: any;
      lastMedicines.data.forEach((item: any) => {
        if (String(item.id) === lastMedicines.id) {
          medicine = item;
        }
      });
      setSaving(true);
      if (medicine) {
        try {
          await API.put(`medicines/${lastMedicines.id}`, {
            reportId: reportId,
            location: lastMedicines.location,
            name: medicine.name,
            dosage: medicine.dosage,
            usage: medicine.usage,
            startAt: medicine.startAt,
            medicalAgency: medicine.medicalAgency,
            department: medicine.department,
            changes: medicine.changes,
            details: medicine.details,
            prescriber: medicine.prescriber,
            prescriptionDays: medicine.prescriptionDays,
            prescription: {
              type: medicine.prescription.type,
              content: medicine.prescription.content,
              reason: medicine.prescription.reason
            }
          });
          setLastMedicines({});
          setSaving(false);
        } catch (error) {
          exceptionHandler(error, t);
          setSaving(false);
        }
      }
    }
  };

  const saveCase = async () => {
    try {
      let notContinue = true;
      let updatedField: any;
      let value;

      Object.entries(caseState).forEach(item => {
        Object.entries(lastCaseState).forEach(lastItem => {
          if (lastItem[0] === item[0]) {
            if (lastItem[1] !== item[1]) {
              notContinue = false;
              updatedField = lastItem[0];
              value = item[1];
            }
          }
        });
      });
      if (notContinue) {
        return;
      }
      setSaving(true);
      await API.put(`cases/${id}`, {
        caseNumber: caseState.case_number,
        agreementPharmacistUserId: caseState.pharmacist,
        patientId: caseState.patient_id,
        patientName: caseState.patient_name,
        sex: caseState.sex,
        birthday: caseState.birthday,
        age: Number(caseState.age),
        occupation: caseState.occupation,
        answer: caseState.answer,
        patientComment: caseState.patient_comment,
        agreementDescription: caseState.agreement_description,
        agreementSend: caseState.agreement_send
        // patientEmail: caseState.patient_email
      });
      setLastCaseState({ ...lastCaseState, [updatedField]: value });
      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };

  const handleBlur = async () => {
    if (publicReport) {
      return;
    }
    try {
      saveMedicines();
      saveCase();
      let notContinue = true;

      Object.entries(reportData).forEach(item => {
        Object.entries(lastReportData).forEach(lastItem => {
          if (lastItem[0] === item[0]) {
            if (lastItem[1] !== item[1]) {
              notContinue = false;
            }
          }
        });
      });
      if (notContinue) {
        return;
      }
      setSaving(true);
      await API.put(`/reports/${reportId}`, {
        caseId: id,
        id: reportData.id,
        teamId: reportData.team_id,
        userId: reportData.user_id,
        reportName: reportData.report_name,
        pathologistUserId: reportData.pathologistUserId,
        pathologistName: reportData.pathologistName,
        pharmacistUserId: reportData.pharmacist_user_id,
        summary: reportData.summary,
        tracingType: reportData.tracing_type,
        tracingComment: reportData.tracing_comment,
        sourceComment: reportData.source_comment,
        sourceReference: reportData.source_reference,
        publicUrl: reportData.public_url,
        changeRequestedAt: reportData.change_requested_at,
        canceled: reportData.canceled,
        confirmedAt: reportData.confirmed_at,
        assignedAt: reportData.assigned_at,
        answeredAt: reportData.answered_at,
        clientCheckUserId: reportData.clientCheckUserId,
        clientStaffUserId: reportData.client_staff_user_id,
        clientStaffName: reportData.clientStaffName,
        answerComment: reportData.answer_comment,
        changePrescription: reportData.change_prescription,
        changeComment: reportData.change_comment,
        teamClientId: reportData.teamClientId,
        clientTeamId: reportData.clientTeamId
      });
      setLastReportData({
        ...reportData
      });
      if (isReceiverReport && reportData.pathologistUserId)
        handleCaseStatus("confirming");
      setSaving(false);
    } catch (error) {
      // setReportData({ ...lastReportData });
      setSaving(false);
      exceptionHandler(error, t);
    }
  };

  const handleConfirm = async () => {
    setSaving(true);
    try {
      await API.put(`reports/${reportId}`, {
        caseNumber: caseState.case_number,
        confirm: true,
        caseId: id
      });
      setConfirmRequest(false);
      history.go(0);
    } catch (error) {
      setConfirmRequest(false);
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  const handleDoubleCheck = async () => {
    if (doubleCheckUser && doubleCheckUser.id) {
      setSaving(true);
      try {
        const response = await API.post("/double-checks", {
          reportId: reportId,
          userId: doubleCheckUser.id
        });
        setDoubleCheckUser(null);
        const data = {
          reportId: reportId,
          userId: doubleCheckUser.id,
          id: response.data.id
        };

        handleCaseStatus("pharmacistChecking");

        const supervisor = allMembers?.find(member => {
          return member.id === doubleCheckUser.id;
        });
        setSupervisorPharmacist(supervisor?.name);

        setDoubleCheckData(data);
      } catch (error) {
        exceptionHandler(error, t);
      }
      setSaving(false);
    }
  };

  const handeReturnDoubleCheck = async () => {
    if (caseDoubleCheck?.id) {
      setSaving(true);
      try {
        await API.post(`/double-checks/${caseDoubleCheck?.id}/return`);
        history.push("/cases");
      } catch (error) {
        exceptionHandler(error, t);
      }
      setSaving(false);
    }
  };

  const handleConfirmDoubleCheck = async () => {
    if (caseDoubleCheck?.id) {
      setSaving(true);
      try {
        await API.post(`/double-checks/${caseDoubleCheck?.id}/confirm`);
        history.push("/cases");
      } catch (error) {
        exceptionHandler(error, t);
      }
      setDoubleCheckConfirm(false);
      setSaving(false);
    }
  };

  const handleSubmitAnswer = async () => {
    setSubmitAnswerLoading(true);
    try {
      await API.post(`/reports/${reportId}/answer`);
      setAnswered(true);
      handleStatus("answered");
    } catch (error) {
      exceptionHandler(error, t);
    }
    if (isReceiverReport) setConfirmRequest(false);
    setSubmitAnswerLoading(false);
  };

  const handlePublicUrl = async (confirm: boolean, personal: boolean) => {
    try {
      const response = await API.post(`reports/${reportId}/public-url`, {
        confirm: reportData.public_url ? false : true,
        personal
      });
      setReportData({ ...reportData, public_url: response.data.publicUrl });
    } catch (err) {
      exceptionHandler(err, t);
    }
  };

  const handleRevoke = async () => {
    try {
      await API.post(`/cases/${id}/revoke`);
      if (handleCaseStatus) handleCaseStatus("canceled");
      setReportData({ ...reportData, canceled: true });
      Alert("success", "success", t("The report has been cancelled."), t);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const disabled = publicReport || viewAsTeamOwner;

  return (
    <div className={classes.container}>
      <CaseTags className={classes.tag} type={caseStatus} />
      <Header
        caseState={caseState}
        reportData={reportData}
        agreementStatus={agreementStatus}
        caseStatus={caseStatus}
      />

      <div className={classes.contents}>
        <div className={classes.tabContainer}>
          <Tabs className={classes.tabBar} defaultActiveKey="1">
            <TabPane tab={t("Report2")} key="1">
              <Form onBlur={handleBlur}>
                {(caseStatus === "creating" ||
                  caseStatus === "pharmacistChecking") &&
                reportData.confirmed_at === null ? (
                  <TextField
                    wraperClass={classes.reportInput}
                    name={"report_name"}
                    placeholder={t("{ReportName}")}
                    onChange={handleChange}
                    editValue={reportData.report_name}
                    disabled={disabled}
                  />
                ) : (
                  <Typography
                    className={classes.titleBlock}
                    fontSize="14px"
                    lineHeight="17px"
                  >
                    {reportData.report_name}
                  </Typography>
                )}
                <AgreementBearer
                  blockTitleName="Reporter"
                  selectFieldName="pharmacist_user_id"
                  pharmacist={reportData?.pharmacist_user_id}
                  members={isReceiverReport ? senderMembers : allMembers}
                  handleChange={handleChange}
                  isEdit={
                    (caseStatus === "creating" ||
                      caseStatus === "pharmacistChecking") &&
                    reportData.confirmed_at === null
                  }
                  reportData={reportData}
                  supervisoryPharmacist={supervisorPharmacist}
                  disabled={disabled}
                  supervisorId={caseState.supervisory_pharmacist}
                  setSupervisorName={setSupervisorPharmacist}
                />
                <PatientInformation
                  caseState={caseState}
                  agreementStatus={caseState.agreement_status}
                  handleChange={handleChangePatientInformation}
                  handleChangeDate={handleChangeDate}
                  viewAsTeamOwner={viewAsTeamOwner}
                />
                <Summary
                  handleChange={handleChange}
                  summary={reportData?.summary}
                  isEdit={
                    (caseStatus === "creating" ||
                      caseStatus === "pharmacistChecking") &&
                    reportData.confirmed_at === null
                  }
                  disabled={disabled}
                />

                <ReportDetail
                  isEdit={
                    (caseStatus === "creating" ||
                      caseStatus === "pharmacistChecking") &&
                    reportData.confirmed_at === null
                  }
                  reportType={String(reportData?.tracing_type) || "Report"}
                  comment={reportData?.tracing_comment}
                  prescriptionTypes={prescriptionTypes}
                  handleChange={handleChange}
                  reportId={reportId}
                  disabled={disabled}
                  isPublicReport={publicReport ?? false}
                />
                <SourceInformation
                  isEdit={
                    (caseStatus === "creating" ||
                      caseStatus === "pharmacistChecking") &&
                    reportData.confirmed_at === null
                  }
                  comment={reportData?.source_comment}
                  reference={reportData?.source_reference}
                  sourceMedicines={sourceMedicines}
                  setSourceMedicines={setSourceMedicines}
                  handleChange={handleChange}
                  handleChangeMedicine={handleChangeMedicine}
                  reportId={reportId}
                  handleDeleteMedicine={handleDeleteMedicine}
                  isPublicReport={publicReport ?? false}
                  disabled={disabled}
                />
                <Sender
                  agreementStatus={""}
                  senderData={{
                    name: senderData.name,
                    address: senderData.address,
                    phone: senderData.phone,
                    fax: senderData.fax,
                    pharmacist_name: caseState.agreementPharmacistUserName
                  }}
                />
              </Form>
            </TabPane>
            <TabPane
              disabled={
                (isPharmacist || publicReport) &&
                (reportData.confirmed_at === null ||
                  (caseStatus !== "sending" &&
                    !reportData.canceled &&
                    reportData.answered_at === null))
              }
              tab={
                <Tooltip
                  overlayClassName={classes.tooltip}
                  title={answerDisableText}
                >
                  <span>{t("Answer")}</span>
                </Tooltip>
              }
              key="2"
            >
              <Form onBlur={handleBlur}>
                <Answerer
                  complete={
                    reportData.canceled || reportData.answered_at !== null
                  }
                  physicianUserId={reportData?.pathologistUserId}
                  pathologistName={reportData?.pathologistName}
                  clients={clients}
                  administrativeStaff={reportData?.client_staff_user_id}
                  clientStaffName={reportData?.clientStaffName}
                  staffList={
                    isReceiverReport
                      ? allMembers
                      : reportData.clientTeamId === null
                      ? senderMembers
                      : allMembers
                  }
                  handleChange={handleChange}
                  disabled={disabled}
                  isReceiverReport={isReceiverReport}
                  clientReport={reportData.clientTeamId === null}
                  clientTeamId={reportData.clientTeamId!}
                  teamClientId={reportData.teamClientId!}
                />
                <AnswerFeedback
                  complete={
                    reportData.canceled || reportData.answered_at !== null
                  }
                  handleChange={handleChange}
                  answerComment={reportData?.answer_comment}
                  disabled={disabled}
                />
                <PrescriptionChange
                  caseStatus={caseStatus}
                  changePrescription={reportData?.change_prescription}
                  changeComment={reportData?.change_comment}
                  reportMedicinesData={drugData}
                  handleChange={handleChange}
                  reportId={reportId}
                  changeMedicines={changeMedicines}
                  setChangeMedicines={setChangeMedicines}
                  handleChangeMedicine={handleChangeMedicine}
                  handleDeleteMedicine={handleDeleteMedicine}
                  handleRevertMedicine={handleRevertMedicine}
                  isEdit={
                    !reportData.canceled &&
                    reportData.answered_at === null &&
                    !publicReport
                  }
                  viewAsTeamOwner={viewAsTeamOwner}
                />
                {reportData.answered_at === null &&
                  !reportData.canceled &&
                  !publicReport &&
                  !isReceiverReport && (
                    <div className={classes.submitContainer}>
                      <Button
                        className={classes.submitAnswer}
                        onClick={handleSubmitAnswer}
                        loading={submitAnswerLoading}
                        disabled={viewAsTeamOwner}
                      >
                        {t("Submit Answer")}
                      </Button>
                    </div>
                  )}
              </Form>
            </TabPane>
          </Tabs>
        </div>
      </div>

      {confirmRequest && (
        <div className={`${classes.bottomContainer} ${classes.moz}`}>
          <div className={classes.buttonWrapper}>
            <Button
              size="middle"
              className={classes.emptybutton}
              onClick={() => {
                setConfirmRequest(false);
              }}
            >
              {t("Back to Edit")}
            </Button>

            <Button
              size="middle"
              className={classes.blueButton}
              onClick={isReceiverReport ? handleSubmitAnswer : handleConfirm}
            >
              {t("Confirm")}
            </Button>
          </div>
        </div>
      )}

      {doubleCheckUser && doubleCheckUser.id && (
        <div className={`${classes.bottomContainer} ${classes.moz}`}>
          <div
            className={classes.member}
            style={{ backgroundColor: doubleCheckUser.color }}
          >
            <Typography className={classes.memberName}>
              {doubleCheckUser.name || doubleCheckUser.email}
            </Typography>
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              size="middle"
              className={classes.emptybutton}
              onClick={() => {
                setDoubleCheckUser(null);
              }}
            >
              {t("Back to Edit")}
            </Button>

            <Button
              size="middle"
              className={classes.blueButton}
              onClick={handleDoubleCheck}
            >
              {t("Send")}
            </Button>
          </div>
        </div>
      )}

      {doubleCheckConfirm && (
        <div className={`${classes.bottomContainer} ${classes.moz}`}>
          <div className={classes.buttonWrapper}>
            <Button
              size="middle"
              className={classes.emptybutton}
              onClick={() => {
                setDoubleCheckConfirm(false);
              }}
            >
              {t("Back to Edit")}
            </Button>

            <Button
              size="middle"
              className={classes.blueButton}
              onClick={handleConfirmDoubleCheck}
            >
              {t("Confirm")}
            </Button>
          </div>
        </div>
      )}
      {!publicReport && (
        <RightSidebar
          clear={!!(confirmRequest || doubleCheckUser || doubleCheckConfirm)}
          doubleCheckProps={{
            setConfirmRequest,
            pharmacistID: reportData?.pharmacist_user_id,
            confirm: !!reportData?.confirmed_at,
            caseStatus: caseStatus,
            members: allMembers,
            setDoubleCheckUser,
            caseDoubleCheck,
            setDoubleCheckReturn: handeReturnDoubleCheck,
            setDoubleCheckConfirm,
            physicianID: reportData?.pathologistUserId,
            canCompleteRequest:
              reportData.answered_at === null && isReceiverReport,
            isReceiverReport: isReceiverReport,
            isAnswered: reportData.answered_at !== null,
            canceled: reportData?.canceled,
            handleRevoke: handleRevoke
          }}
          myBookmark={caseState.myBookmark}
          teamBookmark={caseState.marked}
          caseID={id}
          reportID={reportData?.id}
          publicUrl={reportData.public_url}
          handlePublicUrl={handlePublicUrl}
          caseNumber={caseState.case_number}
          patientName={caseState.patient_name}
          viewAsTeamOwner={viewAsTeamOwner}
          commentNotification={commentNotification}
          setCommentNotification={setCommentNotification}
        />
      )}
    </div>
  );
};
