import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  exceptionHandler,
  Typography
} from "shared-components";

import { TeamCodeBox } from "../../components/atoms/TeamCodeBox";
import { Member } from "../cases/types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    nameField: {
      marginBottom: 27
    },
    field: {
      marginBottom: 34
    },
    finalField: {
      marginBottom: 79
    },
    title: {
      color: theme.primary,
      fontSize: 12,
      marginBottom: 4,
      letterSpacing: 0
    },
    description: {
      fontSize: 16,
      marginTop: 4,
      color: theme.gray7,
      letterSpacing: 0
    }
  };
});

interface Props {
  selectedOrganizationId: string;
  members: Member[];
}

export const TeamOrganization: React.FC<Props> = ({
  selectedOrganizationId,
  members
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { defaultTeam, isAdminTeam, authenticatedOwner } = useContext(
    AuthContext
  );

  const [branchInformation, setBranchInformation] = useState(
    {} as TeamInterface
  );

  useEffect(() => {
    getTeamDetails();
  }, []);

  const getTeamDetails = async () => {
    try {
      const response = !(isAdminTeam && authenticatedOwner)
        ? await API.get(`/teams/${defaultTeam}`)
        : await API.get(`/teams/${selectedOrganizationId}`);
      if (response.data) {
        setBranchInformation({
          ...response.data.teams,
          invitation_code: response.data.teams.invitationCode,
          user_id: response.data.team_users.userId
        });
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const [ownerName, setOwnerName] = useState("" as any);
  useEffect(() => {
    if (branchInformation.user_id) {
      getName(branchInformation.user_id);
    }
  }, [branchInformation.user_id, members]);

  const getName = (id: string) => {
    const member = members.find(item => {
      return item.id === id;
    });
    setOwnerName(member?.name);
  };

  return (
    <>
      <div className={classes.nameField}>
        <Typography className={classes.title}>
          {t("Branch/Department name")}
        </Typography>
        <Typography className={classes.description}>
          {branchInformation.name || "-"}
        </Typography>
      </div>

      <div className={classes.field}>
        <Typography className={classes.title}>{t("Address")}</Typography>
        <Typography className={classes.description}>
          {t`${branchInformation.address}` || t("-")}
        </Typography>
      </div>

      <div className={classes.field}>
        <Typography className={classes.title}>{t("Phone")}</Typography>
        <Typography className={classes.description}>
          {branchInformation.phone || "-"}
        </Typography>
      </div>

      <div className={classes.nameField}>
        <Typography className={classes.title}>{t("Fax")}</Typography>
        <Typography className={classes.description}>
          {branchInformation.fax || "-"}
        </Typography>
      </div>

      <div className={classes.finalField}>
        <Typography className={classes.title}>{t("Owner")}</Typography>
        <Typography className={classes.description}>
          {ownerName || branchInformation.owner_name || "-"}
        </Typography>
      </div>

      <TeamCodeBox invitationCode={branchInformation.invitation_code} />
    </>
  );
};
