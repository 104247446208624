import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  API,
  Button,
  exceptionHandler,
  Modal,
  TextField,
  Typography
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "& .ant-modal-body": {
        minWidth: 850,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      }
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    logo: {
      "& svg": {
        height: 25,
        width: 34,
        marginRight: 15
      }
    },
    modal: {
      maxWidth: "100%",
      marginTop: 120
    },
    modalButton: {
      textAlign: "center"
    },
    button: {
      height: 28,
      width: 160,
      fontSize: 14,
      marginTop: 17,
      lineHeight: "17px",
      letterSpacing: "0px"
    },
    draggerComponent: {
      textAlign: "center"
    },
    text: {
      color: theme.primary,
      textAlign: "center",
      letterSpacing: "1.08px",
      marginBottom: 4,
      "& article": {
        textAlign: "center"
      }
    },
    textBlue: {
      color: theme.primary
    },
    textGreen: {
      color: theme.textGreen
    },
    inputCaseNo: {
      width: "fit-content",
      marginBottom: 5
    }
  };
});

interface Props {
  visible: boolean;
  onCancel: any;
  duplicateWithCaseID?: string;
}
const CreateCaseModal = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false as boolean);
  const [state, setState] = useState({ caseId: "" });

  const handleSubmit = async () => {
    try {
      setLoading(true);

      let caseID = props.duplicateWithCaseID;
      const clientTeamId =
        caseID && caseID.includes("?clientTeamId=") && caseID.split("=")[1];
      if (clientTeamId) {
        caseID = caseID && caseID.split("?")[0];
      }

      const path = props.duplicateWithCaseID
        ? `cases/${caseID}/duplicate`
        : `cases`;

      const response = await API.post(path, {
        caseNumber: state.caseId,
        clientTeamId: clientTeamId || undefined
      });
      history.push(`/cases/${response.data.case.id}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleModalField = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  return (
    <Modal
      className={classes.modalContainer}
      visible={props.visible}
      onCancel={props.onCancel}
      width="850px"
      mask={false}
    >
      <Form form={form} className={classes.modal} onFinish={handleSubmit}>
        <Typography type="body2" className={classes.text}>
          {t("Enter case number")}
        </Typography>
        <Form.Item
          id="name"
          name="name"
          rules={[
            {
              required: true,
              message: t("Required")
            }
          ]}
        >
          <div className={classes.inputCaseNo}>
            <TextField
              autoComplete="off"
              name="caseId"
              onChange={handleModalField}
              maxLength={90}
            />
          </div>
        </Form.Item>
        <Form.Item className={classes.modalButton}>
          <Button
            className={classes.button}
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            data-testid="create-button"
          >
            {t("Create")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { CreateCaseModal };
