import { Layout } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  children: React.ReactNode;
  className?: any;
  sidebar?: boolean;
  onScroll?: any;
  contentId?: string;
  noOverFlow?: boolean;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      marginTop: 40,
      background: "white",
      "& .ant-layout-content": {
        height: "100%",
        overflowY: props => {
          return props.noOverFlow ? "unset" : "auto";
        }
      }
    },
    innerWrapper: {
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1c1",
        borderRadius: "10px"
      }
    }
  };
});

export const ComponentWrapper: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { Content } = Layout;

  return (
    <Layout className={classes.componentWrapper}>
      <Content
        id={props.contentId}
        className={`${classes.innerWrapper} ${props.className}`}
        onScroll={props.onScroll}
      >
        {props.children}
      </Content>
    </Layout>
  );
};
