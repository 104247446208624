import { DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import { Form, Popover } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  AuthContext,
  Button,
  DeleteConfirm,
  DeleteIcon,
  exceptionHandler,
  FilePlusIcon,
  language,
  PorousLogo,
  theme
} from "shared-components";
import { TableComponent } from "shared-components/src/components/molecules/TableComponent";

import { ComponentWrapper } from "../../components";
import { AddClient } from "./AddClientModal";
import { AddTeamCode } from "./AddTeamCode";

const useStyle = createUseStyles({
  tableClassName: {
    "& .ant-table-tbody > tr > td": {
      "&:last-child": { padding: "0px 10px", maxWidth: "60px" },
      "&:nth-last-child(2)": { padding: "0px 10px", maxWidth: "60px" }
    },
    "& .ant-table-column-sorters": {
      padding: "1px 0px"
    }
  },
  moreMenu: {
    fontSize: "16px"
  },
  createButton: {
    float: "Right",
    width: " fit-Content",
    height: "32px",
    marginTop: "33px",
    marginBottom: "74px",
    borderRadius: "16px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: theme.textBlue,
    color: theme.textWhite,
    display: "flex",

    alignItems: "center",
    "&:hover ": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:focus": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:active": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    boxShadow: " 0px 3px 6px #00000029"
  },
  downloadButton: {
    float: "Right",
    width: " fit-Content",
    height: "32px",
    marginTop: "33px",
    marginBottom: "74px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: theme.textWhite,
    color: theme.textBlue,
    border: "none",
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:focus": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:active": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    }
  },
  deleteicon: {
    height: "35px",
    width: "184px",
    cursor: "pointer",
    "& span": {
      paddingLeft: "12px",
      marginLeft: "-5px"
    }
  },
  modal: {
    marginTop: "-5px",
    "& .ant-form-item": {
      marginBottom: "14px !important"
    },
    textAlign: "left"
  },
  componentWrapper: {
    width: "100%",
    paddingBottom: 33,
    height: "calc(100vh - 100px) !important"
  },
  addbutton: {
    marginRight: "17px",
    marginLeft: "22px"
  },
  buttoncomponent: {
    width: "347px",
    justifyContent: "space-between"
  },
  datetimewrapper: {
    display: "flex",
    flexDirection: "column"
  }
});

export const Clients = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { isAdminTeam, defaultTeam } = useContext(AuthContext);

  const [loading, setLoading] = useState(true as boolean);
  const [clients, setClients] = useState([] as TeamClientData[]);
  const [visibility, setVisibility] = useState(false);
  const [teamcodevisibility, setTeamCodeVisibility] = useState(false);
  const [invitationCode, setInvitationCode] = useState("" as string);
  const [formValue, setFormValue] = useState({
    id: 0,
    organizationName: "",
    teamName: "",
    type: "hospital",
    address: "",
    phone: "",
    fax: "",
    pic: "",
    memo: ""
  } as ClientFormData);

  const [totalRequests, setTotalRequests] = useState(0 as number);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 30 } as {
    page: number;
    pageSize?: number;
  });

  const [processing, setProcessing] = useState(false as boolean);
  const [done, setDone] = useState(false as boolean);
  const [errors, setErrors] = useState([]);
  const [teamCodeError, setTeamCodeError] = useState("");
  const [modalType, setModalType] = useState("");
  const [templateDownloadLoading, setTemplateDownloadLoading] = useState(false);
  const [form] = Form.useForm();
  const [teamCodeForm] = Form.useForm();

  useEffect(() => {
    const pageSize = parseInt(localStorage.getItem("clientsPageSize") || "");
    if (pageSize && pageSize !== pagination.pageSize) {
      setPagination({ ...pagination, pageSize });
    }
  }, []);

  useEffect(() => {
    getTeamClients();
  }, [pagination, done]);

  const getTeamClients = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(`/clients/`, {
        params: {
          ...pagination,
          isAdminTeam: isAdminTeam
        }
      });
      setLoading(false);
      if (!response.data) {
        setClients([]);
        return;
      }

      setTotalRequests(response.count);
      setClients(response.data);
    } catch (error) {
      setLoading(false);
      setClients([]);
      exceptionHandler(error, t);
    }
  };

  const toggleVisibility = () => {
    if (visibility) {
      form.resetFields();
      setFormValue({
        id: 0,
        organizationName: "",
        teamName: "",
        type: "hospital",
        address: "",
        phone: "",
        fax: "",
        pic: "",
        memo: ""
      });
    }
    setVisibility(!visibility);
    setModalType("normal");
    setProcessing(false);
    setDone(false);
    setErrors([]);
  };
  const TeamCodeToggleVisibility = () => {
    if (teamcodevisibility) {
      teamCodeForm.resetFields();
      setInvitationCode("");
    }
    setTeamCodeVisibility(!teamcodevisibility);
    setModalType("teamCode");
    setProcessing(false);
    setDone(false);
    setTeamCodeError("");
  };

  const handleSizeChange = (page: number, pageSize: number) => {
    localStorage.setItem("clientsPageSize", `${pageSize}`);
    return setPagination({ page, pageSize });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvitationCode(e.target.value);
  };

  const handleChangeClientData = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.post(`clients/`, {
        invitationCode: invitationCode
      });
      getTeamClients();
      setLoading(false);
      teamCodeForm.resetFields();
      setVisibility(false);
      setTeamCodeVisibility(false);
      Alert(
        "success",
        "success",
        t("Team with provided team code is added as client."),
        t
      );
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleCSVUpload = async (event: any) => {
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("type", modalType);

    try {
      const res: any = await API.post("clients/bulk-registration", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      if (res && res.message === "success") {
        setProcessing(false);
        setDone(true);
      }
    } catch (error) {
      setProcessing(false);
      if (modalType === "teamCode") {
        setTeamCodeError(error.response.data.error.message);
        return;
      }
      setErrors(error.response.data.error);
      exceptionHandler(t, error);
    }
  };

  const handleSubmitCreateClient = async () => {
    setLoading(true);
    try {
      const path = formValue.id
        ? `create-clients/${formValue.id}`
        : `create-clients/`;
      await API.post(path, formValue);
      getTeamClients();
      setLoading(false);
      form.resetFields();
      setVisibility(false);
      Alert(
        "success",
        "success",
        t(
          formValue.id
            ? "Client updated successfully."
            : "Client added successfully."
        ),
        t
      );
      toggleVisibility();
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleClientRemove = async (id: number) => {
    DeleteConfirm(
      async () => {
        setLoading(true);
        try {
          await API.delete(`clients/${id}`);
          getTeamClients();
          setLoading(false);
          Alert("success", "success", t("Client removed successfully."), t);
        } catch (error) {
          setLoading(false);
          exceptionHandler(error, t);
        }
      },
      t("Delete client?"),
      false,
      t(
        "If you delete a contact, the report data and previous transaction information will not be deleted. However, some of the performance management features per contact will be unavailable."
      )
    );
  };

  const popOverContent = (id: number) => {
    return (
      <div
        className={classes.deleteicon}
        onClick={() => {
          return handleClientRemove(id);
        }}
      >
        <DeleteIcon />
        <span>{t("Remove")}</span>
      </div>
    );
  };

  const columns = [
    {
      render: (text: string, data: any) => {
        return <div>{!data.pic && <PorousLogo />}</div>;
      }
    },

    {
      title: t("Organization Type"),
      dataIndex: "type",
      key: "type",
      render: (text: string, data: any) => {
        return data && data.type === "hospital"
          ? t("Hospital")
          : data.type === "pharmacy"
          ? t("Pharmacy")
          : "-";
      },
      sorter: (a: any, b: any) => {
        return a.type.localeCompare(b.type);
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Organization Name"),
      dataIndex: "organizationName",
      key: "organizationName",
      sorter: (a: any, b: any) => {
        return a.organizationName.localeCompare(b.organizationName);
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Branch/Department"),
      dataIndex: "teamName",
      key: "teamName",
      sorter: (a: any, b: any) => {
        return a.teamName.localeCompare(b.teamName);
      },
      sortDirections: ["descend", "ascend"]
    },

    {
      title: t("Created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, data: any) => {
        const dt = moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div className={classes.datetimewrapper}>
            <span>{dt.split(" ")[0]}</span>
            <span>{dt.split(" ")[1]}</span>
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.createdAt.localeCompare(b.createdAt);
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Updated"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string, data: any) => {
        const dt = moment(data.updatedAt).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div className={classes.datetimewrapper}>
            <span>{dt.split(" ")[0]}</span>
            <span>{dt.split(" ")[1]}</span>
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.updatedAt.localeCompare(b.updatedAt);
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      render: (record: any) => {
        return (
          <div
            onClick={event => {
              return event.stopPropagation();
            }}
          >
            {record.TeamID === defaultTeam && (
              <Popover
                content={() => {
                  return popOverContent(record.id);
                }}
                trigger="click"
              >
                <MoreOutlined className={classes.moreMenu} />
              </Popover>
            )}
          </div>
        );
      }
    }
  ];

  const handleClientsDownload = async () => {
    try {
      const response: Blob = await API.get("/download-clients/", {
        params: {
          isAdminTeam: isAdminTeam,
          language: language,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        responseType: "blob"
      });

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      const dateString = moment(new Date()).format("YYYYMMDD_HHmmss");
      link.setAttribute("download", `clients_${dateString}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      exceptionHandler(err, t);
    }
  };

  const handleTemplateDownload = async (e: any) => {
    const templateName =
      modalType === "teamCode"
        ? "clients-teamcode-sample.csv"
        : "clients-sample.csv";

    try {
      setTemplateDownloadLoading(true);

      const res: any = await API.get(
        `/download-csv-templates?templateName=${templateName}`
      );
      if (res && res.data && res.data.templateUrl) {
        window.open(res.data.templateUrl);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setTemplateDownloadLoading(false);
  };

  return (
    <ComponentWrapper className={classes.componentWrapper}>
      <Button
        icon={<FilePlusIcon />}
        className={`${classes.createButton} ${classes.addbutton}`}
        onClick={toggleVisibility}
      >
        {t("Add new")}
      </Button>

      <Button
        icon={<FilePlusIcon />}
        className={classes.createButton}
        onClick={TeamCodeToggleVisibility}
      >
        {t("Add by team code")}
      </Button>

      <Button
        icon={<DownloadOutlined />}
        className={classes.downloadButton}
        onClick={handleClientsDownload}
      >
        {t("Download clients")}
      </Button>

      <TableComponent
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={clients}
        className={classes.tableClassName}
        pagination
        currentPage={pagination.page}
        pageSize={pagination.pageSize}
        totalData={totalRequests}
        handleSizeChange={handleSizeChange}
        handlePaginationChange={(page: number, pageSize?: number) => {
          return setPagination({ page, pageSize });
        }}
        sticky
        handleRow={(record: any) => {
          return {
            onClick: () => {
              if (record.TeamID !== defaultTeam) {
                Alert(
                  "",
                  "info",
                  t("Cannot edit clients that have been added by other teams."),
                  t
                );
                return;
              }
              if (!record.pic) {
                Alert(
                  "",
                  "info",
                  t("Cannot edit clients added by team code."),
                  t
                );
                return;
              }

              setFormValue({
                id: record.id,
                organizationName: record.organizationName,
                teamName: record.teamName,
                type: record.type,
                address: record.address,
                phone: record.phone,
                fax: record.fax,
                pic: record.pic,
                memo: record.memo
              });

              form.setFieldsValue({
                organizationName: record.organizationName,
                teamName: record.teamName,
                type: record.type,
                address: record.address,
                phone: record.phone,
                fax: record.fax,
                pic: record.pic,
                memo: record.memo
              });

              toggleVisibility();
            }
          };
        }}
      />
      <AddClient
        toggleVisibility={toggleVisibility}
        visibility={visibility}
        handleCSVUpload={handleCSVUpload}
        handleTemplateDownload={handleTemplateDownload}
        handleSubmitCreateClient={handleSubmitCreateClient}
        handleChangeClientData={handleChangeClientData}
        templateDownloadLoading={templateDownloadLoading}
        errors={errors}
        formValue={formValue}
        processing={processing}
        form={form}
        done={done}
      />
      <AddTeamCode
        TeamCodeToggleVisibility={TeamCodeToggleVisibility}
        teamcodevisibility={teamcodevisibility}
        handleCSVUpload={handleCSVUpload}
        handleTemplateDownload={handleTemplateDownload}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        teamCodeError={teamCodeError}
        templateDownloadLoading={templateDownloadLoading}
        processing={processing}
        done={done}
        teamCodeForm={teamCodeForm}
      />
    </ComponentWrapper>
  );
};
