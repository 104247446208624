import { DownloadOutlined } from "@ant-design/icons";
import { Tabs, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  Avatar,
  Button,
  CancelInvitationIcon,
  exceptionHandler,
  FilePlusIcon,
  language,
  ResendInvitationIcon,
  theme
} from "shared-components";
import { TableComponent } from "shared-components/src/components/molecules/TableComponent";

import { ComponentWrapper } from "../../components";
import { Member } from "../cases/types/case";

const useStyle = createUseStyles({
  wrapper: {
    paddingBottom: 33
  },
  tableClassName: {
    "& .ant-table-tbody > tr > td": {
      "&:last-child": { padding: "0px 10px", maxWidth: "60px" },
      "&:nth-last-child(2)": { padding: "0px 6px", maxWidth: "60px" },
      "&:nth-last-child(3)": { padding: "0px 6px", maxWidth: "60px" }
    },
    "& .ant-table-tbody > tr > td > .ant-tag-red": {
      marginTop: 8
    },
    "& .ant-table-column-sort": {
      background: "none"
    }
  },
  tabStyle: {
    "& .ant-tabs-nav-list > :first-child": {
      marginLeft: 38
    },
    "& .ant-tabs-nav::before": {
      borderBottom: "none"
    },
    fontFamily: "Noto Sans JP"
  },
  iconwrap: {
    fontSize: "24px"
  },
  deleteicon: {
    display: "flex",
    height: "35px",
    width: "184px",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      paddingLeft: 12
    }
  },
  tagStyle: {
    display: "flex",
    justifyContent: "center"
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 17,
    minHeight: 33
  },
  button: {
    background: theme.darkBlueButton,
    color: theme.textWhite,
    height: 32,
    borderRadius: 16,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: 17
    },
    "&:hover ": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:focus": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    "&:active": {
      backgroundColor: theme.textBlue,
      color: theme.textWhite
    },
    boxShadow: " 0px 3px 6px #00000029",
    fontFamily: "Noto Sans JP"
  },
  iconMenu: {
    padding: "0px 5px",
    fontSize: "16px"
  },
  popoverContainer: {
    "& .ant-popover-inner-content": {
      padding: "0px !important"
    }
  },
  actions: {
    display: "flex",
    flexDirection: "column"
  },
  actionMenu: {
    padding: "10px 15px",
    minWidth: 120,
    cursor: "pointer",
    "&:hover": {
      background: theme.background
    }
  },
  actionMenuWrapper: {
    width: "80px",
    position: "absolute",
    left: 0,
    margin: {
      top: -8,
      right: -15
    }
  },
  divider: {
    border: `1px solid ${theme.gray4}`
  },
  borderBox: {
    "& > div": {
      padding: 0
    }
  },
  teamCodeWrapperHeight: {
    height: 128
  },
  teamCode: {
    color: "#5A5A5A",
    "& span": {
      marginLeft: 20,
      fontSize: 22,
      color: "#2E2E2E"
    }
  },
  firstDesc: {
    marginTop: 18
  },
  buttonWrapper: {
    textAlign: "center",
    marginBottom: 16,
    marginTop: 16,
    display: "flex",
    justifyContent: "center"
  },
  codeWrapper: {
    padding: "21px 0px 18px 30px",
    overflow: "hidden",
    "&:hover": {
      "overflow-y": "auto"
    },
    "&::-webkit-scrollbar": {
      width: 5
    },
    "&::-webkit-scrollbar-track": {
      background: theme.lightGrey
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px"
    }
  },
  copyButton: {
    backgroundColor: theme.darkBlueButton,
    width: 145,
    height: 28,
    padding: 0,
    borderRadius: 2
  },
  avatarColumn: {
    paddingLeft: "30px !important"
  },
  avatar: {
    width: 25,
    height: 25,
    marginRight: 3
  },
  downloadButton: {
    float: "Right",
    width: " fit-Content",
    height: "32px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: theme.textWhite,
    color: theme.textBlue,
    border: "none",
    display: "flex",
    alignItems: "center",
    "&:hover ": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:focus": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    },
    "&:active": {
      backgroundColor: theme.textWhite,
      color: theme.textBlue
    }
  },
  invitationIconWrapper: {
    marginLeft: 5,
    marginTop: 5,
    cursor: "pointer"
  },
  invitationResendIcon: {
    "& > svg": {
      height: 25,
      width: 25
    }
  },
  invitationCancelIcon: {
    marginLeft: 5,
    "& > svg": {
      height: 25,
      width: 25
    }
  }
});

interface Props {
  toggleModal: () => void;
  setShowTeamCode: React.Dispatch<React.SetStateAction<boolean>>;
  response: any;
  fetchMember: boolean;
}

export const OwnerTeam: React.FC<Props> = ({
  toggleModal,
  setShowTeamCode,
  response,
  fetchMember
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("" as string);
  const [memberList, setMemberList] = useState([] as Member[]);
  const [loading, setLoading] = useState(false as boolean);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 30 } as {
    page: number;
    pageSize?: number;
  });
  const [totalNumber, setTotalNumber] = useState(0 as number);

  const [sortWithColumn, setSortWithColumn] = useState([] as any[]);
  const [downloading, setDownloading] = useState(false as boolean);

  useEffect(() => {
    const pageSize = parseInt(localStorage.getItem("memberPageSize") || "");
    if (pageSize && pageSize !== pagination.pageSize) {
      setPagination({ ...pagination, pageSize });
    }
    let tab = localStorage.getItem("memberTab");
    if (!tab) tab = "ownerTeam";
    if (tab) {
      setActiveTab(tab);
      if (tab === "ownerTeam") setShowTeamCode(true);
      else setShowTeamCode(false);
    }
  }, []);

  useEffect(() => {
    if (activeTab !== "") getMemberList();
  }, [activeTab, pagination, response, fetchMember]);

  const getMemberList = async () => {
    setLoading(true);
    try {
      let response: any;
      if (activeTab === "ownerTeam") {
        response = await API.get(`/members`, {
          params: {
            ...pagination
          }
        });
      } else {
        response = await API.get(`/organizations-members/branch`, {
          params: {
            ...pagination
          }
        });
      }

      const sortedData = response.data?.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
      });

      const indexAdded = sortedData?.map((item: any, key: number) => {
        item.index = key;
        return item;
      });

      if (indexAdded) {
        setMemberList(indexAdded);
      } else {
        setMemberList([]);
      }

      setTotalNumber(response.count);
    } catch (error) {
      setMemberList([]);
      exceptionHandler(error, t);
    }
    setLoading(false);
  };

  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    if (activeKey === "ownerTeam") {
      setShowTeamCode(true);
    } else {
      setShowTeamCode(false);
    }
    localStorage.setItem("memberTab", activeKey);
    setSortWithColumn([]);
  };

  const handleResendInvitation = async (email: string, teamId: string) => {
    try {
      const response: any = await API.post(`invite-users/resend-invitation`, {
        email: email,
        teamId: teamId
      });
      if (response) {
        if (
          response.data &&
          response.data.code &&
          response.data.code === "info"
        ) {
          Alert(
            "",
            "info",
            t(
              "Less than 10 minutes have passed since the last resend. Please resend after a while."
            ),
            t
          );
        } else {
          Alert("success", "success", t("Invitation email is sent"), t);
        }
      }
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleCancelInvitation = async (email: string, teamId: string) => {
    try {
      await API.post(`invite-users/cancel-invitation`, {
        email: email,
        teamId: teamId
      });
      getMemberList();
      Alert("success", "success", t("Invitation is cancelled"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const ownerTeamColumn = [
    {
      dataIndex: "color",
      width: 70,
      className: classes.avatarColumn,
      render: (text: any, data: any) => {
        return (
          <Avatar
            size={25}
            className={classes.avatar}
            photo={data.ProfileImage}
            color={text}
          />
        );
      }
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      sortOrder:
        sortWithColumn.length > 0
          ? sortWithColumn[0].columnKey === "name" && sortWithColumn[0].order
          : "ascend",
      sorter: {
        compare: (a: any, b: any) => {
          return a.name.localeCompare(b.name);
        },
        multiple: activeTab === "ownerTeam" ? 3 : 1
      },
      render: (text: any) => {
        return text ? <>{text}</> : "-";
      }
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      sortOrder:
        sortWithColumn.length > 0 &&
        sortWithColumn[0].columnKey === "email" &&
        sortWithColumn[0].order,
      sorter: (a: any, b: any) => {
        return a.email.localeCompare(b.email);
      },
      render: (text: string, data: any) => {
        return data ? text : "-";
      }
    },
    {
      title: t("Role"),
      dataIndex: "role",
      key: "role",
      sortOrder:
        sortWithColumn.length > 0 &&
        sortWithColumn[0].columnKey === "role" &&
        sortWithColumn[0].order,
      sorter: (a: any, b: any) => {
        return a.role.localeCompare(b.role);
      },
      render: (text: string, data: any) => {
        return data && data.role ? (
          <Tag color={data.role === "owner" ? "purple" : "green"}>
            {t(data.role)}
          </Tag>
        ) : (
          "--"
        );
      }
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      sortOrder:
        sortWithColumn.length > 0 &&
        sortWithColumn[0].columnKey === "status" &&
        sortWithColumn[0].order,
      sorter: (a: any, b: any) => {
        return a.status.localeCompare(b.status);
      },
      render: (text: string, data: any) => {
        return (
          <>
            {data.status === "Registered" ? (
              <Tag color="blue"> {t(data.status)}</Tag>
            ) : (
              <>
                <Tag color="red"> {t(data.status)}</Tag>
                <div className={classes.invitationIconWrapper}>
                  <Tooltip placement="bottom" title={t("Resend Invitation")}>
                    <ResendInvitationIcon
                      className={classes.invitationResendIcon}
                      onClick={() => {
                        return handleResendInvitation(data.email, data.teamId);
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="bottom" title={t("Cancel Invitation")}>
                    <CancelInvitationIcon
                      className={classes.invitationCancelIcon}
                      onClick={() => {
                        return handleCancelInvitation(data.email, data.teamId);
                      }}
                    />
                  </Tooltip>
                </div>
              </>
            )}
          </>
        );
      }
    },
    {
      title: t("Added at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sortOrder:
        sortWithColumn.length > 0 &&
        sortWithColumn[0].columnKey === "createdAt" &&
        sortWithColumn[0].order,
      sorter: (a: any, b: any) => {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      },
      render: (text: string, data: any) => {
        return data && data.createdAt
          ? moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss")
          : "-";
      }
    }
  ];

  const otherTeamColumns = [
    ownerTeamColumn[0],
    {
      title: t("Branch/Department"),
      key: "teamName",
      sortOrder:
        sortWithColumn.length > 1
          ? sortWithColumn[1].columnKey === "teamName" &&
            sortWithColumn[1].order
          : "ascend",
      sorter: {
        compare: (a: any, b: any) => {
          return a.teamName && a.teamName.localeCompare(b.teamName);
        },
        multiple: 2
      },
      render: (data: any) => {
        return data && data.teamName ? data.teamName : "-";
      }
    },
    ...ownerTeamColumn.slice(1, 6)
  ];

  const columns =
    activeTab === "ownerTeam" ? ownerTeamColumn : otherTeamColumns;

  const { TabPane } = Tabs;
  const handleSizeChange = (page: number, pageSize: number) => {
    localStorage.setItem("memberPageSize", `${pageSize}`);
    return setPagination({ page, pageSize });
  };

  const handleMemberDownload = async () => {
    try {
      setDownloading(true);
      let response: Blob;
      response = await API.get("/download-members", {
        params: {
          language: language,
          type: activeTab === "ownerTeam" ? "owner" : "member",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        responseType: "blob"
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      const dateString = moment(new Date()).format("YYYYMMDD_HHmmss");
      link.setAttribute("download", `members_${dateString}.csv`);
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      exceptionHandler(err, t);
    }
  };

  const handleSortChange = (pagination: any, filters: any, sorter: any) => {
    if (activeTab === "ownerTeam") {
      if (!Array.isArray(sorter)) return setSortWithColumn([sorter]);

      let data = sorter.find((item: any) => {
        return item.columnKey === "name";
      });
      return setSortWithColumn([data]);
    }

    if (activeTab === "branch") {
      const teamSortData = {
        columnKey: "teamName",
        field: "teamName",
        order: undefined
      };
      const nameSortData = {
        columnKey: "name",
        field: "name",
        order: undefined
      };

      if (!Array.isArray(sorter)) {
        if (sorter.columnKey === "teamName") {
          return setSortWithColumn([nameSortData, sorter]);
        }
        return setSortWithColumn([sorter, teamSortData]);
      }
      let newSortData = [];

      let nameSort = sorter.find((item: any) => {
        return item.columnKey === "name";
      });
      newSortData[0] = nameSort ? nameSort : nameSortData;

      let teamSort = sorter.find((item: any) => {
        return item.columnKey === "teamName";
      });
      newSortData[1] = teamSort ? teamSort : teamSortData;

      return setSortWithColumn(newSortData);
    }
  };

  return (
    <ComponentWrapper className={classes.wrapper}>
      <div className={classes.buttonHolder}>
        <Button
          icon={<DownloadOutlined />}
          className={classes.downloadButton}
          onClick={handleMemberDownload}
          loading={downloading}
        >
          {t("Download Members")}
        </Button>
        {activeTab === "ownerTeam" && (
          <Button
            icon={<FilePlusIcon />}
            onClick={toggleModal}
            className={classes.button}
          >
            {" "}
            {t("Invite")}
          </Button>
        )}
      </div>
      <Tabs
        className={classes.tabStyle}
        onChange={handleTabChange}
        activeKey={activeTab}
      >
        <TabPane tab={t("Owner team")} key="ownerTeam" />
        <TabPane tab={t("Branch/Department")} key="branch" />
      </Tabs>
      <TableComponent
        columns={columns}
        loading={loading}
        rowKey="index"
        dataSource={memberList}
        className={classes.tableClassName}
        pagination={memberList.length > 0}
        currentPage={pagination.page}
        pageSize={pagination.pageSize}
        handleSizeChange={handleSizeChange}
        totalData={totalNumber}
        handlePaginationChange={(page: number, pageSize?: number) => {
          return setPagination({ page, pageSize });
        }}
        onChange={handleSortChange}
        sticky
      />
    </ComponentWrapper>
  );
};
