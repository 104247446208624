import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import {
  API,
  Button,
  CheckboxComponent,
  NotFound,
  Typography
} from "shared-components";

import { ComponentWrapper, HeaderWrapper } from "../../components";
import { AgreementInformation } from "./report/AgreementInformation";
import { Header } from "./report/Header";
import { PatientInformation } from "./report/PatientInformation";
import { Sender } from "./report/Sender";
import { CaseData, SenderData } from "./types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    pageWrapper: {
      position: "absolute",
      top: 0,
      width: "100%",
      background: theme.background,
      paddingBottom: 26,
      height: "100vh"
    },
    container: {
      background: theme.background
    },
    containWrapper: {
      background: theme.lightBg,
      padding: "0px 10px 8px",
      margin: "42px 80px 0px 20px"
    },
    formWrapper: {
      fontFamily: theme.brandFont,
      color: theme.gray6
    },
    buttonStyle: {
      marginLeft: 18,
      width: 160,
      height: 28,
      fontSize: 14,
      lineHeight: "17px",
      letteSpacing: "0.84px",
      margin: {
        top: 15,
        bottom: 11
      }
    },
    bottomContents: {
      display: "flex",
      justifyContent: "center"
    },
    checkBoxTextStyle: {
      fontFamily: theme.brandFont,
      color: theme.gray6,
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0px",
      marginLeft: 4,
      position: "relative",
      top: 3,
      paddingBottom: 26
    },
    doneBlock: {
      height: 244,
      fontFamily: theme.brandFont,
      color: theme.textBlue,
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0px",
      marginTop: 35,
      marginLeft: 10
    },
    checkWrapper: {
      width: "fit-content",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer"
    }
  };
});

export const PatientPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id }: any = useParams();

  const [done, setDone] = useState(false);
  const [sendMail, setSendMail] = useState(false);

  const [caseState, setCaseState] = useState({
    answer: "agree",
    patient_id: ""
  } as CaseData);
  const [senderData, setSenderData] = useState({} as SenderData);
  const [notFound, setNotFound] = useState(false as boolean);

  const fetchData = async () => {
    try {
      const res = await API.get(`agreement/${id}`);
      setCaseState({
        patient_id: res.data.Cases.patientId,
        patient_comment: res.data.Cases.patientComment,
        patient_email: res.data.Cases.patientEmail,
        patient_name: res.data.Cases.patientName,
        id: res.data.Cases.id,
        team_name: res.data.team.name,
        created_at: res.data.Cases.agreementRequestedAt,
        agreement_answered_at: res.data.Cases.agreementAnsweredAt,
        agreement_status: res.data.Cases.agreementStatus,
        case_number: res.data.Cases.caseNumber,
        myBookmark: false,
        marked: false,
        pharmacist: "",
        supervisory_pharmacist: "",
        sex: res.data.Cases.sex,
        birthday: res.data.Cases.birthday,
        age: res.data.Cases.age,
        occupation: res.data.Cases.occupation,
        answer: res.data.Cases.answer,
        agreement_description: res.data.Cases.agreementDescription,
        agreement_send: res.data.Cases.agreementSend,
        case_status: res.data.Cases.caseStatus
      });
      setSenderData({
        address: res.data.team.address,
        fax: res.data.team.fax,
        name: `${res.data.team.organization.name} ${res.data.team.name}`,
        phone: res.data.team.phone,
        pharmacist_name: res.data.Cases.agreementPharmacistUserName
      });
    } catch (error) {
      if (error.response.data.error.message === "agreement not found") {
        setNotFound(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    await API.post(`agreement/${id}`, {
      patientComment: caseState.patient_comment,
      answer: caseState.answer,
      sendMail: sendMail
    });
    setDone(true);
  };

  const handleCheck = () => {
    setCaseState({
      ...caseState,
      send_mail: !sendMail
    });
    setSendMail(!sendMail);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (!name) {
      return;
    }
    setCaseState({ ...caseState, [name]: value });
  };

  if (notFound) {
    return <NotFound />;
  }

  return (
    <div className={classes.pageWrapper}>
      <HeaderWrapper isPatient />
      <ComponentWrapper noOverFlow className={classes.container}>
        <Form onFinish={handleSubmit}>
          <div className={classes.containWrapper}>
            <div className={classes.formWrapper}>
              <Header caseState={caseState} isPatient typeAgreement />

              {!done ? (
                <>
                  <PatientInformation
                    patientPage
                    caseState={caseState}
                    agreementStatus={caseState.agreement_status}
                  />
                  <AgreementInformation
                    caseState={caseState}
                    agreementStatus={caseState.agreement_status}
                    isPatient
                    handleChange={handleChange}
                  />
                  <Sender
                    agreementStatus={caseState.agreement_status}
                    senderData={senderData}
                  />
                </>
              ) : (
                <Typography className={classes.doneBlock}>
                  {t(
                    "Agreement has been sent. Thank you for your cooperation."
                  )}
                </Typography>
              )}
            </div>
          </div>

          {!done && (
            <>
              <div style={{ width: "100%" }}>
                <div className={classes.bottomContents}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className={classes.buttonStyle}
                  >
                    {t("Submit agreement")}
                  </Button>
                </div>
                <div className={classes.bottomContents}>
                  <div onClick={handleCheck} className={classes.checkWrapper}>
                    <CheckboxComponent
                      onClick={handleCheck}
                      checked={caseState.send_mail}
                    />
                    <Typography className={classes.checkBoxTextStyle}>
                      {t(
                        "Send agreement and contact information to my email address"
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>
      </ComponentWrapper>
    </div>
  );
};
