import { Button, Select } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Alert, API, AuthContext, BorderBoxWrapper } from "shared-components";
import { changeLanguage } from "shared-components/src/config/i18n";

import { ComponentWrapper } from "../../components/molecules/ComponentWrapper";

const useStyles = createUseStyles((theme: any) => {
  return {
    text: {
      color: theme.textDarkGrey,
      fontSize: 14,
      lineHeight: "31px"
    },
    containerBottom: {
      position: "absolute",
      bottom: "0px",
      borderTop: `1px solid ${theme.colorGrey}`,
      width: "100%",
      padding: "17px 0px",
      textAlign: "center"
    },
    mainContainer: {
      position: "relative",
      height: 360
    },
    saveButton: {
      background: theme.primary,
      color: "white",
      letterSpacing: "0.72px",
      fontSize: "12px",
      lineHeight: "26px",
      minWidth: "145px",
      borderRadius: "2px"
    },
    languageSelect: {
      display: "inline",
      float: "right",
      "& .ant-select": {
        minWidth: "200px !important"
      },
      "& .ant-select-selector": {
        border: `1px solid ${theme.colorGrey} !important`,
        marginBottom: 4
      }
    },
    row: {
      marginTop: 40,
      marginBottom: 30,
      "& .ant-switch": {
        backgroundColor: "white !important",
        border: `1px solid ${theme.textDarkGrey}`,
        height: "24px !important",
        width: "60px !important"
      }
    },
    contents: {
      padding: "0px 35px 0px 36px"
    }
  };
});

interface SettingsProps {
  language: string;
}
const Settings = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uid, profile, setProfile } = useContext(AuthContext);
  const { Option } = Select;
  const [languageOpen, setLanguageOpen] = useState(false);

  const [state, setState] = useState({
    language: profile.language
  } as SettingsProps);

  const [loading, setLoading] = useState(false as boolean);

  const languageOptions = [
    {
      value: "ja",
      label: "日本語"
    },
    {
      value: "en",
      label: "English"
    }
  ];

  const handleSelectChange = (value: string, name: string) => {
    setState({ ...state, [name]: value });
  };

  const handleSave = async () => {
    if (profile.language !== state.language) {
      setLoading(true);
      const formData = new FormData();
      formData.append("language", state.language);
      try {
        const response = await API.put(`users/${uid}`, formData);
        setProfile({ ...profile, language: response.data.language });
        changeLanguage(state.language);
        Alert("success", "success", "Language updated", t);
      } catch (error) {
        Alert("error", "error", "unable to update language", t);
      }
      setLoading(false);
    } else changeLanguage(state.language);
  };

  return (
    <ComponentWrapper>
      <BorderBoxWrapper
        heading={t("Configuration")}
        width={420}
        headingSpacing="36px 16px 39px 16px"
        paddingTop="40px"
      >
        <div className={classes.mainContainer}>
          <div className={classes.contents}>
            <span className={classes.text}>{t("Language Settings")}</span>
            <div
              className={classes.languageSelect}
              onMouseOver={() => {
                return setLanguageOpen(true);
              }}
              onMouseLeave={() => {
                return setLanguageOpen(false);
              }}
            >
              <Select
                value={state.language}
                onChange={(value: string) => {
                  handleSelectChange(value, "language");
                  setLanguageOpen(false);
                }}
                open={languageOpen}
              >
                {languageOptions.map((language: any, index: number) => {
                  return (
                    <Option key={index} value={language.value}>
                      {language.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className={classes.containerBottom}>
            <Button
              type="primary"
              onClick={handleSave}
              className={classes.saveButton}
              loading={loading}
            >
              {t("Save")}
            </Button>
          </div>
        </div>
      </BorderBoxWrapper>
    </ComponentWrapper>
  );
};

export { Settings };
