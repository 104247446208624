import { Col, Form, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { Button, TextField } from "shared-components";

interface Props {
  onSubmit: (FormItems: FormItems) => void;
  forgotPassword?: () => void;
  error?: string;
  loading?: boolean;
}

interface FormItems {
  email: string;
  password: string;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      width: 243,
      "& div:first-child": {
        color: theme.yellow5
      },
      "& > :first-child": {
        marginBottom: 15
      },
      "& .ant-form-item-has-error input": {
        color: "#000"
      }
    },
    row: {
      marginTop: 33
    },
    buttonDiv: {
      marginTop: 26
    },
    button: {
      borderRadius: 26,
      width: 145,
      height: 28,
      background: theme.yellow5,
      color: theme.textPrimary
    },
    textField: {
      background: "transparent",
      "& input": {
        background: "transparent",
        color: "#fff"
      },
      "& input:focus": {
        color: "#000"
      },
      border: "1px solid #FFFFFF"
    },
    forgotPassword: {
      color: theme.textWhite,
      textAlign: "center"
    },
    termsAndPrivacy: {
      marginTop: 80,
      maxWidth: 250,
      color: theme.textWhite,
      "& a": {
        color: theme.yellow5,
        textDecoration: "underline",
        "&:hover": {
          color: theme.textYellow
        }
      }
    }
  };
});

export const SignUpComponent: React.FC<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState({
    email: "",
    password: "",
    password1: ""
  });

  const handleSignUp = async () => {
    props.onSubmit({ ...state });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(s => {
      return { ...s, [name]: value };
    });
  };

  return (
    <Row gutter={8} className={classes.row}>
      <Col span={24}>
        <Form onFinish={handleSignUp}>
          <div className={classes.formItem}>
            <Form.Item
              id="email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("Not a valid email")
                },
                {
                  required: true,
                  message: t("Required")
                },
                {
                  max: 60,
                  message: t("Maximum limit has exceeded")
                }
              ]}
            >
              <TextField
                name="email"
                className={classes.textField}
                placeholder={t("Mail address")}
                onChange={handleChange}
              />
            </Form.Item>
          </div>

          <div className={classes.formItem}>
            <Form.Item
              id="password"
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Required")
                },
                {
                  min: 8,
                  message: t("Must be at least 8 characters")
                }
              ]}
            >
              <TextField
                name="password"
                className={classes.textField}
                placeholder={t("Password")}
                type="password"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={classes.formItem}>
            <Form.Item
              name="password1"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("Required")
                },
                ({ getFieldValue }: any) => {
                  return {
                    validator(rule: any, value: string) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t("Password did not matched"));
                    }
                  };
                }
              ]}
            >
              <TextField
                name="password1"
                className={classes.textField}
                placeholder={t("Confirm Password")}
                type="password"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={classes.buttonDiv}>
            <Form.Item>
              <Button
                size="small"
                htmlType="submit"
                loading={props.loading}
                type="primary"
                className={classes.button}
              >
                {t("Sign Up")}
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div className={classes.termsAndPrivacy}>
          {t("By signing up, you agree to the ")}
          <Link
            to={"//bon-bon.co.jp/porous-terms-and-conditions/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Porous Terms")}
          </Link>{" "}
          {t("of Service, ")}
          <Link
            to={"//bon-bon.co.jp/privacy-policy/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Privacy policy")}
          </Link>{" "}
          {t("and to receive emails, newsletters, and updates sent by Porous.")}
        </div>
      </Col>
    </Row>
  );
};
