import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider } from "react-jss";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  AuthProvider,
  Forbidden,
  NotFound,
  RECAPTCHA_SITE_KEY,
  theme
} from "shared-components";

import { EmailConfirmation, PasswordReset, SignIn, SignUp } from "./pages/auth";
import { PrivateRoute, RestrictedRoute } from "./routes";
import { Home } from "./routes/Home";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <RestrictedRoute
                exact
                path="/email-confirmation"
                component={EmailConfirmation}
              />
              <Route
                exact
                path="/forgot-password"
                component={() => {
                  return (
                    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                      <PasswordReset />
                    </GoogleReCaptchaProvider>
                  );
                }}
              />
              <RestrictedRoute
                exact
                path="/sign-in"
                component={() => {
                  return (
                    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                      <SignIn />
                    </GoogleReCaptchaProvider>
                  );
                }}
              />
              <PrivateRoute exact path="/403" component={Forbidden} />
              <RestrictedRoute
                exact
                path="/sign-up"
                component={() => {
                  return (
                    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                      <SignUp />
                    </GoogleReCaptchaProvider>
                  );
                }}
              />
              <Route path="/" component={Home} />
              <Route path="*" component={NotFound} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
