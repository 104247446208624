import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      marginTop: 28,
      marginLeft: 37,
      fontFamily: "Noto Sans JP"
    },
    nameField: {
      marginBottom: 48
    },
    typeField: {
      marginBottom: 41
    },
    field: {
      marginBottom: 34
    },
    title: {
      letterSpacing: 0,
      color: theme.primary,
      fontSize: 12,
      marginBottom: 4
    },
    description: {
      letterSpacing: 0,
      fontSize: 16,
      color: theme.gray7,
      marginTop: 4
    }
  };
});

interface Props {
  organizationData: OrganizationInterface;
}

export const OrganizationComponent = ({ organizationData }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.componentWrapper}>
      {organizationData.name && (
        <div className={classes.nameField}>
          <Typography className={classes.title}>{t("Name1")}</Typography>
          <Typography className={classes.description}>
            {organizationData.name}
          </Typography>
        </div>
      )}

      {organizationData.type && (
        <div className={classes.typeField}>
          <Typography className={classes.title}>{t("Type")}</Typography>
          <Typography className={classes.description}>
            {organizationData.type === "hospital"
              ? t("Hospital")
              : t("Pharmacy")}
          </Typography>
        </div>
      )}

      {organizationData.address && (
        <div className={classes.field}>
          <Typography className={classes.title}>{t("Address")}</Typography>
          <Typography className={classes.description}>
            {organizationData.address}
          </Typography>
        </div>
      )}

      {organizationData.phone && (
        <div className={classes.field}>
          <Typography className={classes.title}>{t("Phone")}</Typography>
          <Typography className={classes.description}>
            {organizationData.phone}
          </Typography>
        </div>
      )}

      {organizationData.fax && (
        <div className={classes.field}>
          <Typography className={classes.title}>{t("FAX")}</Typography>
          <Typography className={classes.description}>
            {organizationData.fax}
          </Typography>
        </div>
      )}
    </div>
  );
};
