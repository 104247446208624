import { Col, Modal, Row } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  Button,
  DatePickerComponent,
  debounceFunction,
  exceptionHandler,
  Search,
  TableComponent
} from "shared-components";

import { AgreementTags } from "../../components/atoms/AgreementTag";
import { CaseTags } from "../../components/atoms/CaseTag";
import { actionValue, statusValue } from "./actionValueMap";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalBox: {
      height: 680,
      width: "80% !important",
      "& .ant-modal-content": {
        height: "100%",
        "& .ant-modal-body": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50
        }
      }
    },
    table: {
      marginTop: 50,
      width: "100%",
      "& .ant-table": {
        overflowX: "auto"
      },
      "& .ant-table-cell": {
        paddingTop: 10,
        paddingBottom: 10,
        whiteSpace: "nowrap"
      }
    },
    createButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      marginTop: 50,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    filterColumns: {
      marginBottom: 10
    },

    tagStyle: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

interface Props {
  open: boolean;
  onClose?: any;
  cases: CasesData[];
  setCases: React.Dispatch<React.SetStateAction<CasesData[]>>;
  form: RequestFormData;
  latestActivity: DiagnosisActivity;
}

export const SelectCaseModal = ({
  open,
  onClose,
  cases,
  setCases,
  form,
  latestActivity
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false as boolean);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 5
  } as CaseFilters);

  const [count, setCount] = useState(0 as number);
  const [allCases, setAllCases] = useState([] as CasesData[]);
  const [selectedCases, setSelectedCases] = useState([] as CasesData[]);

  const { defaultTeam } = useContext(AuthContext);

  const disabledToDate = (current: Moment) => {
    return current && current < moment(filters.from);
  };

  const disabledFromDate = (current: Moment) => {
    return current && current > moment(filters.to);
  };

  const handleFilterChange = (
    name: string,
    value: string | number | boolean
  ) => {
    setLoading(true);
    const newFilters = { ...filters, [name]: value };
    if (name !== "page") {
      newFilters.page = 1;
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    if (defaultTeam) {
      debounceFunction(() => {
        return getCases(filters);
      });
    } else {
      setLoading(false);
    }
  }, [defaultTeam, filters]);

  const getCases = async (filters: CaseFilters) => {
    setLoading(true);
    try {
      const response: any = await API.get(`cases`, {
        params: {
          teamId: defaultTeam,
          from: (filters && filters.from) || undefined,
          to: (filters && filters.to) || undefined,
          search: (filters && filters.search) || undefined,
          page: filters.page,
          pageSize: filters.pageSize,
          status: "sending",
          noClient: true
        }
      });
      setLoading(false);
      if (!response.data) {
        setAllCases([]);
        setCount(0);
        return;
      }
      setAllCases(response.data.cases);
      setCount(response.data.count);
    } catch (error) {
      setLoading(false);
      setAllCases([]);
      exceptionHandler(error, t);
    }
  };

  const columns = [
    {
      title: t("Case number"),
      dataIndex: "caseNumber",
      key: "caseNumber"
    },
    {
      title: t("Patient"),
      dataIndex: "patient",
      key: "patient"
    },
    {
      title: t("Agreement"),
      dataIndex: "agreementStatus",
      key: "agreementStatus",
      render: (text: string) => {
        return (
          <div className={classes.tagStyle}>
            <AgreementTags type={text} />
          </div>
        );
      }
    },
    {
      title: t("Status"),
      dataIndex: "caseStatus",
      key: "caseStatus",
      render: (text: string) => {
        return (
          <div className={classes.tagStyle}>
            <CaseTags type={text} />
          </div>
        );
      }
    },
    {
      title: t("Pharmacist"),
      dataIndex: "pharmacist",
      key: "pharmacist"
    },
    {
      title: t("Physician"),
      dataIndex: "physician",
      key: "physician"
    },
    {
      title: t("Created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, data: any) => {
        return moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss");
      }
    },
    {
      title: t("Updated"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string, data: any) => {
        return moment(data.updatedAt).format("YYYY/MM/DD HH:mm:ss");
      }
    }
  ];

  useEffect(() => {
    if (cases !== selectedCases) {
      setSelectedCases(cases);
    }
  }, [cases]);

  const rowSelection = {
    onSelect: (record: CasesData, selected: boolean) => {
      if (selected) {
        setSelectedCases([...selectedCases, record]);
      } else {
        setSelectedCases(
          selectedCases.filter((caseData: CasesData) => {
            return caseData.id !== record.id;
          })
        );
      }
    },
    selectedRowKeys: selectedCases.map((caseData: CasesData) => {
      return caseData.id;
    }),
    onSelectAll: (
      selected: boolean,
      selectedRow: CasesData[],
      changeRows: CasesData[]
    ) => {
      if (selected) {
        const newSelected = changeRows.filter((row: CasesData) => {
          return !selectedCases.some((selectedRow: CasesData) => {
            return selectedRow.id === row.id;
          });
        });
        setSelectedCases([...selectedCases, ...newSelected]);
      } else {
        setSelectedCases(
          selectedCases.filter((caseData: CasesData) => {
            return !changeRows.some((selectedRow: CasesData) => {
              return selectedRow.id === caseData.id;
            });
          })
        );
      }
    }
  };

  const handleDone = async () => {
    const oldCases = cases;
    setCases(selectedCases);
    onClose();
    if (
      form.status === statusValue.Editing &&
      latestActivity &&
      latestActivity.action === actionValue.edit_request
    ) {
      try {
        await API.put(`requests/${form.id}`, {
          ...form,
          caseIds:
            selectedCases && selectedCases.length > 0
              ? selectedCases.map((caseData: CasesData) => {
                  return caseData.id;
                })
              : undefined
        });
      } catch (error) {
        setCases(oldCases);
        exceptionHandler(error, t);
      }
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={open}
      mask={false}
      footer={null}
      className={classes.modalBox}
    >
      <Row gutter={15}>
        <Col lg={12} xs={24} className={classes.filterColumns}>
          <Search
            name="search"
            placeholder={t("Hospital name, Doctor name, etc...")}
            onChange={handleFilterChange}
          />
        </Col>
        <Col lg={6} xs={24} className={classes.filterColumns}>
          <DatePickerComponent
            disabledDate={disabledFromDate}
            name="from"
            placeholder={t("From Date")}
            onChange={handleFilterChange}
          />
        </Col>

        <Col lg={6} xs={24} className={classes.filterColumns}>
          <DatePickerComponent
            name="to"
            disabledDate={disabledToDate}
            placeholder={t("To Date")}
            onChange={handleFilterChange}
          />
        </Col>
      </Row>
      <TableComponent
        loading={loading}
        className={classes.table}
        columns={columns}
        dataSource={allCases}
        rowSelection={{
          type: "checkbox",
          ...rowSelection
        }}
        pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        totalData={count}
        handlePaginationChange={(page: number) => {
          return handleFilterChange("page", page);
        }}
        rowKey={"id"}
        handleRow={(record: CasesData) => {
          return {
            onClick() {
              const isSelected =
                selectedCases
                  .map(cases => {
                    return cases.id;
                  })
                  .indexOf(record.id) > -1;
              rowSelection.onSelect(record, !isSelected);
            }
          };
        }}
      />

      <Button
        type="primary"
        size="small"
        onClick={handleDone}
        className={classes.createButton}
      >
        {t("Done")}{" "}
      </Button>
    </Modal>
  );
};
