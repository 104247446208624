import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { AutoComplete, Form, Input, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  Button,
  DeleteConfirm,
  exceptionHandler,
  Loader,
  Modal,
  Typography
} from "shared-components";

const { Option } = Select;

let useStyles = createUseStyles((theme: any) => {
  return {
    title: {
      fontSize: 14,
      lineHeight: "17px",
      letterSpacing: 0,
      color: theme.gray7,
      margin: {
        top: 17,
        left: 20
      }
    },
    plusIconContainer: {
      display: "flex",
      justifyContent: "center",
      margin: "20px 0px 24px"
    },
    plusIcon: {
      cursor: "pointer",
      fontSize: 24
    },
    tagsContainer: {
      marginLeft: 20,
      display: "flex",
      flexWrap: "wrap",
      "& .ant-tag": {
        margin: {
          bottom: 27,
          left: 20
        },
        fontSize: 14,
        lineHeight: "22px",
        letterSpacing: 0,
        cursor: "pointer"
      }
    },
    modalContainer: {
      flexDirection: "column",
      "& .ant-modal-body": {
        minHeight: 450,
        display: "flex",
        flexDirection: "column"
      }
    },
    modalTitleStyle: {
      color: theme.primary,
      fontSize: 24,
      lineHeight: "29px",
      letterSpacing: 0,
      marginBottom: 28
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      textAlign: "left",
      marginTop: 89,
      margin: "auto",
      "& .ant-form-item": {
        marginBottom: 14
      },
      "& .ant-select-selector": {
        width: 325
      },
      "& .ant-form-item .ant-select, .ant-form-item .ant-cascader-picker": {
        width: 325
      }
    },
    labelStyle: {
      color: theme.primary,
      marginBottom: 12,
      fontSize: 12,
      lineHeight: "14px",
      letterSpacing: 0
    },
    buttonStyle: {
      width: 145,
      height: 28,
      fontSize: 12,
      lineHeight: "12px",
      letterSpacing: "0.72px",
      marginTop: 10
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      marginTop: 40
    },
    tagWrapper: {
      display: "flex",
      position: "relative"
    },
    tagTextStyle: {
      marginRight: 14
    },
    crossStyle: {
      fontSize: 10,
      color: theme.darkBackground,
      position: "absolute",
      top: 7,
      right: 7
    },
    colorSelectField: {
      "& .ant-tag": { marginRight: 0 }
    },
    colorTagStyle: {
      display: "flex",
      alignItems: "center",
      width: "90px",
      height: "100%"
    },
    colorBlockStyle: {
      width: "100%",
      height: "16px",
      display: "block"
    }
  };
});

interface AnalysisTagsInterface {
  id: number;
  tagName: string;
  color: string;
}

interface Props {
  reportID?: number | string;
}

export const AnalysisTags = ({ reportID }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false as boolean);
  const [editTag, setEditTag] = useState("" as string);
  const [loading, setLoading] = useState(true as boolean);
  const [saving, setSaving] = useState(false as boolean);

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({} as AnalysisTagsInterface);

  const [tagsList, setTagsList] = useState([] as AnalysisTagsInterface[]);
  const [teamTagsList, setTeamTagList] = useState(
    [] as AnalysisTagsInterface[]
  );

  const colorOptions = [
    { label: "magenta", value: "#EB2F96" },
    { label: "red", value: "#F5222D" },
    { label: "volcano", value: "#FA541C" },
    { label: "orange", value: "#FA8C16" },
    { label: "gold", value: "#FAAD14" },
    { label: "lime", value: "#A0D911" },
    { label: "green", value: "#52C41A" },
    { label: "cyan", value: "#13C2C2" },
    { label: "blue", value: "#1890FF" },
    { label: "geekblue", value: "#2F54EB" },
    { label: "purple", value: "#722ED1" }
  ];

  useEffect(() => {
    if (!saving) {
      getTagsList();
    }
  }, [saving]);

  const getTagsList = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(`reports/${reportID}/analysis-tags`);
      setLoading(false);
      setTagsList(response.data.reportTags);
      if (response.data.teamTags) setTeamTagList(response.data.teamTags);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    form.resetFields();
    setFormValues({} as AnalysisTagsInterface);
  };

  const addAnalysisTag = () => {
    form.resetFields();
    form.setFieldsValue({
      tagName: undefined
    });
    setEditTag("");
    setShowModal(true);
  };

  const editAnalysisTag = (data: AnalysisTagsInterface) => {
    setEditTag(`${data.id}`);
    setFormValues({
      id: data.id,
      tagName: data.tagName,
      color: data.color
    });
    form.setFieldsValue({
      tagName: data.tagName,
      color: data.color
    });
    setShowModal(true);
  };

  const getColor = (colorCode: string) => {
    const color = colorOptions.find((item: any) => {
      return item.value === colorCode;
    });
    return color?.label;
  };

  const handleDeleteTag = async (tagID: number) => {
    DeleteConfirm(
      async () => {
        try {
          await API.delete(`analysis-tags/${tagID}`);
          let items = tagsList;
          items = items.filter(item => {
            return item.id !== tagID;
          });
          setTagsList(items);
          Alert("success", "success", t("Tag is deleted."), t);
        } catch (error) {
          exceptionHandler(error, t);
        }
      },
      "Are you sure you want to delete?",
      true,
      ""
    );
  };

  const handleTagChange = (value: any) => {
    const data = teamTagsList.find((item: any) => {
      return item.tagName === value;
    });

    if (data) {
      if (editTag !== "")
        setFormValues({ ...formValues, tagName: value, color: data.color });
      else
        setFormValues({
          ...formValues,
          id: data.id,
          tagName: value,
          color: data.color
        });
      form.setFieldsValue({ tagName: data.tagName, color: data.color });
    } else {
      if (editTag !== "") setFormValues({ ...formValues, tagName: value });
      else
        setFormValues({ ...formValues, id: Number(undefined), tagName: value });
      form.setFieldsValue({ tagName: value });
    }
  };

  const handleSelectColor = (value: any) => {
    setFormValues({ ...formValues, color: value });
    form.setFieldsValue({
      color: value
    });
  };

  const handleSave = async (tagID: any) => {
    setSaving(true);
    try {
      const response =
        editTag !== ""
          ? await API.put(`reports/${tagID}/analysis-tags`, {
              ...formValues
            })
          : await API.post(`reports/${reportID}/analysis-tags`, {
              ...formValues
            });
      if (response && response.data) {
        setSaving(false);
        setShowModal(false);
        setFormValues({} as AnalysisTagsInterface);
      }
    } catch (error) {
      setSaving(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <div>
      <div className={classes.title}>{t("Analysis Tags")}</div>
      <div className={classes.plusIconContainer}>
        <PlusCircleOutlined
          className={classes.plusIcon}
          onClick={addAnalysisTag}
        />
      </div>

      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <div className={classes.tagsContainer}>
          {tagsList.map((item: any, key: number) => {
            return (
              <div key={key} className={classes.tagWrapper}>
                <Tag
                  color={getColor(item.color) || "magenta"}
                  onClick={() => {
                    return editAnalysisTag(item);
                  }}
                >
                  <span className={classes.tagTextStyle}>{item.tagName}</span>
                </Tag>
                <CloseOutlined
                  className={classes.crossStyle}
                  onClick={() => {
                    return handleDeleteTag(item.id);
                  }}
                />
              </div>
            );
          })}
        </div>
      )}

      <Modal
        className={classes.modalContainer}
        visible={showModal}
        onCancel={closeModal}
        width="625px"
        mask={true}
      >
        <Form className={classes.formWrapper} onFinish={handleSave} form={form}>
          <Typography className={classes.modalTitleStyle}>
            {editTag !== ""
              ? t("Edit Analysis Tags")
              : t("Create Analysis Tags")}
          </Typography>
          <Form.Item
            id="tagName"
            name="tagName"
            rules={[
              {
                required: true,
                message: t("Required")
              },
              {
                max: 20,
                message: t("Tag should have less than 20 characters")
              }
            ]}
          >
            <Input.Group>
              <Typography className={classes.labelStyle}>
                {t("Tag Name")}
              </Typography>
              <AutoComplete
                options={teamTagsList.map((item: any) => {
                  return {
                    label: (
                      <Tag color={getColor(item.color)}>{item.tagName}</Tag>
                    ),
                    value: item.tagName
                  };
                })}
                id="tagName"
                value={formValues.tagName}
                onChange={handleTagChange}
              />
            </Input.Group>
          </Form.Item>

          <Form.Item
            id="color"
            name="color"
            rules={[
              {
                required: true,
                message: t("Required")
              }
            ]}
          >
            <>
              <Typography className={classes.labelStyle}>
                {t("Color")}
              </Typography>
              <Select
                id="color"
                onChange={handleSelectColor}
                value={formValues.color}
                className={classes.colorSelectField}
              >
                {colorOptions.map((option: any) => {
                  return (
                    <Option key={option.value} value={option.value}>
                      <Tag
                        color={option.label}
                        className={classes.colorTagStyle}
                      >
                        <span
                          style={{ background: option.value }}
                          className={classes.colorBlockStyle}
                        />
                      </Tag>
                    </Option>
                  );
                })}
              </Select>
            </>
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className={classes.buttonStyle}
              loading={saving}
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
