import { Form } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { Alert, API, Button, exceptionHandler } from "shared-components";

import { CaseData, Member } from "../types/case";
import { AgreementBearer } from "./AgreementBearer";
import { AgreementInformation } from "./AgreementInformation";
import { Header } from "./Header";
import { PatientInformation } from "./PatientInformation";

const useStyles = createUseStyles((theme: any) => {
  return {
    reportContainer: {
      padding: "0px 10px 17px",
      "& .ant-picker": {
        borderColor: theme.colorGrey
      }
    },
    submitButtonWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: 24
    },
    submitButton: {
      height: 32,
      fontSize: 14,
      lineHeight: 0,
      letterSpacing: "0.84px"
    }
  };
});

interface Props {
  caseState: CaseData;
  lastCaseState: any;
  setLastCaseState: any;
  allMembers: Member[];
  setCaseState: any;
  supervisorPharmacist: string | undefined;
  setSaving: any;
  viewAsTeamOwner?: boolean;
}

export const PatientAgreement = ({
  caseState,
  setCaseState,
  lastCaseState,
  setLastCaseState,
  allMembers,
  supervisorPharmacist,
  setSaving,
  viewAsTeamOwner
}: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false as boolean);
  const { id }: any = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      patient_name: caseState.patient_name,
      patient_email: caseState.patient_email
    });
  }, [caseState.patient_name, caseState.patient_email]);

  const handleCompleteWithoutSend = async () => {
    if (!caseState.patient_name) {
      return;
    }
    setLoading(true);
    try {
      await API.post(`cases/${id}/agreement`, {
        answer: caseState.answer,
        patientName: caseState.patient_name
      });
      Alert("success", "success", t("Agreement form is saved"), t);
      setCaseState({
        ...caseState,
        agreement_status: caseState.answer,
        agreement_answered_at: moment()
      });
    } catch (error) {
      exceptionHandler(error);
    }
    setLoading(false);
  };

  const handleSaveCase = async () => {
    try {
      let notContinue = true;
      let updatedField: any;
      let value;

      Object.entries(caseState).forEach(item => {
        Object.entries(lastCaseState).forEach(lastItem => {
          if (lastItem[0] === item[0]) {
            if (lastItem[1] !== item[1]) {
              notContinue = false;
              updatedField = lastItem[0];
              value = item[1];
            }
          }
        });
      });
      if (notContinue) {
        return;
      }
      setSaving(true);
      await API.put(`cases/${id}`, {
        caseNumber: caseState.case_number,
        agreementPharmacistUserId: caseState.pharmacist,
        patientId: caseState.patient_id,
        patientName: caseState.patient_name,
        sex: caseState.sex,
        birthday: caseState.birthday,
        age: Number(caseState.age),
        occupation: caseState.occupation,
        answer: caseState.answer,
        patientComment: caseState.patient_comment,
        agreementDescription: caseState.agreement_description,
        agreementSend: caseState.agreement_send
        // patientEmail: caseState.patient_email
      });
      setLastCaseState({ ...lastCaseState, [updatedField]: value });
      setSaving(false);
    } catch (error) {
      setSaving(false);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (!name) {
      return;
    }
    setCaseState({ ...caseState, [name]: value });
  };

  const handleChangeDate = (time: any) => {
    if (caseState.age) {
      setCaseState({ ...caseState, birthday: time });
    } else {
      if (caseState.birthday && caseState.age === 0) {
        setCaseState({ ...caseState, age: String(caseState.age) });
        return;
      }
      const birthDate = moment(time, "YYYY-MM-DD");
      const today = moment();
      const ageDiff = today.diff(birthDate, "y");
      setCaseState({ ...caseState, age: ageDiff, birthday: time });
    }
  };
  const handleWithDrawAgreement = async () => {
    try {
      await API.post(`cases/${id}/agreement/withdraw`);
      Alert("success", "success", t("Agreement form has been withdrawal"), t);
      setCaseState({ ...caseState, agreement_status: "disagree" });
    } catch (error) {
      exceptionHandler(error);
    }
  };

  return (
    <Form
      className={classes.reportContainer}
      onBlur={handleSaveCase}
      form={form}
    >
      <Header
        caseState={caseState}
        agreementStatus={caseState.agreement_status}
        typeAgreement
      />
      <AgreementBearer
        blockTitleName="Agreement Bearer"
        selectFieldName="pharmacist"
        members={allMembers}
        pharmacist={caseState.pharmacist}
        handleChange={handleChange}
        isEdit={caseState.agreement_status === "creating"}
        supervisoryPharmacist={supervisorPharmacist}
        disabled={viewAsTeamOwner && true}
      />
      <PatientInformation
        caseState={caseState}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        agreementStatus={caseState.agreement_status}
        viewAsTeamOwner={viewAsTeamOwner}
      />
      <AgreementInformation
        caseState={caseState}
        handleChange={handleChange}
        agreementStatus={caseState.agreement_status}
        handleWithDrawAgreement={handleWithDrawAgreement}
        viewAsTeamOwner={viewAsTeamOwner}
      />
      {caseState.agreement_status === "creating" && (
        <div className={classes.submitButtonWrapper}>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={handleCompleteWithoutSend}
            disabled={viewAsTeamOwner}
            className={classes.submitButton}
          >
            {t("Complete Agreement")}
          </Button>
        </div>
      )}
    </Form>
  );
};
