import { Button, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API, AuthContext } from "shared-components";

import { ComponentWrapper } from "../../components";
import { EditOrganizationModal } from "../organization/EditOrganizationModal";
import { BranchComponent } from "./BranchComponent";
import { OrganizationComponent } from "./OrganizationComponent";

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      background: theme.white,
      height: "80vh",
      overflowY: "auto",
      overflowX: "hidden"
    },
    container: {
      // margin: "30px 24px"
    },
    tabs: {
      width: "fit-content",
      // marginTop: 50,
      "& .ant-tabs-nav": {
        marginBottom: "0px",
        "& :before": {
          borderBottom: "1px solid red !important"
        },
        "& .ant-tabs-nav-list": {
          "& .ant-tabs-tab:last-of-type": {
            marginLeft: 32
          }
        }
      },
      "& .ant-tabs-tab": {
        "&:focus": {
          outline: "none"
        }
      },
      "& .ant-tabs-top > .ant-tabs-nav": {
        margin: 0
      },
      "& .ant-tabs-nav-wrap": {
        borderBottom: "1px solid white"
      }
    },
    tabTitle: {
      fontSize: 16,
      verticalAlign: "center",
      position: "relative",
      letterSpacing: 0,
      color: theme.primary
    },
    button: {
      width: 145,
      height: 28,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      borderRadius: 2,
      letterSpacing: 0.72,
      fontFamily: "Heisei Kaku Gothic Std",
      marginLeft: 240,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    topMenu: {
      margin: "30px 24px",
      display: "flex",
      alignItems: "baseline"
    }
  };
});

export const Organization = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("" as string);
  const { authenticatedOwner, isAdminTeam, organizationId } = useContext(
    AuthContext
  );
  const [visibility, setVisibility] = useState(false);

  const [organizationData, setOrganizationData] = useState(
    {} as OrganizationInterface
  );
  const [form, setForm] = useState({} as OrganizationInterface);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    localStorage.setItem("organizationTab", value);
  };

  const toggleVisibility = () => {
    if (!visibility) {
      setForm(organizationData);
    }
    setVisibility(!visibility);
  };

  useEffect(() => {
    let tab = localStorage.getItem("organizationTab");
    if (tab === null) tab = "organization";
    if (tab) {
      setActiveTab(tab);
    }

    getOrganizationDetails();
  }, [visibility]);

  const getOrganizationDetails = async () => {
    const response = await API.get(`/organizations/${organizationId}`);
    if (response.data) {
      setOrganizationData(response.data);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (!name) {
      return;
    }
    setForm({ ...form, [name]: value });
  };

  const handleUpdateOrganization = () => {
    setOrganizationData(form);
  };

  return (
    <ComponentWrapper className={classes.componentWrapper}>
      <div className={classes.container}>
        <div className={classes.topMenu}>
          <Tabs
            className={classes.tabs}
            activeKey={activeTab}
            onChange={handleTabChange}
          >
            <Tabs.TabPane
              tab={<div className={classes.tabTitle}>{t("Organization")}</div>}
              key="organization"
            />
            <Tabs.TabPane
              tab={
                <div className={classes.tabTitle}>{t("Branch/Department")}</div>
              }
              key="branch/department"
            />
          </Tabs>
          {authenticatedOwner && isAdminTeam && activeTab === "organization" && (
            <Button className={classes.button} onClick={toggleVisibility}>
              {t("Edit")}
            </Button>
          )}
        </div>
        {activeTab === "organization" ? (
          <OrganizationComponent organizationData={organizationData} />
        ) : (
          <BranchComponent isAdmin={authenticatedOwner && isAdminTeam} />
        )}
      </div>
      <EditOrganizationModal
        visible={visibility}
        onCancel={toggleVisibility}
        setVisibility={setVisibility}
        form={form}
        handleChange={handleChange}
        setForm={setForm}
        onComplete={handleUpdateOrganization}
      />
    </ComponentWrapper>
  );
};
