import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  Circle,
  DeleteIcon,
  exceptionHandler,
  i18n,
  Loader,
  Typography
} from "shared-components";

import { CommentForm } from "../forms/Comment";

let useStyles = createUseStyles((theme: any) => {
  return {
    title: {
      marginLeft: 24,
      fontSize: 14
    },
    plusIconContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 21,
      marginBottom: 21
    },
    plusIcon: {
      cursor: "pointer",
      fontSize: 25,
      border: `2px solid ${theme.colorLight}`,
      width: 29,
      height: 29,
      textAlign: "center",
      lineHeight: "25px",
      fontWeight: "bold",
      borderRadius: "50%",
      color: theme.formLabelColor
    },
    commentContainer: {
      display: "flex",
      marginBottom: 15,
      flexDirection: "column"
    },
    badgeContainer: {
      display: "flex",
      alignItems: "center",
      "& >span": {
        marginLeft: 7
      }
    },
    textHolder: {
      width: "280px",
      background: theme.textWhite,
      padding: "10px 10px 3px 10px"
    },
    replyComment: {
      fontSize: 16,
      color: theme.textBlack,
      // wordBreak: "break-all",
      whiteSpace: "break-spaces"
    },
    date: {
      fontSize: 14
    },
    info: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end"
    },
    delete: {
      cursor: "pointer",
      color: theme.formLabelColor
    },
    doubleTick: {
      height: 22
    },
    addButton: {
      width: "40%",
      margin: "auto"
    },
    commentForm: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: 20
    },
    loader: {
      display: "flex",
      justifyContent: "center"
    },
    editText: {
      marginLeft: 24
    },
    error: {
      color: theme.red6,
      fontSize: 12,
      marginLeft: 25,
      marginBottom: 5
    },
    comment: {
      width: "305px",
      padding: "10px 10px 3px 30px"
    },
    replyComponent: {
      width: "305px",
      padding: "10px 10px 3px 60px"
    },
    replyButton: {
      textDecoration: "underline",
      marginRight: 31,
      cursor: "pointer"
    },
    otherReplies: {
      textAlign: "center",
      textDecoration: "underline",
      marginTop: 26,
      cursor: "pointer"
    },
    highlightComment: {
      border: "1px solid red"
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      minWidth: 75
    }
  };
});

const initialValue = {
  comment: ""
};

interface Props {
  reportID?: number | string;
  commentNotification?: any;
  setCommentNotification?: any;
}

const Comment = ({
  reportID,
  commentNotification,
  setCommentNotification
}: Props) => {
  const classes = useStyles();
  const { uid } = useContext(AuthContext);
  const [addComment, setAddComment] = useState(initialValue as any);
  const [addForm, setAddForm] = useState(false);
  const [loading, setLoading] = useState(false as boolean);
  const [submitLoad, setSubmitLoad] = useState(false as boolean);
  const [commentList, setCommentList] = useState([] as any);
  const [isReply, setIsReply] = useState(false as boolean);
  const [commentInfo, setCommentInfo] = useState({} as any);
  const [moreReplies, setMoreReplies] = useState([] as Number[]);
  const [highlight, setHighlight] = useState(false as boolean);
  const [queryComment, setQueryComment] = useState("" as string);
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (!name) {
      return;
    }
    if (value.length >= 45) {
      setAddComment({
        ...addComment,
        error: t("Maximum 45 characters are allowed")
      });
      return;
    }
    setAddComment({ [name]: value });
  };

  const increase = (isReply?: boolean) => {
    setAddComment({ comment: "" });
    if (isReply) {
      return;
    }
    setAddForm(true);
  };

  const decrease = async (id: number, key: number) => {
    try {
      await API.delete(`comments/${id}`);
      commentList.splice(key, 1);
      setCommentList([...commentList]);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const removeReply = async (id: number, commentId: number) => {
    try {
      await API.delete(`reply/${id}`);
      const comments = commentList;
      comments.forEach((item: any) => {
        if (item.id === commentId) {
          item.replies.forEach((reply: any, key: number) => {
            if (reply.id === id) {
              item.replies.splice(key, 1);
            }
          });
        }
      });
      setCommentList([...comments]);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const submitAdd = async () => {
    setSubmitLoad(true);
    try {
      if (addComment.comment === "") {
        setAddComment({ ...addComment, error: t("Required") });
        setSubmitLoad(false);
        return;
      }
      if (!isReply) {
        const res = await API.post(`reports/${reportID}/comments`, addComment);
        commentList.splice(0, 0, res.data);
        setCommentList([...commentList]);
      } else {
        const res = await API.post(`reports/${reportID}/reply`, {
          ...addComment,
          commentId: commentInfo.commentId
            ? commentInfo.commentId
            : commentInfo.id,
          toUserId: commentInfo.fromUserId
            ? commentInfo.fromUserId
            : commentInfo.user.id
        });
        const newList = commentList.map((item: any) => {
          if (item.id === commentInfo.id || item.id === commentInfo.commentId) {
            const newReplies = item.replies;
            if (res.data.data) {
              newReplies[item.replies.length - 1] = res.data.data;
            } else {
              newReplies[item.replies.length - 1] = res.data;
            }

            const updatedItem = {
              ...item,
              replies: newReplies
            };
            return updatedItem;
          }
          return item;
        });

        setCommentList(newList);
      }

      setSubmitLoad(false);
      setAddForm(false);
      setIsReply(false);
    } catch (error) {
      exceptionHandler(error, t);
      setSubmitLoad(false);
      setIsReply(false);
    }
  };

  const initialFetch = async () => {
    try {
      setLoading(true);
      const res = await API.get(`/reports/${reportID}/comments`);
      const data = res.data;
      if (data === null) {
        setLoading(false);
        return;
      }

      const sorted = data.sort((a: any, b: any) => {
        return moment(b.created_at).diff(a.created_at);
      });
      setCommentList([...sorted]);
      const urlParams = new URLSearchParams(window.location.search);
      const commentId = urlParams.get("commentId");
      if (commentId) {
        setQueryComment(commentId);
        setHighlight(true);
        sorted.forEach((item: any) => {
          if (item.replies) {
            item.replies.forEach((replyItem: any, key: number) => {
              if (key > 4 && replyItem.id === Number(commentId)) {
                handleSeeMore(item.id);
              }
            });
          }
        });
      }
      setLoading(false);
    } catch (error) {
      exceptionHandler(error, t);
      setLoading(false);
    }
  };

  const handleSeeMore = (id: number) => {
    setMoreReplies([...moreReplies, id]);
  };

  const addReplyForm = (item: any) => {
    const comments = [...commentList];
    comments.forEach((comment: any) => {
      if (
        comment.replies &&
        comment.replies[comment.replies.length - 1] &&
        !comment.replies[comment.replies.length - 1].id
      ) {
        comment.replies.splice(comment.replies.length - 1, 1);
      }
      if (comment.id === item.id) {
        if (!comment.replies) {
          comment.replies = [];
          comment.replies.push({});
        } else {
          comment.replies.push({});
        }
      }
    });
    setCommentList([...comments]);
  };

  const slideToComment = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const commentId = urlParams.get("commentId");
    const targetComment = document.getElementById(`comment_${commentId}`);
    const drawer = document.getElementById(`drawer`);
    const topPosition = targetComment?.offsetTop;
    drawer?.parentElement?.scroll({
      top: topPosition,
      behavior: "smooth"
    });
    setCommentNotification(true);
  };

  useEffect(() => {
    if (highlight) {
      setTimeout(() => {
        setHighlight(false);
      }, 3000);
    }
  }, [highlight]);

  useEffect(() => {
    if (commentList.length > 0 && !commentNotification) {
      setTimeout(() => {
        slideToComment();
      }, 600);
    }
  }, [commentList.length]);

  useEffect(() => {
    if (reportID) {
      initialFetch();
    }
  }, [reportID]);

  return (
    <div id={"drawer"}>
      <div className={classes.title}>{t("Comment")}</div>
      <div className={classes.plusIconContainer}>
        <span
          className={classes.plusIcon}
          onClick={() => {
            return increase();
          }}
        >
          +
        </span>
      </div>
      {addForm && (
        <CommentForm
          handleChange={handleChange}
          onSubmit={submitAdd}
          loading={submitLoad}
          addComment={addComment}
        />
      )}
      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        commentList.map((item: any, key: number) => {
          return (
            <div className={classes.commentContainer} key={key}>
              <div className={classes.comment}>
                <Typography className={classes.replyComment}>
                  {item.comment}
                </Typography>
                <div className={classes.info}>
                  <div>
                    <div className={classes.badgeContainer}>
                      <Circle color={item.user.color} size={14} />
                      <span>{item.user.name}</span>
                    </div>
                    <span className={classes.date}>
                      {moment(item.created_at).format("YYYY/MM/DD HH:mm")}
                    </span>
                  </div>
                  <div className={classes.actions}>
                    <span
                      onClick={() => {
                        setIsReply(true);
                        setCommentInfo(item);
                        addReplyForm(item);
                        handleSeeMore(item.id);
                        return increase(true);
                      }}
                      className={classes.replyButton}
                    >
                      {t("Reply")}
                    </span>
                    {uid === item.user.id && (
                      <DeleteIcon
                        className={classes.delete}
                        onClick={() => {
                          return decrease(item.id, key);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {item.replies &&
                item.replies !== null &&
                item.replies.map((replyItem: any, key: number) => {
                  if (!moreReplies.includes(item.id) && key > 4) {
                    return;
                  }
                  if (!replyItem.id) {
                    return (
                      <CommentForm
                        handleChange={handleChange}
                        onSubmit={submitAdd}
                        loading={submitLoad}
                        addComment={addComment}
                      />
                    );
                  }
                  return (
                    <div
                      id={`comment_${replyItem.id}`}
                      className={`${classes.replyComponent} 
                          ${replyItem.id === Number(queryComment) &&
                            highlight &&
                            classes.highlightComment}`}
                      key={key}
                    >
                      <Typography className={classes.replyComment}>
                        {i18n.language === "ja"
                          ? `${replyItem.toUserName} さん`
                          : replyItem.toUserName}
                      </Typography>

                      <Typography className={classes.replyComment}>
                        {replyItem.comment}
                      </Typography>
                      <div className={classes.info}>
                        <div>
                          <div className={classes.badgeContainer}>
                            <Circle color={replyItem.fromUserColor} size={14} />
                            <span>{replyItem.fromUserName}</span>
                          </div>
                          <span className={classes.date}>
                            {moment(replyItem.created_at).format(
                              "YYYY/MM/DD HH:mm"
                            )}
                          </span>
                        </div>
                        <div className={classes.actions}>
                          <span
                            onClick={() => {
                              setIsReply(true);
                              setCommentInfo(replyItem);
                              addReplyForm(item);
                              handleSeeMore(item.id);
                              return increase(true);
                            }}
                            className={classes.replyButton}
                          >
                            {t("Reply")}
                          </span>
                          {uid === replyItem.fromUserId && (
                            <DeleteIcon
                              className={classes.delete}
                              onClick={() => {
                                return removeReply(replyItem.id, item.id);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {item.replies &&
                item.replies !== null &&
                item.replies.length > 5 &&
                !moreReplies.includes(item.id) && (
                  <span
                    className={classes.otherReplies}
                    onClick={() => {
                      return handleSeeMore(item.id);
                    }}
                  >
                    {t("Show other replies")}{" "}
                    {`(${item.replies.length - 5} ${
                      i18n.language === "ja" ? "件" : ""
                    })`}
                  </span>
                )}
            </div>
          );
        })
      )}
    </div>
  );
};

export { Comment };
