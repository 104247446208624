import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  AuthContext,
  Button,
  DoubleTickIcon,
  Typography
} from "shared-components";

import { Member } from "../../pages/cases/types/case";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      width: "100%",
      paddingLeft: 24
    },
    container: {
      width: "100%",
      padding: "40px 50px 20px 50px"
    },
    lowerContainer: {
      width: "100%",
      padding: "40px 50px 20px 50px",
      backgroundColor: theme.formBgColor
    },
    button: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginBottom: 10,
      width: "100%",
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    },
    icon: {
      position: "absolute",
      top: "25%",
      right: "-5px"
    },
    doubleTick: {
      marginRight: 5
    },
    member: {
      borderRadius: 3,
      marginBottom: 10,
      height: 30,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer"
      }
    },
    memberName: {
      margin: "0 15px",
      fontSize: 16,
      fontColor: theme.textPrimary
    },
    divider: {
      borderColor: theme.border
    },
    listContainer: {
      marginTop: 15
    },
    buttonLabel: {
      color: theme.formLabelColor,
      fontSize: 14,
      marginBottom: 10
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: 69,
      width: "100%"
    },
    revokeButtonStyle: {
      height: "28px",
      width: "202px",
      backgroundColor: theme.textBlue,
      fontSize: 14,
      lineHeight: "17px",
      letteSpacing: "0.84px"
    }
  };
});

export interface CaseDoubleCheck {
  reportId: number;
  completedAt: string | null;
  changeRequestedAt: string | null;
  id: number;
  userId: string;
}

export interface DoubleCheckProps {
  caseDoubleCheck?: CaseDoubleCheck | null;
  setDoubleCheckUser?: React.Dispatch<React.SetStateAction<Member | null>>;
  setConfirmRequest?: React.Dispatch<React.SetStateAction<boolean>>;
  setDoubleCheckReturn?: any;
  setDoubleCheckConfirm?: any;
  teamId?: number;
  confirm?: boolean;
  pharmacistID?: string;
  consultantID?: string;
  members?: Member[];
  onClose?: (event?: any, clickedOn?: string | undefined) => void;
  caseStatus?: string;
  physicianID?: string;
  canCompleteRequest?: boolean;
  isReceiverReport?: boolean;
  isAnswered?: boolean;
  canceled?: boolean;
  handleRevoke?: any;
}

const DoubleCheck = ({
  setDoubleCheckConfirm,
  caseDoubleCheck,
  members,
  confirm,
  pharmacistID,
  setConfirmRequest,
  onClose,
  caseStatus,
  setDoubleCheckUser,
  setDoubleCheckReturn,
  physicianID,
  canCompleteRequest,
  isReceiverReport,
  isAnswered,
  canceled,
  handleRevoke
}: DoubleCheckProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uid } = useContext(AuthContext);

  const [buttonup, setButtonup] = useState(false as boolean);

  const [userSelect, setUserSelect] = useState(null as Member | null);

  useEffect(() => {
    if (members) {
      if (members.length > 0 && caseDoubleCheck) {
        if (
          !caseDoubleCheck.completedAt &&
          !caseDoubleCheck.changeRequestedAt
        ) {
          const currentUser = members?.find(member => {
            return member.id !== "" && member.id === caseDoubleCheck.userId;
          });
          if (currentUser) {
            setUserSelect(currentUser);
          }
        } else {
          setUserSelect(null);
        }
      }
    }
  }, [members, caseDoubleCheck]);

  return (
    <div className={classes.wrapper}>
      {caseDoubleCheck &&
      userSelect &&
      (!isAnswered || !canceled) &&
      !confirm ? (
        userSelect.id === uid ? (
          <div>
            <Typography className={classes.buttonLabel}>
              {t("Change Request")}
            </Typography>
            <Button
              size="middle"
              className={classes.button}
              onClick={() => {
                if (setDoubleCheckReturn) setDoubleCheckReturn();
                if (onClose) onClose();
              }}
            >
              {t("Send back")}
            </Button>
            <Divider className={classes.divider} />
            <Typography className={classes.buttonLabel}>{t("Done")}</Typography>
            <Button
              size="middle"
              className={classes.button}
              onClick={() => {
                setDoubleCheckConfirm(true);
                if (onClose) onClose();
              }}
            >
              {t("Confirm")}
            </Button>
          </div>
        ) : (
          <div>
            <Typography type="body3">
              {t("The following member is in the process of double-checking.")}
            </Typography>
            <div
              className={classes.member}
              style={{ backgroundColor: userSelect.color }}
            >
              <Typography type="body3" className={classes.memberName}>
                {userSelect.name || userSelect.email}
              </Typography>
            </div>
          </div>
        )
      ) : confirm &&
        (caseStatus !== "canceled" || isAnswered) &&
        !canCompleteRequest &&
        !canceled ? (
        <div>
          <Typography type="body3">
            {t(
              isReceiverReport
                ? "This case has been answered."
                : "This case has been confirmed."
            )}
          </Typography>
          {pharmacistID === uid && !isAnswered && (
            <div className={classes.buttonWrapper}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className={classes.revokeButtonStyle}
                onClick={handleRevoke}
              >
                {t("Revoke completion")}
              </Button>
            </div>
          )}
        </div>
      ) : canceled ? (
        <Typography type="body3">
          {t("The report has been cancelled.")}
        </Typography>
      ) : pharmacistID === uid &&
        !isReceiverReport &&
        (!isAnswered || !canceled) ? (
        <>
          {
            <>
              <Typography type="body3" className={classes.buttonLabel}>
                {t("Double Check")}
              </Typography>
              <Button
                size="middle"
                className={classes.button}
                onClick={() => {
                  return setButtonup(!buttonup);
                }}
              >
                <div className={classes.buttonContainer}>
                  <DoubleTickIcon className={classes.doubleTick} />
                  {t("Request a double check")}
                  {buttonup ? (
                    <CaretUpOutlined className={classes.icon} />
                  ) : (
                    <CaretDownOutlined className={classes.icon} />
                  )}
                </div>
              </Button>
              <br />
              <div className={classes.listContainer}>
                {buttonup &&
                  members &&
                  members.length > 0 &&
                  members.map((member: Member) => {
                    if (member.id !== "") {
                      return (
                        <div
                          key={member.id}
                          className={classes.member}
                          style={{ backgroundColor: member.color }}
                          onClick={() => {
                            setButtonup(!buttonup);
                            if (setDoubleCheckUser) setDoubleCheckUser(member);
                            if (onClose) onClose();
                          }}
                        >
                          <Typography
                            type="body3"
                            className={classes.memberName}
                          >
                            {member.name || member.email}
                          </Typography>
                        </div>
                      );
                    }
                  })}
              </div>
              <Divider className={classes.divider} />
            </>
          }
          <Typography type="body3" className={classes.buttonLabel}>
            {t("Complete")}
          </Typography>
          <Button
            size="middle"
            className={classes.button}
            onClick={() => {
              if (setConfirmRequest) setConfirmRequest(true);
              if (onClose) onClose();
            }}
          >
            {t("To Confirmation Screen")}
          </Button>
        </>
      ) : isReceiverReport &&
        physicianID === uid &&
        (!isAnswered || !canceled) ? (
        <>
          <Typography type="body3" className={classes.buttonLabel}>
            {t("Complete")}
          </Typography>
          <Button
            size="middle"
            className={classes.button}
            onClick={() => {
              if (setConfirmRequest) setConfirmRequest(true);
              if (onClose) onClose();
            }}
          >
            {t("To Confirmation Screen")}
          </Button>
        </>
      ) : (
        (!isAnswered || !canceled) && (
          <div>
            <Typography type="body3">
              {isReceiverReport
                ? t(
                    "You are not authorized. Only the physician of the case can complete answering."
                  )
                : t(
                    "You are not authorized to double check and confirm the case."
                  )}
            </Typography>
          </div>
        )
      )}
    </div>
  );
};

export { DoubleCheck };
