import "moment/locale/ja";

import { ConfigProvider } from "antd";
import enLocale from "antd/lib/locale/en_US";
import jpLocale from "antd/lib/locale/ja_JP";
import i18n from "i18next";
import React, { useContext } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { AuthContext } from "shared-components";

import { SideBar } from "../components";
import { Case } from "../pages/cases/Case";
import { CaseList } from "../pages/cases/CaseList";
import { Clients } from "../pages/clients/Client";
import { Member } from "../pages/member/Member";
import { Organization } from "../pages/organization/Organization";
import { Request } from "../pages/requests/Request";
import { RequestDetails } from "../pages/requests/RequestDetails";
import { Settings } from "../pages/settings/Settings";
import { PrivateRoute } from "./PrivateRoute";

const HomeWithSiderBar = () => {
  const { authenticatedOwner, profile } = useContext(AuthContext);
  const history = useHistory();

  if (profile && profile.teams && profile.teams.length === 0) {
    history.replace("/account-settings");
    return null;
  }

  return (
    <React.Fragment>
      <SideBar />
      <ConfigProvider locale={i18n.language === "ja" ? jpLocale : enLocale}>
        <Switch>
          <PrivateRoute exact path="/cases/:id" component={Case} />
          <PrivateRoute exact path="/cases" component={CaseList} />
          <PrivateRoute exact path="/organization" component={Organization} />
          {authenticatedOwner && (
            <PrivateRoute exact path="/members" component={Member} />
          )}
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/clients" component={Clients} />
          <PrivateRoute exact path="/requests" component={Request} />
          <PrivateRoute exact path="/requests/:id" component={RequestDetails} />
          <Redirect to="/cases" from="/" />
        </Switch>
      </ConfigProvider>
    </React.Fragment>
  );
};

export { HomeWithSiderBar };
