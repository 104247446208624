import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { TextArea, TextField, Typography } from "shared-components";

import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { DrugData } from "../types/case";
import { SourceInformationMedicineTableContainer } from "./SourceInformationMedicineTable";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9
    },
    showComment: {
      padding: {
        top: 21,
        bottom: 35
      }
    },
    lineStyle: {
      display: "block",
      backgroundColor: theme.scrollColor,
      height: "1.2px",
      marginBottom: 13
    },
    textStyle: {
      fontSize: "12px",
      lineHeight: "14px",
      marginBottom: 13,
      color: theme.gray6
    },
    buttonWrapper: ({ isEdit }) => {
      return {
        display: "flex",
        justifyContent: "center",
        marginBottom: isEdit ? 21 : 33
      };
    },
    buttonStyle: {
      width: 79,
      height: 28,
      fontSize: 14,
      lineHeight: "17px",
      letteSpacing: "0.84px",
      marginTop: 29,
      padding: "unset"
    },
    referenceWrapper: {
      marginBottom: 40
    },
    noDrugInfo: {
      fontSize: 12,
      color: theme.gray6,
      textAlign: "center"
    }
  };
});

interface Props {
  isEdit: boolean;
  comment: any;
  reference: any;
  handleChange: any;
  sourceMedicines: DrugData[];
  setSourceMedicines: any;
  handleChangeMedicine?: (location: string, data: any, id?: string) => void;
  reportId: string;
  handleDeleteMedicine: any;
  isPublicReport: boolean;
  disabled?: boolean;
}

export const SourceInformation = ({
  isEdit,
  comment,
  reference,
  handleChange,
  sourceMedicines,
  setSourceMedicines,
  handleChangeMedicine,
  reportId,
  handleDeleteMedicine,
  isPublicReport,
  disabled
}: Props) => {
  const classes = useStyles({ isEdit });
  const { t } = useTranslation();

  const referenceLabel = t(
    "Reference Information (Appendix / Interview Form / RMP / Manual / Guidelines / Book by Serious Side Effect)"
  );

  return (
    <div className={classes.container}>
      <FormTitle>{t("Source Information")}</FormTitle>

      {!isEdit ? (
        <ShowData
          label="Comments"
          value={comment || "-"}
          className={classes.showComment}
        />
      ) : (
        <TextArea
          label={t("Comments")}
          name="source_comment"
          value={comment}
          onChange={handleChange}
          rows={5}
          maxLength={3000}
          disabled={disabled}
        />
      )}

      <span className={classes.lineStyle} />

      <Typography className={classes.textStyle}>
        {t("Drug Information")}{" "}
      </Typography>

      <SourceInformationMedicineTableContainer
        isEditable={isEdit && !disabled}
        reportId={reportId}
        isPublicReport={isPublicReport}
      />

      {!isEdit ? (
        <ShowData
          label={referenceLabel}
          value={reference || "-"}
          className={classes.referenceWrapper}
        />
      ) : (
        <TextField
          name="source_reference"
          label={referenceLabel}
          editValue={reference}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};
