import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  Button,
  exceptionHandler,
  TextArea,
  Typography
} from "shared-components";

import { DrugCaseComponent } from "../../../components/atoms/DrugCase";
import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { DrugData } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9
    },
    title: {
      display: "flex",
      marginBottom: 21
    },
    sectionContent: {
      fontSize: 16,
      wordBreak: "break-word"
    },
    divider: {
      borderTop: "1px solid #F0F8FD",
      marginTop: 28
    },
    tableHead: {
      marginBottom: 15
    },
    prescriptionFormHead: {
      display: "flex"
    },
    precriptionRadio: {
      display: "flex",
      alignItems: "center",
      marginLeft: 18
    },
    answerBlock: {
      marginTop: 15,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline"
    },
    revert: {
      background: theme.primary,
      color: theme.textWhite,
      borderRadius: "2px",
      width: 160
    },
    add: {
      background: theme.primary,
      color: theme.textWhite,
      borderRadius: "2px",
      width: 79
    },
    addContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20
    },
    changeText: {
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0px",
      color: theme.textLightBlack,
      marginLeft: 22,
      position: "relative",
      top: 7
    },
    noDrugInfo: {
      fontSize: 12,
      color: theme.gray6,
      textAlign: "center"
    }
  };
});

interface Props {
  caseStatus: string;
  changePrescription: boolean;
  changeComment: string;
  reportMedicinesData: DrugData[];
  handleChange: (event: any) => void;
  reportId: string;
  changeMedicines: DrugData[];
  setChangeMedicines: any;
  handleChangeMedicine?: (location: string, data: any, id?: string) => void;
  handleDeleteMedicine: any;
  handleRevertMedicine: any;
  isEdit: boolean;
  viewAsTeamOwner?: boolean;
}

export const PrescriptionChange: React.FC<Props> = ({
  caseStatus,
  changePrescription,
  changeComment,
  reportMedicinesData,
  handleChange,
  reportId,
  changeMedicines,
  setChangeMedicines,
  handleChangeMedicine,
  handleDeleteMedicine,
  handleRevertMedicine,
  isEdit,
  viewAsTeamOwner
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showEditTable, setShowEditTable] = useState(true as boolean);

  useEffect(() => {
    if (changePrescription) {
      setShowEditTable(true);
    } else {
      setShowEditTable(false);
    }
  }, [changePrescription]);

  const handleAddDrugData = async () => {
    try {
      const res = await API.post("medicines", {
        reportId: reportId,
        location: "change"
      });
      const id = res.data.id;
      setChangeMedicines([...changeMedicines, { id: id } as DrugData]);
      if (handleChangeMedicine) {
        handleChangeMedicine("change", [
          ...changeMedicines,
          { id: id } as DrugData
        ]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleChangeDrugData = (id: number, event: any) => {
    const newList = changeMedicines.map(item => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          [event.target.name]: event.target.value
        };
        return updatedItem;
      }
      return item;
    });
    setChangeMedicines(newList);
    if (handleChangeMedicine) {
      handleChangeMedicine("change", newList, String(id));
    }
  };

  const handleDeleteDrugData = (id: number) => {
    handleDeleteMedicine("change", changeMedicines, id);
  };

  const handleRevert = () => {
    handleRevertMedicine("change", reportId);
  };

  return (
    <div className={classes.container}>
      {isEdit ? (
        <div>
          <div className={classes.prescriptionFormHead}>
            <FormTitle>{t("Prescription Change")}</FormTitle>
            <Radio.Group
              className={classes.precriptionRadio}
              name="change_prescription"
              onChange={handleChange}
              value={changePrescription}
              disabled={viewAsTeamOwner}
            >
              <Radio value={true}>{t("Change2")}</Radio>
              <br />
              <Radio value={false}>{t("Don’t change")}</Radio>
            </Radio.Group>
          </div>
          <TextArea
            onChange={handleChange}
            name="change_comment"
            rows={5}
            value={changeComment}
            label={t("Comments")}
            maxLength={3000}
            disabled={viewAsTeamOwner}
          />
        </div>
      ) : (
        <>
          <FormTitle className={classes.title}>
            {t("Prescription Change")}
            <Typography className={classes.changeText}>
              {showEditTable ? t("Change2") : t("Don’t change")}
            </Typography>
          </FormTitle>
          <ShowData label={t("Comments")} value={changeComment || "-"} />
        </>
      )}
      <hr className={classes.divider} />
      <div>
        <Typography className={classes.tableHead} fontSize={"12px"}>
          {t("Pharmacy report (review/suggestion/request)")}
        </Typography>
        {reportMedicinesData.length > 0 ? (
          reportMedicinesData.map((medicine, key) => {
            return (
              <DrugCaseComponent
                recordId={medicine.id}
                data={medicine}
                cardkey={key}
                disabled={viewAsTeamOwner}
              />
            );
          })
        ) : (
          <div style={{ marginTop: 26 }}>
            <Typography className={classes.noDrugInfo}>
              {t("There are no drugs set.")}
            </Typography>
          </div>
        )}
      </div>

      {showEditTable && (
        <>
          <div className={classes.answerBlock}>
            <Typography className={classes.tableHead} fontSize={"12px"}>
              {t("Answer")}
            </Typography>
            {isEdit && (
              <Button
                disabled={viewAsTeamOwner}
                onClick={handleRevert}
                className={classes.revert}
              >
                {t("Revert")}
              </Button>
            )}
          </div>
          {changeMedicines.length > 0 ? (
            changeMedicines.map((medicine, key) => {
              return (
                <DrugCaseComponent
                  isEdit={isEdit}
                  handleChange={handleChangeDrugData}
                  handleDelete={handleDeleteDrugData}
                  recordId={medicine.id}
                  data={medicine}
                  cardkey={key}
                  disabled={viewAsTeamOwner}
                />
              );
            })
          ) : (
            <div style={{ marginTop: 26 }}>
              <Typography className={classes.noDrugInfo}>
                {t("There are no drugs set.")}
              </Typography>
              {isEdit && (
                <Typography className={classes.noDrugInfo}>
                  {t("Please click on the button below to add one.")}
                </Typography>
              )}
            </div>
          )}
          {isEdit && (
            <div className={classes.addContainer}>
              <Button
                disabled={viewAsTeamOwner}
                onClick={handleAddDrugData}
                className={classes.add}
              >
                {t("Add")}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
