import { Form, Input, Table } from "antd";
import { FormInstance } from "antd/lib/form";
import { TableProps } from "antd/lib/table";
import React, { useContext, useEffect } from "react";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps<T> {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof T & (string | number);
  record: T;
  handleSave: (record: T) => void;
  textareaSize?: number;
}

const EditableCell = <T extends {}>(props: EditableCellProps<T>) => {
  const {
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    textareaSize = 1,
    ...restProps
  } = props;
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [form, record, dataIndex]);

  const save = async () => {
    const values = await form.validateFields();
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    handleSave({ ...record, ...values });
  };

  return (
    <>
      {editable ? (
        <td {...restProps} style={{ padding: "4px" }}>
          <Form.Item style={{ margin: 0 }} name={dataIndex} rules={[]}>
            <Input.TextArea
              onBlur={save}
              rows={textareaSize}
              style={{ padding: "4px" }}
            />
          </Form.Item>
        </td>
      ) : (
        <td {...restProps} style={{ padding: "12px" }}>
          <div className="editable-cell-value-wrap">{children}</div>
        </td>
      )}
    </>
  );
};

type EditableTableProps<T> = Omit<TableProps<T>, "columns"> &
  Required<Pick<TableProps<T>, "dataSource">> & {
    columns: ColumnType<T>[];
    handleSave: (data: T) => void;
  };

type DataType = {
  key: React.Key;
  type: string;
  content: string;
  reason: string;
};

type ElementOfArray<T> = T extends { [K in number]: infer E } ? E : any;

type ColumnType<T> = ElementOfArray<TableProps<T>> & {
  title: string;
  dataIndex: string;
  textareaSize?: number;
  editable?: boolean;
};

type OriginalColumnTypes = Exclude<TableProps<{}>["columns"], undefined>;

const EditableTable = <T extends {}>(props: EditableTableProps<T>) => {
  const { dataSource, handleSave, style } = props;

  const columns = props.columns?.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
          textareaSize: col.textareaSize
        };
      }
    };
  });

  return (
    <Table
      components={{
        body: {
          row: EditableRow,
          cell: EditableCell
        }
      }}
      rowClassName={() => {
        return "editable-row";
      }}
      bordered
      dataSource={dataSource}
      columns={columns as OriginalColumnTypes}
      pagination={false}
      style={style}
      size="small"
    />
  );
};

export default EditableTable;
