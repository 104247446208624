import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API, exceptionHandler } from "shared-components";

import { Member } from "../cases/types/case";
import { AdminOrganization } from "./AdminOrganization";
import { TeamOrganization } from "./TeamOrganization";

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      marginTop: 30,
      fontFamily: "Noto Sans JP",
      letterSpacing: 0,
      marginLeft: 37
    },
    nameField: {
      marginBottom: 27
    },
    field: {
      marginBottom: 34
    },
    finalField: {
      marginBottom: 79
    },
    title: {
      color: theme.primary,
      fontSize: 12,
      marginBottom: 4,
      letterSpacing: 0
    },
    description: {
      fontSize: 16,
      marginTop: 4,
      color: theme.gray7,
      letterSpacing: 0
    }
  };
});

interface Props {
  isAdmin: boolean | undefined;
}

export const BranchComponent = ({ isAdmin }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [allMembers, setAllMembers] = useState([] as Member[]);

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = async () => {
    try {
      const memberResponse = await API.get(`organizations-members`);
      setAllMembers(memberResponse.data);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  return (
    <>
      {selectedOrganizationId == "" && isAdmin ? (
        <AdminOrganization
          setSelectedOrganizationId={setSelectedOrganizationId}
          members={allMembers}
          getMembers={getMembers}
        />
      ) : (
        <div className={classes.componentWrapper}>
          <TeamOrganization
            selectedOrganizationId={selectedOrganizationId}
            members={allMembers}
          />
        </div>
      )}
    </>
  );
};
