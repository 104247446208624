import { useQuery } from "react-query";
import { API } from "shared-components";

import { PrescriptionType } from "../pages/cases/types/case";

export const useFetchPrescriptionTypes = () => {
  return useQuery<PrescriptionType[]>(
    "prescriptionTypes",
    async () => {
      const { data } = await API.get(`/prescription-types`);
      return data.prescriptionTypes;
    },
    {
      staleTime: Infinity
    }
  );
};
