import { DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  AuthContext,
  Button,
  DeleteConfirm,
  DeleteIcon,
  exceptionHandler,
  FilePlusIcon,
  language,
  TableComponent
} from "shared-components";

import { Member } from "../cases/types/case";
import { CreateBranchModal } from "./CreateBranchModal";

const useStyles = createUseStyles((theme: any) => {
  return {
    tableClassName: {
      "& .ant-table-thead > tr > th": {
        textAlign: "left",
        padding: "0px 10px",
        "&:first-child": { paddingLeft: 19, paddingRight: 10 }
      },
      "& .ant-table-tbody > tr > td": {
        textAlign: "left",
        "&:first-child": { padding: "0px 19px" },
        "&:last-child": { padding: "0px 10px", maxWidth: "60px", width: "60px" }
      }
    },
    button: {
      float: "right",
      width: 145,
      height: 32,
      borderRadius: 16,
      paddingLeft: 30,
      paddingRight: 30,
      marginBottom: 6,
      marginRight: 17,
      boxShadow: "0px 3px 6px #00000029",
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      display: "flex",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    moreMenu: {
      fontSize: "16px"
    },
    deleteicon: {
      display: "flex",
      height: "35px",
      width: "184px",
      alignItems: "center",
      cursor: "pointer",
      "& span": {
        paddingLeft: 12
      }
    },
    actionColumn: {
      width: 50,
      position: "absolute",
      right: 0,
      margin: {
        top: -8,
        right: -15
      }
    },
    dateTimeColumn: {
      maxWidth: 110
    },
    downloadButton: {
      float: "Right",
      width: " fit-Content",
      height: "32px",
      paddingLeft: "30px",
      paddingRight: "30px",
      backgroundColor: theme.textWhite,
      color: theme.textBlue,
      border: "none",
      display: "flex",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      },
      "&:focus": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      },
      "&:active": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      }
    }
  };
});

interface Props {
  setSelectedOrganizationId: React.Dispatch<React.SetStateAction<string>>;
  members: Member[];
  getMembers: any;
}

export const AdminOrganization: React.FC<Props> = ({ members, getMembers }) => {
  const classes = useStyles();
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(true as boolean);
  const { t } = useTranslation();
  const [popOverVisible, setPopOverVisible] = useState("" as String);
  const [isDeleted, setIsDeleted] = useState(false as boolean);
  const { organizationId, uid, getProfile, setProfile } = useContext(
    AuthContext
  );

  const [teamList, setTeamList] = useState([] as TeamInterface[]);
  const [hasId, setHasId] = useState("");
  const hasIdRef = useRef(hasId);
  const [donwloading, setDownloading] = useState(false as boolean);

  const setHasIdRef = async (data: any) => {
    hasIdRef.current = data;
  };

  useEffect(() => {
    getTeamList();
  }, [visibility, isDeleted]);

  const getTeamList = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(`teams-list/${organizationId}`);
      setLoading(false);
      if (!response.data) {
        setTeamList([]);
        return;
      }
      setTeamList(response.data);
    } catch (error) {
      setLoading(false);
      setTeamList([]);
      exceptionHandler(error, t);
    }
  };

  const toggleVisibilty = () => {
    setVisibility(!visibility);
  };

  const removeVisibility = () => {
    setVisibility(false);
  };

  const popOverContent = (id: string) => {
    return (
      <>
        <div
          className={classes.deleteicon}
          onMouseDown={() => {
            setHasId(id);
            setPopOverVisible("");
            return handleDelete(id);
          }}
        >
          <DeleteIcon />
          <span>{t("Remove")}</span>
        </div>
      </>
    );
  };

  const handleDelete = async (id: string) => {
    DeleteConfirm(
      async () => {
        setLoading(true);
        try {
          await API.delete(`/teams/${id}`);
          setLoading(false);
          setIsDeleted(!isDeleted);
          setProfile(await getProfile(uid));
          Alert("success", "success", t("Branch/ Department is deleted "), t);
        } catch (error) {
          setLoading(false);
          exceptionHandler(error, t);
        }
      },
      "Delete branch/department?",
      true,
      ""
    );
  };

  const columns = [
    {
      title: t("Name2"),
      dataIndex: "name",
      key: "name",
      width: "30%"
    },
    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
      width: "30%"
    },
    {
      title: t("Team Code"),
      dataIndex: "invitationCode",
      key: "invitation_code",
      width: "14%"
    },
    {
      title: t("Created"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "13%",
      render: (text: string, data: any) => {
        return (
          <div className={classes.dateTimeColumn}>
            {moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss")}
          </div>
        );
      }
    },
    {
      title: t("Updated"),
      dataIndex: "updatedAt",
      key: "updated_at",
      width: "13%",
      render: (text: string, data: any) => {
        return (
          <div className={classes.dateTimeColumn}>
            {moment(data.updatedAt).format("YYYY/MM/DD HH:mm:ss")}
          </div>
        );
      }
    },
    {
      render: (record: any) => {
        return (
          <div
            className={classes.actionColumn}
            onClick={event => {
              return event.stopPropagation();
            }}
            onBlur={() => {
              return setPopOverVisible("");
            }}
          >
            <Popover
              content={() => {
                return popOverContent(record.ID);
              }}
              trigger="click"
              visible={record.ID === popOverVisible && true}
            >
              <MoreOutlined
                className={classes.moreMenu}
                onClick={() => {
                  return setPopOverVisible(record.ID);
                }}
              />
            </Popover>
          </div>
        );
      }
    }
  ];

  const handleDownloadBranch = async () => {
    try {
      setDownloading(true);
      const response: Blob = await API.get("/download-branch", {
        params: {
          id: organizationId,
          language: language,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        responseType: "blob"
      });

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      const dateString = moment(new Date()).format("YYYYMMDD_HHmmss");
      link.setAttribute("download", `teams_${dateString}.csv`);
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      exceptionHandler(err, t);
    }
  };
  return (
    <>
      <Button
        icon={<FilePlusIcon />}
        className={classes.button}
        onClick={() => {
          setHasIdRef("");
          toggleVisibilty();
        }}
      >
        {t("Create")}
      </Button>
      <Button
        icon={<DownloadOutlined />}
        className={classes.downloadButton}
        onClick={handleDownloadBranch}
        loading={donwloading}
      >
        {t("Download Branch/Department")}
      </Button>
      <TableComponent
        loading={loading}
        rowKey="ID"
        columns={columns}
        dataSource={teamList}
        className={classes.tableClassName}
        handleRow={(record: any) => {
          return {
            onClick: () => {
              setHasIdRef(record.ID);
              setHasId(record.ID);
              toggleVisibilty();
            }
          };
        }}
      />

      <CreateBranchModal
        id={hasIdRef.current}
        visible={visibility}
        onCancel={removeVisibility}
        setVisibility={setVisibility}
        members={members}
        setHasIdRef={setHasIdRef}
        getMembers={getMembers}
      />
    </>
  );
};
