export const notificationTypes: any = {
  double_check_request: "asked you for double-check.",
  double_check_complete: "has completed double-check.",
  double_check_return: "has returned double-check.",
  "assigned-as-pathologist": "A new case was assigned to you.",
  send_request: "dr_send_request",
  request_quote: "dr_request_quote",
  edit_request: "dr_edit_request",
  accept_request: "dr_accept_request",
  return_request: "dr_return_request",
  request_check: "dr_request_check",
  return_check: "dr_return_check",
  done: "dr_done",
  accept_cancel: "dr_accept_cancel",
  send_quote: "dr_send_quote",
  order: "dr_order",
  request_cancel: "dr_request_cancel",
  new_reply: "replied to the comment."
};
