import { Col, Form, Radio, Row } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { TextField, Typography } from "shared-components";

import { SelectDateComponent } from "../../../components/atoms/SelectDateComponent";
import { ShowData } from "../../../components/form-items/ShowData";
import { FormTitle } from "../../../components/forms/Layout";
import { CaseData } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      background: theme.formBackground,
      padding: "16px 40px",
      marginTop: 9
    },
    titleStyle: {
      paddingBottom: 21
    },
    formBlock: {
      display: "flex",
      maxHeight: 47
    },
    bottomFormBlock: {
      display: "flex",
      minHeight: 47,
      marginTop: 27
    },
    viewPatientTopInformation: {
      marginBottom: 35
    },
    labelStyle: {
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0px",
      color: theme.gray6
    },
    patientIDStyle: { width: "22.9%" },
    patientNameStyle: { width: "48.6%" },
    sexBlockStyle: {
      position: "relative",
      top: 4,
      left: -4,
      "& span.ant-radio + *": {
        padding: {
          left: 2
        },
        position: "relative",
        bottom: -1,
        fontSize: "16px",
        lineHeight: "19px",
        color: theme.textLightBlack
      }
    },
    sexTextStyle: {
      position: "relative",
      left: 4
    },
    bottomSideField: {
      width: "31.4%"
    },
    gapStyle: {
      display: "block",
      width: "20px"
    },
    datePickerStyle: {
      width: "100%",
      marginTop: 1
    }
  };
});

interface Props {
  caseState: CaseData;
  agreementStatus: string;
  patientPage?: boolean;
  viewAsTeamOwner?: boolean;
  handleChange?: any;
  handleChangeDate?: any;
}

export const PatientInformation = ({
  caseState,
  agreementStatus,
  patientPage,
  viewAsTeamOwner,
  handleChange,
  handleChangeDate
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <FormTitle className={classes.titleStyle}>
        {t("Patient Information")}
      </FormTitle>
      {agreementStatus === "creating" ? (
        <div>
          <div className={classes.formBlock}>
            <Form.Item
              required={false}
              name="patient_id"
              rules={[
                {
                  max: 90,
                  message: t("Max Length Reached")
                }
              ]}
              className={classes.patientIDStyle}
            >
              <TextField
                name="patient_id"
                label={t("Patient ID")}
                editValue={caseState.patient_id}
                onChange={handleChange}
                disabled={viewAsTeamOwner}
              />
            </Form.Item>
            <span className={classes.gapStyle} />
            <Form.Item
              required={true}
              name="patient_name"
              rules={[
                {
                  required: true,
                  message: t("Required")
                },
                {
                  max: 90,
                  message: t("Max Length Reached")
                }
              ]}
              className={classes.patientNameStyle}
            >
              <TextField
                name="patient_name"
                label={t("Patient Name")}
                editValue={caseState.patient_name}
                onChange={handleChange}
                disabled={viewAsTeamOwner}
              />
            </Form.Item>
            <span className={classes.gapStyle} />
            <Form.Item required={false}>
              <>
                <Typography className={classes.labelStyle}>
                  {t("Sex")}
                </Typography>
                <Radio.Group
                  name="sex"
                  onChange={handleChange}
                  value={caseState.sex}
                  className={classes.sexBlockStyle}
                  disabled={viewAsTeamOwner}
                >
                  <Radio value={"male"} className={classes.sexTextStyle}>
                    {t("male")}
                  </Radio>
                  <Radio value={"female"} className={classes.sexTextStyle}>
                    {t("female")}
                  </Radio>
                </Radio.Group>
              </>
            </Form.Item>
          </div>

          <div className={classes.bottomFormBlock}>
            <Form.Item required={false} className={classes.bottomSideField}>
              <SelectDateComponent
                label={t("Date of birth")}
                labelClassName={classes.labelStyle}
                value={caseState.birthday}
                onChange={handleChangeDate}
                disabled={viewAsTeamOwner}
              />
            </Form.Item>
            <span className={classes.gapStyle} />
            <Form.Item
              rules={[
                {
                  max: 3,
                  message: t("Please enter within 3 digits")
                }
              ]}
              name="age"
              className={classes.bottomSideField}
            >
              <TextField
                name="age"
                label={t("Age")}
                editValue={caseState.age}
                onChange={handleChange}
                type="number"
                min={0}
                disabled={viewAsTeamOwner}
              />
            </Form.Item>
            <span className={classes.gapStyle} />
            <Form.Item
              name="occupation"
              rules={[
                {
                  max: 200,
                  message: t("Max Length Reached")
                }
              ]}
              className={classes.bottomSideField}
            >
              <TextField
                name="occupation"
                label={t("Occupation")}
                editValue={caseState.occupation}
                onChange={handleChange}
                disabled={viewAsTeamOwner}
              />
            </Form.Item>
          </div>
        </div>
      ) : (
        <div>
          {patientPage ? (
            <Row className={classes.viewPatientTopInformation}>
              <Col span={8}>
                <ShowData
                  label="Patient ID"
                  value={caseState.patient_id || "-"}
                />
              </Col>
              <Col span={8} style={{ paddingRight: 20 }}>
                <ShowData
                  label="Patient Name"
                  value={caseState.patient_name || "-"}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className={classes.viewPatientTopInformation}>
                <Col span={8}>
                  <ShowData
                    label="Patient ID"
                    value={caseState.patient_id || "-"}
                  />
                </Col>
                <Col span={8} style={{ paddingRight: 20 }}>
                  <ShowData
                    label="Patient Name"
                    value={caseState.patient_name || "-"}
                  />
                </Col>
                <Col span={8}>
                  <ShowData label="Sex" value={caseState.sex || "-"} />
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <ShowData
                    label="Date of birth"
                    value={
                      caseState.birthday
                        ? moment(caseState.birthday).format("YYYY/MM/DD")
                        : "-"
                    }
                  />
                </Col>
                <Col span={8}>
                  <ShowData label="Age" value={caseState.age || "-"} />
                </Col>
                <Col span={8}>
                  <ShowData
                    label="Occupation"
                    value={caseState.occupation || "-"}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </div>
  );
};
