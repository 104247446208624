import React, { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  Alert,
  API,
  Container,
  exceptionHandler,
  Logo,
  Wrapper
} from "shared-components";

import { SignUpComponent } from "../../components";

const useStyles = createUseStyles((theme: any) => {
  return {
    bottomLogo: {
      position: "fixed",
      bottom: "3%",
      left: "50%",
      transform: "translate(-50%)"
    },
    formError: {
      color: theme.errorLight,
      marginTop: "3%",
      marginBottom: "3%"
    }
  };
});

export const SignUp = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("" as string);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const history = useHistory();
  const createNewUser = useCallback(async (email: string, password: string) => {
    try {
      const token = await executeRecaptcha!("userSignUp");
      const recaptchaData: any = await API.post(`verify-recaptcha`, {
        response: token
      });
      if (
        recaptchaData &&
        recaptchaData.data &&
        recaptchaData.data.score > 0.5
      ) {
        await API.post(`users`, { email, password });
        Alert("success", "success", t("Your account has been created"), t);
        history.push("/sign-in");
        setLoading(false);
      }
    } catch (error) {
      setError(error && error.response && error.response.data.error.message);
      setLoading(false);
      exceptionHandler(error, t);
    }
  }, []);

  const handleSubmit = ({ email, password }: any) => {
    setLoading(true);
    createNewUser(email, password);
  };

  return (
    <Container center>
      <Wrapper>
        <Logo />
        {error ? <div className={classes.formError}>{t(error)}</div> : null}
        <SignUpComponent onSubmit={handleSubmit} loading={loading} />
      </Wrapper>

      <div className={classes.bottomLogo}>
        <Logo logo="bonbon" />
      </div>
    </Container>
  );
};
