import {
  DeleteFilled as DeleteFilledIcon,
  PlusCircleFilled as PlusFilledIcon
} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import EditableTable from "shared-components/src/components/molecules/EditableTable";

import {
  useCreateMedicines,
  useDeleteMedicines,
  useFetchMedicines,
  useUpdateMedicines
} from "../../../hooks/medicines";
import { useFetchPrescriptionTypes } from "../../../hooks/prescription-types";
import { DrugData, PrescriptionType } from "../types/case";

type DataType = DrugData & { key: React.Key };

type Props = {
  prescriptionTypeDatum: PrescriptionType[];
  medicineDatum: DrugData[];
  handleAddMedicine: () => void;
  handleChangeMedicine: (record: DataType) => void;
  handleDeleteMedicine: (medicineId: number) => void;
  isLoading: boolean;
  isEditable: boolean;
};

const SourceInformationMedicineTable: React.FC<Props> = props => {
  const {
    medicineDatum,
    handleAddMedicine,
    handleChangeMedicine,
    handleDeleteMedicine,
    isLoading,
    isEditable
  } = props;

  const dataSource: DataType[] = medicineDatum.map(medicine => {
    return { key: medicine.id, ...medicine };
  });

  const columns = [
    {
      title: "薬品名",
      dataIndex: "name",
      editable: isEditable,
      textareaSize: 2
    },
    {
      title: "用量",
      dataIndex: "dosage",
      editable: isEditable,
      textareaSize: 2
    },
    {
      title: "用法",
      dataIndex: "usage",
      editable: isEditable,
      textareaSize: 2
    },
    {
      title: "服用開始期間",
      dataIndex: "startAt",
      editable: isEditable,
      textareaSize: 2
    },
    {
      title: "医療機関",
      dataIndex: "medicalAgency",
      editable: isEditable,
      textareaSize: 2
    },
    {
      title: "診療科",
      dataIndex: "department",
      editable: isEditable,
      textareaSize: 2
    },
    {
      title: "処方医",
      dataIndex: "prescriber",
      editable: isEditable,
      textareaSize: 2
    },
    ...(isEditable
      ? [
          {
            title: "",
            dataIndex: "operation",
            width: "0",
            align: "center",
            render: (_: any, record: DataType) => {
              return dataSource.length >= 1 ? (
                <Button
                  icon={<DeleteFilledIcon />}
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    return (
                      record.key && handleDeleteMedicine(record.key as number)
                    );
                  }}
                />
              ) : null;
            }
          }
        ]
      : [])
  ];

  return (
    <>
      <EditableTable
        dataSource={dataSource}
        columns={columns}
        handleSave={handleChangeMedicine}
        loading={isLoading}
        style={{ marginBottom: 12 }}
      />
      {isEditable && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="primary"
            icon={<PlusFilledIcon />}
            onClick={handleAddMedicine}
            style={{ marginBottom: "12px" }}
          >
            追加
          </Button>
        </div>
      )}
    </>
  );
};

export default SourceInformationMedicineTable;

type ContainerProps = {
  isEditable: boolean;
  reportId: string;
  isPublicReport: boolean;
};

export const SourceInformationMedicineTableContainer: React.FC<ContainerProps> = ({
  isEditable,
  reportId,
  isPublicReport
}) => {
  const { data: prescriptionTypes } = useFetchPrescriptionTypes();
  const { data: medicines, isFetching, isLoading } = useFetchMedicines(
    reportId,
    isPublicReport
  );
  const updateMutation = useUpdateMedicines(reportId, isPublicReport);
  const createMutation = useCreateMedicines(reportId, isPublicReport, "report");
  const deleteMutation = useDeleteMedicines(reportId, isPublicReport);

  const handleAddMedicine = () => {
    createMutation.mutate(null);
  };

  const handleDeleteMedicine = (medicineId: number) => {
    deleteMutation.mutate(medicineId);
  };

  const handleUpdateMedicine = (record: DataType) => {
    const medicine = medicines?.find(m => {
      return m.id === record.key;
    });
    medicine && updateMutation.mutate({ ...record });
  };

  return (
    <>
      <SourceInformationMedicineTable
        prescriptionTypeDatum={prescriptionTypes || []}
        medicineDatum={medicines || []}
        handleAddMedicine={handleAddMedicine}
        handleChangeMedicine={handleUpdateMedicine}
        handleDeleteMedicine={handleDeleteMedicine}
        isLoading={!isFetching && !isLoading}
        isEditable={isEditable}
      />
    </>
  );
};
