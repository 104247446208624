import { CopyOutlined, MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  API,
  AuthContext,
  debounceFunction,
  DeleteConfirm,
  DeleteIcon,
  exceptionHandler,
  FlagIcon,
  TeamBookmarkIcon,
  theme,
  UserBookmarkIcon
} from "shared-components";
import { TableComponent } from "shared-components/src/components/molecules/TableComponent";

import { ComponentWrapper } from "../../components";
import { AgreementTags } from "../../components/atoms/AgreementTag";
import { CaseTags } from "../../components/atoms/CaseTag";
import { FilterComponent } from "./FilterComponent";
import { CaseListData } from "./types/case";

const useStyle = createUseStyles({
  wrapper: {
    paddingBottom: 33,
    height: "calc(100vh - 100px) !important"
  },
  tableClassName: {
    "& .ant-table-tbody > tr > td": {
      textAlign: "left",
      "&:last-child": { padding: "0px", width: 50 },
      "&:nth-last-child(2)": { padding: "0px 6px", maxWidth: "80px" },
      "&:nth-last-child(3)": { padding: "0px 6px", maxWidth: "80px" }
    }
  },
  iconwrap: {
    fontSize: "24px",
    display: "flex"
  },
  moreMenu: {
    height: "46px",
    fontSize: "16px",
    padding: "0px 16px",
    display: "flex",
    alignItems: "center"
  },
  teamicon: {
    marginLeft: "-18px"
  },
  popicon: {
    display: "flex",
    height: "35px",
    width: "250px",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      paddingRight: "12px"
    },
    "&:hover": {
      background: theme.background
    }
  },
  flagIconGreen: {
    fontSize: 30,
    color: theme.textGreen
  },
  flagIconYellow: {
    fontSize: 30,
    color: theme.textYellow
  },
  bookmarkIcon: {
    cursor: "pointer",
    borderRadius: "50%",
    padding: 10,
    fontSize: 21
  },
  disabledIcon: {
    "& svg": {
      fill: "#bbbbbb"
    }
  },
  tagStyle: {
    display: "flex",
    "@media(max-width: 1479px)": {
      width: "min-content",
      wordBreak: "keep-all"
    }
  },
  dateTimeStyle: {
    maxWidth: 110
  },
  myBookmarkMenuIcon: {
    width: "17px",
    height: "0",
    borderBottom: `4px solid ${theme.textYellow}`,
    borderTop: `4px solid ${theme.textYellow}`,
    borderRight: "4px solid transparent",
    transform: "rotate(-50deg)",
    margin: {
      right: 7,
      top: 5
    }
  },
  teamBookmarkMenuIcon: {
    width: "17px",
    height: "0",
    borderBottom: `4px solid ${theme.textGreen}`,
    borderTop: `4px solid ${theme.textGreen}`,
    borderRight: "4px solid transparent",
    transform: "rotate(-50deg)",
    margin: {
      right: 7,
      top: 5
    }
  }
});
export const CaseList = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { defaultTeam, uid, profile } = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true as boolean);
  const [caseList, setCaseList] = useState([] as CaseListData[]);
  const [openCaseModal, setOpenCaseModal] = useState(false as boolean);
  const location = useLocation();
  const [duplicateWithCaseID, setDuplicateWithCaseID] = useState("" as string);
  const [showMoreMenu, setShowMoreMenu] = useState("" as string);

  const userSettings: any = {};
  for (const setting of profile.userSettings || []) {
    userSettings[setting.name] = setting.value;
  }
  const clearedFilter = {
    from: userSettings.case_from_date,
    to: userSettings.case_to_date,
    search: userSettings.case_keyword,
    mybookmark: userSettings.case_my_mark === "true" ? true : false,
    teambookmark: userSettings.case_team_mark === "true" ? true : false,
    own: userSettings.case_own === "true" ? true : false,
    disagreed: userSettings.case_disagreed === "true" ? true : false,
    status: userSettings.case_tab,
    sortKey: userSettings.case_sort_column,
    sortOrder: userSettings.case_sort_type,
    page: 1,
    pageSize: 30
  };

  const [filters, setFilters] = useState(clearedFilter as CaseFilters);
  const [count, setCount] = useState(0 as number);
  useEffect(() => {
    if (defaultTeam) {
      debounceFunction(() => {
        return getCases(filters);
      });
    } else {
      setLoading(false);
    }
  }, [defaultTeam, filters]);
  const getCases = async (filters: CaseFilters) => {
    setLoading(true);
    try {
      const response = await API.get("cases", {
        params: filters
      });
      if (response && response.data) {
        setCaseList(response.data.cases);
        setCount(response.data.count);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLoading(false);
  };

  const clearFilters = () => {
    const newFilters = {
      ...filters,
      from: "",
      to: "",
      search: "",
      page: 1,
      pageSize: 30
    };
    setFilters(newFilters);
  };

  const handleDelete = (id: number) => {
    DeleteConfirm(async () => {
      setLoading(true);
      try {
        await API.delete(`cases/${id}`);
        await getCases(filters);
        setLoading(false);
        Alert("success", "success", t("Case is deleted successfully"), t);
      } catch (error) {
        setLoading(false);
        exceptionHandler(error, t);
      }
    });
  };
  useEffect(() => {
    if (location.search === "?openCaseModal") {
      setOpenCaseModal(true);
    }
  }, []);
  const handleMyBookmark = async (id: number) => {
    try {
      const response: any = await API.post("/toggle-user-marks", {
        case_id: id,
        user_id: uid
      });
      const newList = caseList.map(item => {
        if (item.id === id) {
          const updatedItem = {
            ...item,
            userMarked: !item.userMarked
          };
          return updatedItem;
        }
        return item;
      });
      setCaseList(newList);
      if (response && response.data) {
        Alert("success", "success", t("Case is added as your bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from your bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };
  const handleTeamBookmark = async (data: any) => {
    try {
      const response: any = await API.post("/toggle-team-marks", {
        case_id: data.id,
        team_id: defaultTeam
      });
      const newList = caseList.map(item => {
        if (item.id === data.id) {
          const updatedItem = {
            ...item,
            marked: !item.marked
          };
          return updatedItem;
        }
        return item;
      });
      setCaseList(newList);
      if (response && response.data) {
        Alert("success", "success", t("Case is added as team bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from team bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleDuplicate = (record: any) => {
    if (
      record.clientTeamId !== null &&
      record.clientTeamId !== defaultTeam &&
      record.teamId !== defaultTeam &&
      record.diagnosisRequestId !== null
    )
      setDuplicateWithCaseID(
        `${record.id}?clientTeamId=${record.clientTeamId}`
      );
    else setDuplicateWithCaseID(`${record.id}`);
  };

  const popOverContent = (data: any) => {
    return (
      <>
        <div
          className={classes.popicon}
          onMouseDown={() => {
            return handleMyBookmark(data.id);
          }}
        >
          <div className={classes.myBookmarkMenuIcon} />
          <span>
            {data.userMarked ? t("Remove from My Bookmark") : t("My Bookmark")}
          </span>
        </div>
        <div
          className={classes.popicon}
          onMouseDown={() => {
            return handleTeamBookmark(data);
          }}
        >
          <div className={classes.teamBookmarkMenuIcon} />
          <span>
            {data.marked ? t("Remove from Team Bookmark") : t("Team Bookmark")}
          </span>
        </div>
        <div
          className={classes.popicon}
          onMouseDown={() => {
            return handleDuplicate(data);
          }}
        >
          <CopyOutlined />
          <span>{t("Duplicate")}</span>
        </div>
        {defaultTeam === data.teamId && (
          <div
            className={classes.popicon}
            onMouseDown={() => {
              return handleDelete(data.id);
            }}
          >
            <DeleteIcon />
            <span>{t("Remove")}</span>
          </div>
        )}
      </>
    );
  };
  const columns = [
    {
      width: "68px",
      render: (item: CaseListData) => {
        return (
          <div className={classes.iconwrap}>
            <div>
              {item.userMarked ? (
                <UserBookmarkIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconYellow}`}
                />
              ) : (
                <FlagIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconYellow} ${classes.disabledIcon}`}
                />
              )}
            </div>
            <div className={classes.teamicon}>
              {item.marked ? (
                <TeamBookmarkIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconGreen}`}
                />
              ) : (
                <FlagIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconGreen} ${classes.disabledIcon}`}
                />
              )}
            </div>
          </div>
        );
      }
    },
    {
      title: t("Team Name"),
      dataIndex: "teamName",
      render: (text: string, data: CaseListData) => {
        return data.clientTeamId !== defaultTeam && data.receiverTeamName
          ? `${data.receiverTeamName} / ${data.teamName}`
          : data.teamName;
      }
    },
    {
      title: t("Case number"),
      dataIndex: "caseNumber",
      key: "caseNumber",
      sorter: (a: any, b: any) => {
        return a.caseNumber - b.caseNumber;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Patient"),
      dataIndex: "patientName",
      key: "patientName",
      sorter: (a: any, b: any) => {
        return a.patientName - b.patientName;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Agreement"),
      dataIndex: "agreementStatus",
      key: "agreementStatus",
      render: (text: string) => {
        return (
          <div className={classes.tagStyle}>
            <AgreementTags type={text} />
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.agreementStatus - b.agreementStatus;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Status"),
      dataIndex: "caseStatus",
      key: "caseStatus",
      render: (text: string) => {
        return (
          <div className={classes.tagStyle}>
            <CaseTags type={text} />
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.caseStatus - b.caseStatus;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Pharmacist"),
      dataIndex: "pharmacist",
      key: "pharmacist",
      sorter: (a: any, b: any) => {
        return a.pharmacist - b.pharmacist;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, data: any) => {
        return (
          <div className={classes.dateTimeStyle}>
            {moment(data.createdAt).format("YYYY/MM/DD HH:mm:ss")}
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.createdAt - b.createdAt;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: t("Updated"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string, data: any) => {
        return (
          <div className={classes.dateTimeStyle}>
            {moment(data.updatedAt).format("YYYY/MM/DD HH:mm:ss")}
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.updatedAt - b.updatedAt;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      render: (record: CaseListData) => {
        return (
          <div
            onClick={event => {
              return event.stopPropagation();
            }}
            onBlur={() => {
              return setShowMoreMenu("");
            }}
          >
            <Popover
              content={() => {
                return popOverContent(record);
              }}
              visible={`${record.rowKey}` === showMoreMenu}
            >
              <MoreOutlined
                className={classes.moreMenu}
                onClick={() => {
                  return setShowMoreMenu(`${record.rowKey}`);
                }}
              />
            </Popover>
          </div>
        );
      }
    }
  ];

  const handleSortChange = (pagination: any, filters1: any, sorter: any) => {
    setLoading(true);
    const { columnKey, order } = sorter;
    const newFilters = {
      ...filters,
      sortOrder: order,
      sortKey: order ? columnKey : undefined
    };
    setFilters({ ...newFilters });
  };

  return (
    <ComponentWrapper className={classes.wrapper}>
      <FilterComponent
        openCaseModal={openCaseModal}
        clearFilters={clearFilters}
        filters={filters}
        setFilters={setFilters}
        count={count}
        setLoading={setLoading}
        duplicateWithCaseID={duplicateWithCaseID}
        setDuplicateWithCaseID={setDuplicateWithCaseID}
      />
      <TableComponent
        rowKey="rowKey"
        columns={columns}
        dataSource={caseList?.map((d, k) => {
          return { ...d, rowKey: k };
        })}
        loading={loading}
        handleRow={(record: any) => {
          return {
            onClick: () => {
              return history.push(
                `/cases/${record.id}${
                  record.clientTeamId !== null &&
                  record.clientTeamId !== defaultTeam &&
                  record.teamId !== defaultTeam &&
                  record.diagnosisRequestId !== null
                    ? "?clientTeamId=" + record.clientTeamId
                    : ""
                }`
              );
            }
          };
        }}
        className={classes.tableClassName}
        onChange={handleSortChange}
      />
    </ComponentWrapper>
  );
};
