import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router";
import { SidebarComponent } from "../organisms";


const useStyles = createUseStyles((theme: any) => {
  return {
    "@keyframes slide-out-left": {
      from: {
        webkitTransform: "translateX(0)",
        transform: "translateX(0)",
        opacity: 1
      },
      to: {
        webkitTransform: "translateX(-1000px)",
        transform: "translateX(-1000px)",
        opacity: 0,
        width: 0
      }
    },
    "@keyframes slide-in-left": {
      from: {
        webkitTransform: "translateX(-100px)",
        transform: "translateX(-100px)",
        opacity: 0
      },
      to: {
        webkitTransform: "translateX(0)",
        transform: "translateX(0)",
        opacity: 1
      }
    },
    leftSidebarWrapperOut: {
      display: "none",
      animation:
        "$slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"
    },
    leftSidebarWrapperIn: {
      animation:
        "$slide-in-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"
    },
    sider: {
      marginTop: 40,
      background: theme.background,
      minWidth: "auto !important",
      flex: "auto !important",
      "& .ant-layout-sider": {
        height: "100%"
      }
    },
    sidebarContainer: {
      display: "inherit",
      "& .ant-layout-sider-collapsed": {
        width: "60px !important"
      },
      "& .ant-menu-inline-collapsed": {
        width: "60px !important"
      }
    }
  };
});

export const SideBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false as boolean);
  const state = location.state as any;

  const checksidebarCollapsible = (path: string) => {
    return !!path.match(/\/cases\/[/\d+]/g);
  };

  useEffect(() => {
    setCollapsed(checksidebarCollapsible(location.pathname));
  }, [location.pathname]);

  const { Sider } = Layout;
  return (
    <div className={classes.sidebarContainer}>
      <Sider
        collapsed={collapsed}
        className={`${classes.sider} ${
          state && state.clear
            ? classes.leftSidebarWrapperOut
            : classes.leftSidebarWrapperIn
        }`}
        breakpoint="sm"
      >
        <SidebarComponent collapsed={collapsed} />
      </Sider>
    </div>
  );
};
