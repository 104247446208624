import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

interface Props {
  type: string;
  className?: any;
}

const useStyles = createUseStyles({
  tagContainer: {
    fontSize: "14px",
    lineHeight: "16px",
    padding: "1px 8px 2px 8px",
    textTransform: "capitalize",
    borderRadius: "2px",
    Opacity: "1px",

    border: (type: string) => {
      switch (type) {
        case "creating":
          return "1px solid #FFA39E";
        case "pharmacistChecking":
          return "1px solid #FFBB96";
        case "sending":
          return "1px solid #FFE58F";
        case "accepting":
          return "1px solid #B7EB8F";
        case "confirming":
          return "1px solid #91D5FF";
        case "answered":
          return "1px solid #D3ADF7";
        case "canceled":
          return "1px solid #F7ADEE";
      }
    },
    background: (type: string) => {
      switch (type) {
        case "creating":
          return "#FFF1F0";
        case "pharmacistChecking":
          return "#FFF2E8";
        case "sending":
          return "#FFFBE6";
        case "accepting":
          return "#F6FFED";
        case "confirming":
          return "#E6F7FF";
        case "answered":
          return "#F9F0FF";
        case "canceled":
          return "#FFF0F8 ";
      }
    },
    color: (type: string) => {
      switch (type) {
        case "creating":
          return "#F5222D";
        case "pharmacistChecking":
          return "#FA541C";
        case "sending":
          return "#FAAD14";
        case "accepting":
          return "#52C41A";
        case "confirming":
          return "#1890FF";
        case "answered":
          return "#722ED1";
        case "canceled":
          return "#D12EB8";
      }
    },

    width: "fit-content"
  }
});

const CaseTags: React.FC<Props> = ({ type, className }) => {
  const classes = useStyles(type);
  const { t } = useTranslation();

  return (
    <div className={`${classes.tagContainer} ${className}`}>{t(`${type}`)}</div>
  );
};

export { CaseTags };
