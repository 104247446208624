import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography } from "shared-components";

import { AgreementTags } from "../../../components/atoms/AgreementTag";
import { CaseData, ReportData } from "../types/case";

const useStyles = createUseStyles((theme: any) => {
  return {
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: 56,
      paddingLeft: 10,
      letterSpacing: "0px"
    },
    reportWrapper: {
      justifyContent: "flex-end"
    },
    titleBlock: {
      paddingTop: 21,
      color: theme.gray6
    },
    extraInformationBlock: {
      paddingTop: 17,
      display: "flex",
      flexDirection: "column",
      fontSize: "10px",
      lineHeight: "12px"
    },
    topBlock: {
      display: "flex"
    },
    bottomBlock: {
      display: "flex",
      justifyContent: "flex-end"
    },
    textNameStyle: {
      padding: {
        left: 29,
        right: 9
      }
    },
    createdAtValue: {
      position: "relative",
      top: -4,
      color: theme.gray6
    },
    answeredStyle: {
      padding: {
        left: 16,
        right: 4
      }
    },
    caseNumberValue: {
      position: "relative",
      top: -4,
      left: -5,
      color: theme.gray6
    },
    tagStyle: {
      position: "relative",
      top: -4
    }
  };
});

interface Props {
  agreementStatus?: string;
  typeAgreement?: boolean;
  caseState?: CaseData;
  isPatient?: boolean;
  reportData?: ReportData;
  caseStatus?: string;
}

export const Header = ({
  caseState,
  agreementStatus,
  typeAgreement,
  isPatient,
  reportData,
  caseStatus
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [answeredAt, setAnsweredAt] = useState("" as string | undefined);

  useEffect(() => {
    if (typeAgreement) setAnsweredAt(caseState?.agreement_answered_at);
    else if (reportData?.answered_at) setAnsweredAt(reportData?.answered_at);
  }, [typeAgreement, caseState, reportData, caseStatus]);

  return (
    <div
      className={`${classes.headerWrapper} ${!typeAgreement &&
        classes.reportWrapper}`}
    >
      {typeAgreement && (
        <Typography
          className={classes.titleBlock}
          fontSize="24px"
          lineHeight="29px"
        >
          {t("Patient Agreement")}
        </Typography>
      )}

      <div className={classes.extraInformationBlock}>
        <div className={classes.topBlock}>
          {!isPatient && typeAgreement && caseState?.team_name}
          <div className={classes.textNameStyle}>
            {" "}
            {isPatient ? t("Requested") : t("Created")}{" "}
          </div>
          <Typography
            fontSize="14px"
            lineHeight="19px"
            className={classes.createdAtValue}
          >
            {reportData
              ? moment(reportData?.created_at).format("YYYY/MM/DD HH:mm:ss")
              : moment(caseState?.created_at).format("YYYY/MM/DD HH:mm:ss")}
          </Typography>
          {!isPatient && (
            <>
              <div className={classes.answeredStyle}>
                {" "}
                {t("Answered date")}{" "}
              </div>
              <Typography
                fontSize="14px"
                lineHeight="19px"
                className={classes.createdAtValue}
              >
                {answeredAt
                  ? moment(answeredAt).format("YYYY/MM/DD HH:mm:ss")
                  : "-"}
              </Typography>
            </>
          )}
        </div>
        <div className={classes.bottomBlock}>
          {!isPatient && typeAgreement && (
            <div className={classes.tagStyle}>
              <AgreementTags
                type={
                  agreementStatus !== undefined ? agreementStatus : "creating"
                }
              />{" "}
            </div>
          )}
          <div className={classes.textNameStyle}> {t("Case number")} </div>
          <Typography
            fontSize="14px"
            lineHeight="17px"
            className={classes.caseNumberValue}
          >
            {caseState?.case_number}
          </Typography>
        </div>
      </div>
    </div>
  );
};
