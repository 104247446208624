import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

interface Props {
  type: string;
  className?: any;
}

const useStyles = createUseStyles({
  tagContainer: {
    fontSize: "14px",
    lineHeight: "16px",
    padding: "1px 8px 2px 8px",
    textTransform: "capitalize",
    borderRadius: "2px",
    Opacity: "1px",

    border: (type: string) => {
      switch (type) {
        case "creating":
          return "1px solid #FFA39E";
        case "agree":
          return "1px solid #B7EB8F";
        case "disagree":
          return "1px solid #FFBB96";
        case "waiting":
          return "1px solid #FFE58F";
      }
    },
    background: (type: string) => {
      switch (type) {
        case "creating":
          return "#FFF1F0";
        case "agree":
          return "#F6FFED";
        case "disagree":
          return "#FFF2E8";
        case "waiting":
          return "#FFFBE6 ";
      }
    },
    color: (type: string) => {
      switch (type) {
        case "creating":
          return "#F5222D";
        case "agree":
          return "#52C41A";
        case "disagree":
          return "#FA541C";
        case "waiting":
          return "#FAAD14";
      }
    },
    width: "fit-content"
  }
});

const AgreementTags: React.FC<Props> = ({ type, className }) => {
  const classes = useStyles(type);
  const { t } = useTranslation();

  return (
    <div className={`${classes.tagContainer} ${className}`}>
      {" "}
      {t(`${type}`)}
    </div>
  );
};

export { AgreementTags };
