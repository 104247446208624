import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  BorderBoxWrapper,
  Button,
  DividerComponent,
  exceptionHandler,
  Typography
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    boxStyle: {
      "& > div": {
        padding: 0
      }
    },
    wrapper: {
      padding: "21px 0px 18px 30px",
      overflow: "hidden",
      "&:hover": {
        "overflow-y": "auto"
      },
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1c1",
        borderRadius: "10px"
      }
    },
    teamCode: {
      color: theme.gray7,
      "& span": {
        marginLeft: 20,
        fontSize: 22,
        color: theme.textBlack
      }
    },
    firstDesc: {
      marginTop: 18,
      color: theme.gray7
    },
    textColor: { color: theme.gray7 },
    divider: { border: `1px solid ${theme.gray4}` },
    teamCodeButton: {
      textAlign: "center",
      marginTop: 16,
      marginBottom: 16
    },
    button: {
      backgroundColor: theme.darkBlueButton,
      width: 160,
      height: 28,
      padding: 0,
      borderRadius: 3
    }
  };
});

interface Props {
  className?: string;
  invitationCode: string;
}

export const TeamCodeBox = ({ className, invitationCode }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCopyButtonClick = () => {
    try {
      navigator.clipboard.writeText(invitationCode);
      Alert("success", "success", t("Team code copied successfully."), t);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  return (
    <BorderBoxWrapper
      headingSpacing="0px"
      className={`${classes.boxStyle} ${className}`}
    >
      <div className={classes.wrapper}>
        <Typography fontSize={"20px"} className={classes.teamCode}>
          {t("Team Code")} <span>{invitationCode}</span>
        </Typography>
        <Typography fontSize={"14px"} className={classes.firstDesc}>
          ※{t("This is a code to be used for requests and consultations.")}
        </Typography>
        <Typography fontSize={"14px"} className={classes.textColor}>
          ※
          {t(
            "If you have not yet communicated with the other team, provide them the code."
          )}
        </Typography>
        <Typography fontSize={"14px"} className={classes.textColor}>
          ※
          {t(
            "Once the code is entered, it will be added to their contact list."
          )}
        </Typography>
      </div>
      <DividerComponent className={classes.divider} />
      <div className={classes.teamCodeButton}>
        <Button
          type="primary"
          size="large"
          onClick={handleCopyButtonClick}
          className={classes.button}
        >
          {t("Copy")}{" "}
        </Button>
      </div>
    </BorderBoxWrapper>
  );
};
