import {
  DownloadOutlined,
  GlobalOutlined,
  TagOutlined
} from "@ant-design/icons";
import { Divider, Drawer, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import {
  Alert,
  API,
  AuthContext,
  exceptionHandler,
  // BookIcon,
  FilePlusIcon,
  FlagIcon,
  MessageIcon,
  SearchIcon,
  SendPDFIcon,
  TeamBookmarkIcon,
  theme,
  TickIcon,
  UserBookmarkIcon
} from "shared-components";

import { AnalysisTags } from "./AnalysisTags";
import { Comment } from "./Comment";
import { DoubleCheck, DoubleCheckProps } from "./DoubleCheck";
import { Export } from "./Export";
import { PublicLink } from "./PublicLink";
import { SeeCase } from "./SeeCase";
import { SendPDF } from "./SendPDF";

const useStyles = createUseStyles((theme: any) => {
  return {
    rightSidebarWrapper: {
      position: "fixed",
      top: 40,
      width: 214,
      right: 15,
      zIndex: "1000",
      backgroundColor: theme.background,
      height: "100vh"
    },
    rightSidebar: {
      alignItems: "center",
      minHeight: "-webkit-fill-available"
    },
    rightSidebarIconsWrapper: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.1411764705882353) !important"
      }
    },
    rightSidebarIcon: {
      margin: "5px 5px",
      cursor: "pointer",
      borderRadius: "50%",
      color: theme.primary,
      padding: 10,
      fontSize: 21
    },
    bookmarkIcon: {
      margin: "5px 0px",
      cursor: "pointer",
      borderRadius: "50%",
      color: theme.black,
      padding: 10,
      fontSize: 21,
      transform: "rotateZ(90deg)"
    },
    file: {
      fontSize: 26
      // margin: "10px 0px 0px 0px"
    },
    dividerWrapper: {
      width: "24px",
      marginLeft: 15
    },
    divider: {
      height: 2,
      margin: "16px 0",
      background: "#DBDCDC"
    },
    disabledIcon: {
      "& svg": {
        fill: "#bbbbbb"
      }
    },
    flagIconGreen: {
      fontSize: 24,
      marginLeft: 3,
      color: theme.textGreen
    },
    flagIconYellow: {
      fontSize: 24,
      marginLeft: 3,
      color: theme.textYellow
    },
    iconText: {
      fontSize: 12,
      color: "#6A6A6A",
      whiteSpace: "nowrap"
    },
    drawer: {
      "& .ant-drawer-content-wrapper": {
        boxShadow: "-2px 0px 1px -1px #00000029 !important",
        minWidth: "320px !important",
        width: "auto !important"
      },
      "& *": {
        transition: "none"
      },
      "& .ant-drawer-body": {
        width: 380,
        borderRight: "none !important",
        marginBottom: 45,
        "&::-webkit-scrollbar": {
          width: 5
        },
        "&::-webkit-scrollbar-track": {
          background: theme.lightGrey
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#c1c1c1",
          borderRadius: "10px"
        }
      }
    },
    rightSidebarWrapperOut: {
      display: "none",
      animation:
        "$slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"
    },
    rightSidebarWrapperIn: {
      animation:
        "$slide-in-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"
    }
  };
});

interface IDrawerContent {
  identifier: string;
}
interface SiderInterface {
  clear?: boolean;
  doubleCheckProps?: DoubleCheckProps;
  typeAgreement?: boolean;
  setDoubleCheckConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
  caseID?: any;
  myBookmark: boolean;
  teamBookmark: boolean;
  reportID?: string;
  caseNumber?: string;
  patientName?: string;
  publicUrl?: string;
  handlePublicUrl?: (confirm: boolean, personal: boolean) => Promise<void>;
  viewAsTeamOwner?: boolean;
  setCommentNotification?: React.Dispatch<React.SetStateAction<boolean>>;
  commentNotification?: boolean;
}

const RightSidebar = ({
  clear,
  doubleCheckProps,
  typeAgreement,
  caseID,
  myBookmark,
  teamBookmark,
  reportID,
  caseNumber,
  patientName,
  publicUrl,
  handlePublicUrl,
  viewAsTeamOwner,
  commentNotification,
  setCommentNotification
}: SiderInterface) => {
  const [showDrawer, setShowDrawer] = useState(false as boolean);
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const [rightSidebarMenuSelected, setRightSidebarMenuSelected] = useState(
    "0" as string
  );
  const { defaultTeam, uid } = useContext(AuthContext);
  const [uBookmark, setMyBookmark] = useState(false as boolean);
  const [tBookmark, setTeamBookmark] = useState(false as boolean);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location && location.search && location.search.includes("commentId=")) {
      if (commentNotification) {
        return;
      }
      toggleDrawer(undefined, "2");
    }
  }, []);

  const toggleDrawer = (event?: any, clickedOn?: string) => {
    if (clickedOn) {
      setRightSidebarMenuSelected(clickedOn);
      if (clickedOn === rightSidebarMenuSelected) {
        setShowDrawer(false);
        setRightSidebarMenuSelected("0");
      } else {
        setShowDrawer(true);
      }
    } else {
      setShowDrawer(false);
      setRightSidebarMenuSelected("0");
    }
  };

  const toggleVisibility = () => {
    window.open("/cases?openCaseModal");
  };

  useEffect(() => {
    if (myBookmark) {
      setMyBookmark(true);
    }
    if (teamBookmark) {
      setTeamBookmark(true);
    }
  }, [myBookmark, teamBookmark]);

  const downloadPDF = async () => {
    try {
      setLoading(true);
      const res: Blob = await API.post(
        `/pdf/${reportID}`,
        {},
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${caseNumber}_${patientName}.pdf`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      exceptionHandler(error);
      setLoading(false);
    }
  };

  const DrawerContent = (props: IDrawerContent) => {
    switch (props.identifier) {
      case "1": {
        return <AnalysisTags reportID={reportID} />;
      }
      case "2": {
        return (
          <Comment
            commentNotification={commentNotification}
            setCommentNotification={setCommentNotification}
            reportID={reportID}
          />
        );
      }
      case "3": {
        return (
          <SeeCase
            handleTeamBookmark={handleTeamBookmark}
            handleMyBookmark={handleMyBookmark}
          />
        );
      }
      case "4": {
        return <SendPDF reportID={reportID} />;
      }
      case "5": {
        return <Export loading={loading} downloadPDF={downloadPDF} />;
      }
      case "6": {
        return <DoubleCheck {...doubleCheckProps} onClose={toggleDrawer} />;
      }
      case "7": {
        return (
          <PublicLink publicUrl={publicUrl} handlePublicUrl={handlePublicUrl} />
        );
      }
      default: {
        return <p>Custom Content Block</p>;
      }
    }
  };

  const handleMyBookmark = async (caseId?: string, userId?: string) => {
    try {
      const response: any = await API.post("/toggle-user-marks", {
        case_id: caseId ? caseId : caseID,
        user_id: userId ? userId : uid
      });
      setMyBookmark(!uBookmark);
      if (response && response.data) {
        Alert("success", "success", t("Case is added as your bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from your bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleTeamBookmark = async (caseId?: string, team_id?: string) => {
    try {
      const response: any = await API.post("/toggle-team-marks", {
        case_id: caseId ? caseId : caseID,
        team_id: team_id ? team_id : defaultTeam
      });
      setTeamBookmark(!tBookmark);
      if (response && response.data) {
        Alert("success", "success", t("Case is added as team bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from team bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  return (
    <>
      <div
        className={`${classes.rightSidebarWrapper} ${
          clear ? classes.rightSidebarWrapperOut : classes.rightSidebarWrapperIn
        }`}
      >
        <div className={classes.rightSidebar}>
          <div
            onClick={toggleVisibility}
            className={classes.rightSidebarIconsWrapper}
          >
            <FilePlusIcon
              className={`${classes.rightSidebarIcon} ${classes.file}`}
              name="1"
            />
            <Typography className={classes.iconText}>
              {t("Create New")}
            </Typography>
          </div>

          {!typeAgreement && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={event => {
                return toggleDrawer(event, "1");
              }}
            >
              <TagOutlined className={classes.rightSidebarIcon} name="1" />
              <Typography className={classes.iconText}>
                {t("Analysis Tags")}
              </Typography>
            </div>
          )}

          {!typeAgreement && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={event => {
                return toggleDrawer(event, "2");
              }}
            >
              <MessageIcon className={classes.rightSidebarIcon} name="2" />
              <Typography className={classes.iconText}>
                {t("Comment")}
              </Typography>
            </div>
          )}

          <div
            className={classes.rightSidebarIconsWrapper}
            onClick={(event: any) => {
              return toggleDrawer(event, "3");
            }}
          >
            <SearchIcon className={classes.rightSidebarIcon} name="3" />
            <Typography className={classes.iconText}>
              {t("Search Case")}
            </Typography>
          </div>

          {!typeAgreement && (
            <>
              {!viewAsTeamOwner && (
                <div
                  className={classes.rightSidebarIconsWrapper}
                  onClick={(event: any) => {
                    return toggleDrawer(event, "6");
                  }}
                >
                  <TickIcon className={classes.rightSidebarIcon} />
                  <Typography className={classes.iconText}>
                    {t(
                      doubleCheckProps?.isReceiverReport
                        ? "Answer"
                        : "Complete / Double check"
                    )}
                  </Typography>
                </div>
              )}

              <div
                className={classes.rightSidebarIconsWrapper}
                onClick={(event: any) => {
                  return toggleDrawer(event, "4");
                }}
              >
                <SendPDFIcon className={classes.rightSidebarIcon} />
                <Typography className={classes.iconText}>
                  {t("Send PDF")}
                </Typography>
              </div>

              <div
                className={classes.rightSidebarIconsWrapper}
                onClick={(event: any) => {
                  return toggleDrawer(event, "5");
                }}
              >
                <DownloadOutlined
                  className={classes.rightSidebarIcon}
                  name="5"
                />
                <Typography className={classes.iconText}>
                  {t("Download PDF")}
                </Typography>
              </div>

              <div
                className={classes.rightSidebarIconsWrapper}
                onClick={(event: any) => {
                  return toggleDrawer(event, "7");
                }}
              >
                <GlobalOutlined className={classes.rightSidebarIcon} name="7" />
                <Typography className={classes.iconText}>
                  {t("Public Link")}
                </Typography>
              </div>
            </>
          )}

          <div className={classes.dividerWrapper}>
            <Divider className={classes.divider} />
          </div>

          <div
            className={classes.rightSidebarIconsWrapper}
            onClick={() => {
              return handleMyBookmark();
            }}
          >
            {!uBookmark ? (
              <>
                <FlagIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconYellow}`}
                />
                <Typography className={classes.iconText}>
                  {t("My Bookmark")}
                </Typography>
              </>
            ) : (
              <>
                <UserBookmarkIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconYellow}`}
                />
                <Typography className={classes.iconText}>
                  {t("My Bookmark")}
                </Typography>
              </>
            )}
          </div>

          <div
            className={classes.rightSidebarIconsWrapper}
            onClick={() => {
              return handleTeamBookmark();
            }}
          >
            {!tBookmark ? (
              <>
                <FlagIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconGreen}`}
                />
                <Typography className={classes.iconText}>
                  {t("Team Bookmark")}
                </Typography>
              </>
            ) : (
              <>
                <TeamBookmarkIcon
                  className={`${classes.bookmarkIcon} ${classes.flagIconYellow}`}
                />
                <Typography className={classes.iconText}>
                  {t("Team Bookmark")}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <Drawer
          placement="right"
          closable={false}
          onClose={toggleDrawer}
          visible={showDrawer}
          className={classes.drawer}
          getContainer={false}
          maskStyle={{
            backgroundColor: "transparent"
          }}
          style={{
            marginRight: showDrawer ? 260 : 0,
            marginTop: 40
          }}
          bodyStyle={{
            background: theme.background,
            borderRight: "1px solid #dbdcdc",
            boxShadow: "0px 3px 6px #00000029 !important",
            padding: "12px 16px 0 0"
          }}
        >
          <DrawerContent identifier={rightSidebarMenuSelected} />
        </Drawer>
      </div>
    </>
  );
};

export { RightSidebar };
