import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

type RequestTag =
  | string
  | "Declined"
  | "Accepting"
  | "Estimating"
  | "Checking"
  | "Ordering"
  | "Working"
  | "Done"
  | "Editing"
  | "Canceling"
  | "Rejected"
  | "Canceled";

interface Props {
  type: RequestTag;
  className?: any;
}

const useStyles = createUseStyles({
  tagContainer: {
    fontSize: "14px",
    lineHeight: "16px",
    padding: "1px 8px 2px 8px",
    textTransform: "capitalize",
    borderRadius: "2px",
    Opacity: "1px",

    border: (type: string) => {
      switch (type) {
        case "Declined":
          return "1px solid #FFA39E";
        case "Accepting":
          return "1px solid #FFBB96";
        case "Estimating":
          return "1px solid #FFBB96";
        case "Checking":
          return "1px solid #FFE58F";
        case "Ordering":
          return "1px solid #B7EB8F";
        case "Working":
          return "1px solid #91D5FF";
        case "Done":
          return "1px solid #D3ADF7";
        case "Editing":
          return "1px solid #D9D9D9";
        case "Canceling":
          return "1px solid #ADC6FF";
        case "Rejected":
          return "1px solid #FFADD2";
        case "Canceled":
          return "1px solid #F7ADEE";
      }
    },
    background: (type: string) => {
      switch (type) {
        case "Declined":
          return "#FFF1F0";
        case "Accepting":
          return "#FFF2E8";
        case "Estimating":
          return "#FFF2E8";
        case "Checking":
          return "#FFFBE6";
        case "Ordering":
          return "#F6FFED";
        case "Working":
          return "#E6F7FF";
        case "Done":
          return "#F9F0FF";
        case "Editing":
          return "#F5F5F5";
        case "Canceling":
          return "#F0F5FF";
        case "Rejected":
          return "#FFF0F6";
        case "Canceled":
          return "#FFF0F8";
      }
    },
    color: (type: string) => {
      switch (type) {
        case "Declined":
          return "#F5222D";
        case "Accepting":
          return "#FA541C";
        case "Estimating":
          return "#FA541C";
        case "Checking":
          return "#FAAD14";
        case "Ordering":
          return "#52C41A";
        case "Working":
          return "#1890FF";
        case "Done":
          return "#722ED1";
        case "Editing":
          return "#595959";
        case "Canceling":
          return "#2F54EB";
        case "Rejected":
          return "#EB2F96";
        case "Canceled":
          return "#D12EB8";
      }
    },

    width: "fit-content"
  }
});

const RequestTag: React.FC<Props> = ({ type, className }) => {
  const classes = useStyles(type);
  const { t } = useTranslation();

  return (
    <div className={`${classes.tagContainer} ${className}`}>{t(`${type}`)}</div>
  );
};

export { RequestTag };
