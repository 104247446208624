import { Layout } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router";
import {
  AccountMenu,
  Button,
  Logo,
  NotificationComponent
} from "shared-components";
import { AuthContext } from "shared-components/src";
import { changeLanguage } from "shared-components/src/config/i18n";

const useStyles = createUseStyles((theme: any) => {
  return {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.background,
      borderBottom: "1px solid #E6E6E6",
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
      position: "fixed",
      maxHeight: 40,
      zIndex: "1000"
    },
    logoBlock: {
      display: "flex",
      whiteSpace: "nowrap"
    },
    teamName: {
      marginLeft: 15
    },
    buttonWapper: {
      "& .ant-btn-lg": {
        padding: "3px 16px"
      },
      "& .ant-btn:hover, .ant-btn:focus, .ant-btn:active": {
        background: "#EAEAEA"
      },
      "& .ant-btn:hover, .ant-btn:focus": {
        color: "#222222",
        border: "1px solid #D5D5D5"
      }
    },
    changeLanguageBtnStyle: {
      height: 21,
      fontSize: 12,
      lineHeight: "14px",
      letteSpacing: "0.72px",
      color: "#222222",
      background: "#EAEAEA",
      marginRight: 60,
      border: "1px solid #D5D5D5"
    }
  };
});

interface Props {
  isPatient?: boolean;
}

export const HeaderWrapper = ({ isPatient }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { defaultTeamName, externalUser, organizationName } = useContext(
    AuthContext
  );
  const [userLanguage, setUserLanguage] = useState("" as string);

  const { Header } = Layout;

  const publicLink = location.pathname.includes("/public-report");
  useEffect(() => {
    return () => {
      const pdfUploading = localStorage.getItem("pdfUploading");
      if (pdfUploading) {
        localStorage.removeItem("pdfUploading");
      }
    };
  }, []);

  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    if (language === "ja") {
      setUserLanguage(language);
    }
  }, []);

  const handleChangeLangugage = (lang: string) => {
    setUserLanguage(lang);
    changeLanguage(lang);
  };

  return (
    <Header className={classes.header}>
      <div className={classes.logoBlock}>
        <Logo
          textSize="small"
          color="black"
          onClick={() => {
            // if (externalUser) {
            //   return;
            // }
            return history.push("/cases");
          }}
        />
        {defaultTeamName !== "" &&
          organizationName !== "" &&
          !publicLink &&
          !isPatient && (
            <div className={classes.teamName}>
              {organizationName} {defaultTeamName}
            </div>
          )}
      </div>
      {!externalUser && !publicLink && !isPatient && <NotificationComponent />}
      {!publicLink && !isPatient && <AccountMenu />}

      {isPatient && (
        <div className={classes.buttonWapper}>
          <Button
            size="large"
            htmlType="button"
            className={classes.changeLanguageBtnStyle}
            onClick={() => {
              return handleChangeLangugage(userLanguage === "ja" ? "en" : "ja");
            }}
          >
            {userLanguage === "ja"
              ? "Change language to English"
              : "言語を日本語に変更"}
          </Button>
        </div>
      )}
    </Header>
  );
};
